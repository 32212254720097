import axios, { InternalAxiosRequestConfig } from "axios"
import { isString } from "./any"

/**
 * Provided urls are prefixed with `/admin/` for convenience and `X-CSRF-TOKEN` is set at request time.
 */
export const adaClient = axios.create({
  withCredentials: true,
  baseURL: "/admin/",
})

adaClient.interceptors.request.use(attachXCSRFToken)

/**
 * Automatically sets CSRF token and instructs API to reply with camelCased bodies.
 */
export const appClient = axios.create({
  withCredentials: true,
  baseURL: "/",
  headers: { "Key-Inflection": "camel" },
})

export const iClient = axios.create({
  withCredentials: true,
  baseURL: "/api/i/",
  headers: { "Key-Inflection": "camel" },
})

appClient.interceptors.request.use(attachXCSRFToken)
iClient.interceptors.request.use(attachXCSRFToken)

function attachXCSRFToken<D>(config: InternalAxiosRequestConfig<D>): InternalAxiosRequestConfig<D> {
  if (document instanceof Document) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")

    if (isString(csrfToken) && config.headers) {
      config.headers["X-CSRF-TOKEN"] = csrfToken
    } else if (!config.headers) {
      console.error(new Error(`Trying to set X-CSRF-TOKEN, request header cannot be found`))
    } else {
      console.error(new Error(`meta[name="csrf-token"] could not be found!`))
    }
  }

  return config
}
