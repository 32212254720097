import React from "react"
import { Tooltip } from "./tooltip"

export function AsideTagList(props) {
  const { tags = [] } = props

  return (
    <div className="ui-widget-content ui-widget-aside u-cf">
      <Tooltip title="Add and edit tags in 'Details'">
        <h3 className="search-tags">
          Tags <span className="fa fa-question-circle" />
        </h3>
      </Tooltip>

      <p className="search-tags">
        {tags && tags.length > 0 ? (
          <React.Fragment>
            {tags.map((t) => (
              <span key={t.name} className="search-tag">
                {t.name}
              </span>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>Lookbook hasn't been tagged.</React.Fragment>
        )}
      </p>
    </div>
  )
}
