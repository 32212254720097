import React from "react"
import ReactDOM from "react-dom"
import { action } from "mobx"
import { observer, inject, Provider } from "mobx-react"
import PriceGroupSelect from "./price_group_select"
import ProductPhotosDialog from "./product_photos_dialog"

@inject(["ideabookStore"])
@observer
class Product extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ideabook_product = props.ideabook_product
    this.product = props.ideabook_product.product
    this.price_groups = this.product.price_structure.price_groups
    this.product.selected = false
    this.parent = props.parent
  }

  @action selectProduct(elem) {
    this.product.selected = !this.product.selected
    this.parent.toggleProductMover()
  }

  @action toggleAvailablePhotos() {
    $("input").blur() // We need to keep the scroll from jumping after closing the dialog.

    // Render the photo dialog box lazily vs. eagerly to save
    // initial load time.
    ReactDOM.render(
      <Provider ideabookStore={this.props.ideabookStore}>
        <ProductPhotosDialog ideabook_product={this.ideabook_product} parent={this} />
      </Provider>,
      document.getElementById(`product_photos_dialog_${this.ideabook_product.id}`)
    )

    let dialogBox = $(`#product_photos_dialog_${this.ideabook_product.id}`)

    dialogBox.dialog({
      dialogClass: "no-close",
      minWidth: 800,
      maxWidth: 1450,
      maxHeight: 500,
      position: { my: "left top", at: "right top", of: `div#${this.ideabook_product.id}` },
      buttons: [
        {
          text: "OK",
          click: function () {
            dialogBox.dialog("close")
          },
        },
      ],
    })

    return false
  }

  @action changePhoto(obj) {
    this.ideabook_product.photo.sizes.medium.url = obj.photo.sizes.medium.url
    this.ideabook_product.photo.id = obj.photo.id
    this.ideabook_product.variant_photo_id = obj.photo.id
    this.props.ideabookStore.enqueueForUpdate({ ideabook_product: this.ideabook_product })
  }

  @action deleteProduct() {
    $("input").blur() // We need to keep the scroll from jumping after closing the dialog.
    let self = this
    let dialogBox = $(`#product_delete_dialog_${this.ideabook_product.id}`)
    dialogBox.dialog({
      modal: true,
      dialogClass: "no-close",
      minWidth: 400,
      maxWidth: 725,
      maxHeight: 500,
      buttons: [
        {
          text: "CANCEL",
          click: function () {
            dialogBox.dialog("close")
          },
        },
        {
          text: "DELETE",
          click: function () {
            self.doDeleteProduct(function (success, err) {
              if (err) {
                dialogBox.html("Uh Oh. Something went wrong: " + err)
              } else {
                dialogBox.dialog("close")
              }
            })
          },
        },
      ],
    })
    return false
  }

  @action doDeleteProduct(callback) {
    this.props.ideabookStore.deleteIdeabookProduct(
      { ideabook_product: this.ideabook_product },
      (success) => {
        console.log(success)
        callback(success, null)
      },
      (err) => {
        console.log(err)
        callback(null, err)
      }
    )
  }

  render() {
    let productImageUrl = "/images/default_photo.png"
    if (this.ideabook_product.photo.sizes.medium) {
      productImageUrl = this.ideabook_product.photo.sizes.medium.url
    }

    let dropBoxTemplDom = null
    if (this.product.dropbox_template_url) {
      dropBoxTemplDom = (
        <li>
          <a href={this.product.dropbox_template_url} target="_blank">
            <span className="ui-icon ui-icon-extlink"></span>
          </a>
        </li>
      )
    }

    let originalTemplDom = null
    if (this.ideabook_product.photo.sizes.original) {
      originalTemplDom = (
        <li>
          <a href={this.ideabook_product.photo.sizes.original.url} target="_blank">
            <span className="ui-icon ui-icon-newwin"></span>
          </a>
        </li>
      )
    }

    return (
      <div
        id={this.ideabook_product.id}
        className={`ideabook-product-container ui-cf ${this.ideabook_product.photo.type} ${
          this.product.selected ? "selected" : ""
        }`}
      >
        <div className="ribbon ribbon-top-left" style={{ display: `${this.product.inactive ? "block" : "none"}` }}>
          <span>inactive</span>
        </div>
        <ul>
          <li>
            <a href={`/admin/products/${this.product.id}`} target="_blank">
              <span className="ui-icon ui-icon-zoomin"></span>
            </a>
          </li>
          <li>
            <span className="ui-icon ui-icon-image" onClick={(e) => this.toggleAvailablePhotos()}></span>
          </li>
          {originalTemplDom}
          <li>
            <span className="ui-icon ui-icon-trash" onClick={(e) => this.deleteProduct()}></span>
          </li>
          {dropBoxTemplDom}
        </ul>
        <img src={productImageUrl} onClick={(e) => this.selectProduct(e.target)} className="product-image" />
        <br />
        <span className="product-name">{this.product.name}</span>
        <br />
        <span>{this.product.price}</span>
        <br />
        <PriceGroupSelect ideabook_product={this.ideabook_product} />
        <br />
        <div id={`product_photos_dialog_${this.ideabook_product.id}`}></div>
        <div id={`product_delete_dialog_${this.ideabook_product.id}`} style={{ display: "none" }}>
          You are about to delete this product from the lookbook. Are you sure you want to proceed?
        </div>
      </div>
    )
  }
}

export default Product
