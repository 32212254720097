import React from "react"
import { twMerge } from "tailwind-merge"
import style from "./NavButton.module.scss"

export type NavButtonProps = { isActive?: boolean } & JSX.IntrinsicElements["div"]

export function NavButton(props: NavButtonProps) {
  const { className, isActive = false, ...restProps } = props

  return <div className={twMerge([style.base, isActive && style.isActive, className])} {...restProps} />
}
