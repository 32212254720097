import React from "react"
import { humanize } from "underscore.string"
import { FallDown } from "~/src/components"
import { isArray, isBlank, isNil, isPresent } from "~/src/lib/any"
import { mapValues } from "~/src/lib/object"
import { validateDrop } from "../../validationSchemas/drop"
import style from "./MatchHeadersStep.module.scss"
import { WizardNextStep } from "./WizardNextStep"
import { WizardStep } from "./WizardStep"
import { WizardStepSidebar } from "./WizardStepSidebar"

export function MatchHeadersStep(props) {
  const { headers, rawData: [firstRow = []] = [], rows, setHeaders, setRows, nextStep, onNextClick } = props

  const handleChosenHeaderChange = (headerName) => (selectedOption) => {
    setHeaders((headers) => ({
      ...headers,
      [headerName]: {
        ...headers[headerName],
        columnIdx: isArray(selectedOption) ? selectedOption.map((option) => option?.value) : selectedOption?.value,
      },
    }))
  }

  const areRequiredHeadersMatched = Object.values(headers)
    .filter((h) => h.isRequired !== false)
    .every((h) => typeof h.columnIdx === "number" && h.columnIdx >= 0)

  return (
    <WizardStep
      sidebarRender={() => (
        <WizardStepSidebar>
          <h1>Match Headers</h1>
          <p>
            Match your spreadsheet headers with the provided categories by selecting your corresponding column from the
            dropdown list.
          </p>
          <br />

          <h3>Some Tips</h3>

          <p>
            Can't see a column from your spreadsheet in the dropdown? Make sure to give its first row column a name.
          </p>

          <p>
            The little blue <span className="periwinkle">R</span> means we require a match for this category! Otherwise,
            you can leave the dropdown blank.
          </p>

          <p>
            <strong style={{ color: "var(--good-color)" }}>Notes</strong> can take multiple columns. If you have a
            T-shirt size, custom message, or sequence number, match them all to this category.
          </p>

          <div className="gap"></div>
          <WizardNextStep disabled={!areRequiredHeadersMatched} stepName={nextStep} onClick={onNextClick} />
        </WizardStepSidebar>
      )}
    >
      <div className={style.headerMappers}>
        {Object.entries(headers).map(([headerName, { columnIdx, isGroup = false, isRequired = true }]) => {
          let defaultValue

          if (isArray(columnIdx)) {
            defaultValue = columnIdx.map((colIdx) => ({ label: firstRow[colIdx], value: colIdx }))
          } else {
            defaultValue =
              typeof columnIdx === "number" && columnIdx >= 0
                ? { label: firstRow[columnIdx], value: columnIdx }
                : undefined
          }

          return (
            <div key={headerName}>
              <div className={style.validHeader}>
                {isRequired && <span className="periwinkle">R</span>}
                <h2>{humanize(headerName)}</h2>
              </div>
              <FallDown
                isClearable
                clearValue={() => ({ label: "", value: null })}
                defaultValue={defaultValue}
                isMulti={isGroup}
                menuPlacement="auto"
                onChange={handleChosenHeaderChange(headerName)}
                options={firstRow
                  .map((value, idx) => ({ label: value, value: idx }))
                  .filter(({ label }) => isPresent(label))}
              />
            </div>
          )
        })}
      </div>
    </WizardStep>
  )
}

MatchHeadersStep.onNext = async (props) => {
  const { namespace, collection, table, rawData, fulfillmentRequest, fulfillmentKits = [], headers } = props
  const errors = {}
  const rows = []
  const [firstRow = []] = rawData ?? []

  await collection.delete()

  // Apply header mappings to rawData generating a bunch of rows
  // as objects
  rawData.slice(1).forEach((rawRow, index) => {
    const mappedRow = mapValues(({ columnIdx, pattern }, header) => {
      // Handle cells that are a grouping of multiple cells (i.e. notes)
      // If a single column is selected to merge into another, then it will be treated
      // as a single column instead of a grouped column.
      if (isArray(columnIdx) && columnIdx.length > 1) {
        return columnIdx
          .map((colIdx) => {
            const headerName = firstRow[colIdx]
            const cellValue = rawRow[colIdx]

            if (isBlank(headerName) || isBlank(cellValue)) return null
            if (pattern.test(headerName)) return cellValue

            return [headerName, " := ", cellValue].join("")
          })
          .filter(isPresent)
          .join("; ")
      } else {
        return rawRow[columnIdx?.[0] ?? columnIdx]
      }
    }, headers)

    // Skip row if none of the values are present
    if (!Object.values(mappedRow).some(isPresent)) return

    // Hacky "id" to allow easily adding new rows in position.
    // TODO: Find a better implementation
    if (isNil(mappedRow.id)) mappedRow.id = (index + 1) * 100

    mappedRow.namespace = namespace

    // Apply `transformCell` to each row
    const row = mapValues(
      (value, header, row) => headers[header]?.transformCell?.({ value, header, row, props }) ?? value,
      mappedRow
    )

    // Validate row
    const rowErrors = validateDrop({ allowedKits: fulfillmentKits.map((k) => k.name) }, row)

    row.errorMessages = []

    for (let { message, path } of rowErrors) {
      row.errorMessages.push(message)
      if (isNil(row.errors)) row.errors = {}
      row.errors[path] = message
    }

    rows.push(row)
  })

  await table.bulkAdd(rows)
}
