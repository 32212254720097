import * as React from "react"
import type { KeyboardEvent } from "react"
import { Bee } from "~/src/components/BeeKit"
import { useSetSearchQuery } from "./FormPage"

type SearchInputEvent = KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }

export type SearchInputProps = {
  liveSearch?: boolean
}

export function SearchInput(props: SearchInputProps) {
  const { liveSearch = false } = props
  const setSearch = useSetSearchQuery()

  const searchItems = (e: SearchInputEvent) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      setSearch(e.target.value)
    }
  }

  return liveSearch ? (
    <Bee.SearchInput onKeyDown={searchItems} onChange={(e: SearchInputEvent) => setSearch(e.target.value)} />
  ) : (
    <Bee.SearchInput onKeyDown={searchItems} />
  )
}
