import * as React from "react"
import { useContext } from "react"
import { DatePicker } from "~/src/components"
import { AdaFieldError as FieldError } from "~/src/components/AdaFieldError"
import { FulfillmentContext } from "~/src/components/PreferenceCollector/FulfillmentContext"
import { FulfillmentRequestDraft } from "~/src/components/PreferenceCollector/FulfillmentFields/FulfillmentMethodSection"

type FulfillLaterFieldsProps = {
  draft: FulfillmentRequestDraft
}
const createName = (association: string, name: string) => `${association}[${name}]`

export default function FulfillLaterFields({ draft }: FulfillLaterFieldsProps) {
  const { errors } = useContext(FulfillmentContext)
  const association = "preference_collector[placeholder_fulfillment_request_draft_attributes]"
  const estimatedNumberOfRecipientsName = createName(association, "estimated_number_of_recipients")
  const targetShipDateName = createName(association, "target_ship_date")

  return (
    <div className="mt-3">
      {draft.id && <input type="hidden" name={createName(association, "id")} value={draft.id} />}
      {draft.customOrderId && (
        <input type="hidden" name={createName(association, "custom_order_id")} value={draft.customOrderId} />
      )}
      {draft.requestedById && (
        <input type="hidden" name={createName(association, "requested_by_id")} value={draft.requestedById} />
      )}

      <div className="mt-2">
        <label className="float-none" htmlFor={estimatedNumberOfRecipientsName}>
          Estimated number of recipients
        </label>
        <input
          className="block text-xs w-48"
          defaultValue={draft.estimatedNumberOfRecipients}
          id={estimatedNumberOfRecipientsName}
          name={estimatedNumberOfRecipientsName}
          min={1}
          required
          step={1}
          type="number"
        />
        <FieldError errorMessage={errors.placeholderFulfillmentRequestDraft?.estimatedNumberOfRecipients} />
      </div>
      <div className="mt-2">
        <label className="float-none" htmlFor={targetShipDateName}>
          Target ship date
        </label>
        <DatePicker
          containerClass="text-xs w-48"
          dateInputName={targetShipDateName}
          required
          selectedDateString={draft.targetShipDate}
        />
        <FieldError errorMessage={errors.placeholderFulfillmentRequestDraft?.targetShipDate} />
      </div>
    </div>
  )
}
