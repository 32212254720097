import React from "react"
import { Awaitable } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import * as SerializedRecord from "~/src/serializedRecords"
import style from "./LegacyCatalogItem.module.scss"

export interface LegacyCatalogItemClickEvent {
  name: string
  ideabookProductId: number
  productId: number
  itemIndex: number
}

export interface LegacyCatalogItemProps extends SerializedRecord.CatalogItem {
  itemIndex: number
  className?: string
  onHideClick?: (event: LegacyCatalogItemClickEvent) => Awaitable<void>
  onLikeClick?: (event: LegacyCatalogItemClickEvent) => Awaitable<void>
}

export function LegacyCatalogItem(props: LegacyCatalogItemProps) {
  const {
    className,
    ideabookId,
    ideabookProductId,
    hidden,
    itemIndex,
    love,
    name,
    onHideClick,
    onLikeClick,
    price,
    privatePricing,
    productActive,
    lowPrice,
    productUrl,
    productId,
    photoUrl,
  } = props
  const addToCartUrl = `/projects/${ideabookId}/modal_contents/${ideabookProductId}?modal=true`

  return (
    <div className={twMerge([className])}>
      <div className={style.photoContainer}>
        <a href={productUrl}>
          <img src={photoUrl} loading="lazy" />
        </a>

        <ul className={style.itemActions}>
          <li
            className={twMerge(["love-button", "fa", love ? "fa-heart" : "fa-heart-o"])}
            onClick={() => onLikeClick?.({ name, ideabookProductId, itemIndex, productId })}
          />
          <li
            className={twMerge(["hidden-button", "fa", hidden ? "fa-ban" : "fa-ban-open"])}
            onClick={() => onHideClick?.({ name, ideabookProductId, itemIndex, productId })}
          />
        </ul>
      </div>

      <div className={style.details}>
        <a className={style.productTitle} href={productUrl}>
          {name}
        </a>
        <span>{privatePricing ? <>Contact us for pricing</> : <>From {toUSD.format(price ?? lowPrice)}</>}</span>
        {productActive ? (
          <>
            <a className="basic-button hide-for-medium-up" data-remote="true" href={addToCartUrl + "&opens=growl"}>
              Add To Cart
            </a>
            <a className="basic-button hide-for-small-down" data-remote="true" href={addToCartUrl + "&opens=mini-cart"}>
              Add To Cart
            </a>
          </>
        ) : (
          <span className="red">Unavailable</span>
        )}
      </div>
    </div>
  )
}

const toUSD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
