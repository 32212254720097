import cuid from "cuid"
import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"

@inject(["ideabookStore"])
@observer
class CustomRequestUsers extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.users = props.ideabookStore.ideabook.custom_request_users
    this.ideabook = props.ideabookStore.ideabook
    this.shared_with = props.ideabookStore.ideabook.shared_with
    this.possible_users = props.ideabookStore.ideabook.enumerations.users
    this.sortByShareStatus()
    this.state = { search: "", includeStoreUsers: false }
  }

  @action sortByShareStatus() {
    this.possible_users = this.possible_users.map((u) => {
      u.shared = this.alreadyShared(u.id)
      return u
    })

    this.possible_users = _.sortBy(this.possible_users, [
      (u) => {
        return !u.shared
      },
      (u) => {
        return u.name
      },
    ])
  }

  @action getCustomRequestUserByUserId(id) {
    return _.find(this.users, (u) => {
      return u.custom_request_user.user.id === id
    })
  }

  @action alreadyShared(id) {
    return !!this.shared_with.find((u) => u.id === id)
  }

  @action searchFilteredPossibleUsers() {
    // not the owner id (ideabook.user_id) && not in the shared_with list
    let unsharedPossibleUsers = this.possible_users.filter(
      (u) => u.id !== this.ideabook.user_id && !this.shared_with.find((su) => su.user_id === u.id)
    )
    return _.filter(unsharedPossibleUsers, (u) => {
      if (u.store_user && !this.state.includeStoreUsers) return false
      if (this.state.search === "") {
        return true
      } else {
        return _.startsWith(_.lowerCase(u.name), this.state.search)
      }
    })
  }

  @action filterPossibleUsers(search) {
    this.setState({ search: _.lowerCase(search) })
  }

  numStoreUsers() {
    return _.filter(this.possible_users, (u) => {
      return u.store_user
    }).length
  }

  @action addSharedUser(id) {
    this.props.ideabookStore.createCustomRequestUser(
      {
        custom_request_user: {
          user_id: id,
          ideabook_id: this.props.ideabookStore.ideabook.id,
        },
      },
      (success) => {
        console.log(success)
        this.sortByShareStatus()
        this.forceUpdate()
      },
      (error) => {
        console.log(error)
      }
    )
  }

  @action removeSharedUser(id, type) {
    if (type !== "CustomRequestUser") {
      // this is an external shared user
      this.props.ideabookStore.deleteExternalUser(
        id,
        () => {
          this.sortByShareStatus()
          this.forceUpdate()
        },
        (err) => {
          console.log(err)
        }
      )
    } else {
      this.props.ideabookStore.deleteCustomRequestUser(
        id,
        (success) => {
          console.log(success)
          this.sortByShareStatus()
          this.forceUpdate()
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }

  render() {
    return (
      <div id="users_box" className="ui-widget-content u-cf">
        <h4>Shared with</h4>
        <ul>
          {this.shared_with.map((u) => {
            return (
              <li
                key={`shared-with-user-${u.id}-${cuid()}`}
                className="u-cf"
                onClick={() => {
                  this.removeSharedUser(u.id, u.type)
                }}
              >
                <span>
                  <span className="ui-icon ui-icon-heart"></span>
                  {u.name || u.email}
                </span>
              </li>
            )
          })}
        </ul>
        <input
          name="search"
          onChange={(e) => {
            this.filterPossibleUsers(e.target.value)
          }}
        />
        <ul>
          {this.searchFilteredPossibleUsers().map((pu) => {
            return (
              <li
                key={`possible-user-${pu.id}`}
                className="u-cf"
                onClick={() => {
                  this.addSharedUser(pu.id)
                }}
              >
                <span className="line-through">
                  <span className="ui-icon ui-icon-cancel line-through"></span>
                  {pu.name}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default CustomRequestUsers
