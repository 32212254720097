import { Toaster, toast } from "react-hot-toast"
import { merge } from "lodash-es"

const messageStyle = {
  warning: {
    style: {
      border: "1px solid var(--toast-warning-highlight-color)",
      borderRadius: "3px",
      color: "var(--toast-warning-color)",
    },
    iconTheme: {
      primary: "var(--toast-warning-color)",
      secondary: "white",
    },
    icon: "⚠️",
  },

  error: {
    style: {
      border: "1px solid var(--toast-error-highlight-color)",
      borderRadius: "3px",
      color: "var(--toast-error-color)",
    },
    iconTheme: {
      primary: "var(--toast-error-color)",
      secondary: "white",
    },
  },
}

const error: (typeof toast)["error"] = (message, opts) => {
  return toast.error(message, merge(messageStyle["error"], opts))
}

const warning: (typeof toast)["custom"] = (message, opts) => {
  return toast(message, merge(messageStyle["warning"], opts))
}

export const retoast = {
  error,
  warning,
}

export { Toaster }
