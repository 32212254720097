import React, { useEffect, useRef, useState } from "react"

/**
 * A component to input tags that wraps jQuery tagit plugin.
 * Can not be controlled.
 * @see http://aehlke.github.com/tag-it/
 * @param {*} props
 */
export function TagsInput(props) {
  const { availableTags: rawAvailableTags = [], name, onChange = () => {}, tagitOptions = {}, ...restProps } = props
  const [availableTags, setAvailableTags] = useState(Array.isArray(rawAvailableTags) ? rawAvailableTags : [])

  const inputRef = useRef(null)

  // Initialize then destroy tagit plugin
  useEffect(() => {
    if (typeof $ !== "function") {
      console.error(new Error("TagsInput cannot find jQuery"))
      return
    }

    if (typeof $.fn.tagit !== "function") {
      console.error(new Error("TagsInput cannot find tagit jQuery plugin"))
      return
    }

    const $input = $(inputRef.current)
    $input.tagit({
      ...tagitOptions,
      availableTags,
      allowSpaces: true,
      autocomplete: {
        minLength: 1,
      },
      afterTagAdded(...args) {
        onChange(
          $input
            .val()
            .trim()
            .split(/\s*,\s*/)
        )

        if (typeof tagitOptions.afterTagAdded === "function") {
          tagitOptions.afterTagAdded(...args)
        }
      },
      afterTagRemoved(...args) {
        onChange(
          $input
            .val()
            .trim()
            .split(/\s*,\s*/)
        )

        if (typeof tagitOptions.afterTagRemoved === "function") {
          tagitOptions.afterTagRemoved(...args)
        }
      },
      fieldName: name || tagitOptions.fieldName,
    })

    return () => {
      if ($input && typeof $input.destroy === "function") {
        $input.destroy()
      }
    }
  }, [availableTags, name, onChange, tagitOptions])

  // If rawAvailableTags is a function, get tags and set in state
  useEffect(() => {
    if (typeof rawAvailableTags === "function") {
      ;(async () => {
        const tags = await rawAvailableTags()
        setAvailableTags(tags)
      })()
    }
  }, [rawAvailableTags])

  return <input ref={inputRef} {...restProps} />
}
