import React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"

@inject(["ideabookStore"])
@observer
class Statuses extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.statuses = props.ideabookStore.ideabook.enumerations.statuses
    this.status = props.ideabookStore.ideabook.status
  }

  @action changeHandler(value) {
    this.props.ideabookStore.ideabook["status"] = value
    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.props.ideabookStore.ideabook })
  }

  render() {
    if (this.statuses) {
      return (
        <select onChange={(e) => this.changeHandler(e.target.value)} defaultValue={this.status}>
          {this.statuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      )
    } else {
      return null
    }
  }
}

export default Statuses
