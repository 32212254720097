import React from "react"
import { twMerge } from "tailwind-merge"

export type TabProps = {
  label: string
  isSelected: boolean
  onClick: () => void
  className?: string
  ref?: React.Ref<HTMLButtonElement>
}

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  ({ label, isSelected, onClick, className = "text-black border-b border-black" }, ref) => {
    const tabClass = isSelected ? className : "text-gray-500"

    return (
      <button
        className={twMerge("block pb-2 pt-2 text-base text-nowrap", tabClass)}
        type="button"
        onClick={onClick}
        ref={ref}
      >
        {label}
      </button>
    )
  }
)

Tab.displayName = "Tab"
