import React, { ReactNode, forwardRef } from "react"
import { twMerge } from "tailwind-merge"

export type WorkflowContainerProps = {
  className?: string
  children: ReactNode
}

export const WorkflowContainer = forwardRef(function (props: WorkflowContainerProps, ref: React.ForwardedRef<any>) {
  const { className, children } = props

  return (
    <div ref={ref} className={twMerge("bg-white rounded-lg px-8 lg:px-20 py-12 sm:mt-8 sm:mx-6", className)}>
      {children}
    </div>
  )
})
