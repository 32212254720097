import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"
import style from "./Elliptick.module.scss"

export interface ElliptickProps {
  interval?: number
}

/**
 * Animated ellipsis dots meant to be used as a loading animation. Dots transition from "", ".", "..", "...", "", and
 * so on every half-second by default.
 * @param props
 */
export function Elliptick(props: ElliptickProps) {
  const { interval = 500 } = props
  const [ticker, setTicker] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTicker((ticker) => (ticker + 1) % 4)
    }, interval)

    return () => {
      clearInterval(intervalId)
    }
  }, [interval])

  const ticks = Array.from({ length: 3 }, (_, i) => (
    <span
      key={i}
      className={twMerge([style.dot, i < ticker && style.on])}
      style={{ transitionDuration: `${interval}ms` }}
    >
      .
    </span>
  ))

  return <>{ticks}</>
}
