import cuid from "cuid"
import { create } from "zustand"
import * as SerializedRecord from "~/src/serializedRecords"

export type Destination = {
  key: string
  alpha2?: string
  cost?: number
  zoning?: "residential" | "commercial"
  quantity?: number
  carrier?: string
  service?: string
}

export type NewOrderItem = { id?: number } & Omit<SerializedRecord.OrderItem, "id">

export type ShippingCostEstimatorStore = {
  estimateId?: number
  destinations: Destination[]
  addDestination(destination: Destination): void
  removeDestination(index: number): void
  setEstimateId(id: number): void
  setDestinations(destinations: (Destination & { cost?: number })[]): void
  updateDestinationByAlpha2AndZoning(alpha2: string, zoning: string, newDestination: Partial<Destination>): void
}

export const useShippingCostEstimatorStore = create<ShippingCostEstimatorStore>((set) => ({
  destinations: [{ key: cuid(), alpha2: "US", zoning: "residential", quantity: 1 }],
  variants: [],
  setEstimateId(id) {
    set(() => ({ estimateId: id }))
  },
  setDestinations(destinations) {
    set(() => ({ destinations }))
  },
  addDestination(destination) {
    set((s) => ({ destinations: s.destinations.concat([destination]) }))
  },
  updateDestinationByAlpha2AndZoning(alpha2, zoning, newDestination) {
    set((s) => ({
      destinations: s.destinations.map((d) => {
        if (d.alpha2 === alpha2 && d.zoning === zoning) {
          return { ...d, ...newDestination }
        } else {
          return d
        }
      }),
    }))
  },
  removeDestination(index) {
    set((s) => ({ destinations: s.destinations.filter((_, i) => i !== index) }))
  },
}))
