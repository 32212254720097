import React from "react"
import { twMerge } from "tailwind-merge"
import style from "./CornerTriangle.module.scss"

export function CornerTriangle(props) {
  const { color = "black", size = 20, ...restProps } = props

  return (
    <div
      className={twMerge(["corner-triangle", style.base])}
      style={{
        "--triangle-size": size + "px",
        "--triangle-color": color,
      }}
      {...restProps}
    >
      <div></div>
    </div>
  )
}
