import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"

@inject(["ideabookStore"])
@observer
class Owners extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ams = props.ideabookStore.ideabook.enumerations.account_managers
    this.am = props.ideabookStore.ideabook.account_manager
  }

  @action amChangeHandler(id) {
    this.props.ideabookStore.ideabook.account_manager = _.find(this.ams, (am) => {
      return am.id == id
    })
    this.props.ideabookStore.ideabook.account_manager_id = id
    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.props.ideabookStore.ideabook })
  }

  render() {
    let book = this.props.ideabookStore.ideabook

    let companyBook = book.company && book.company.id
    let userBook = book.user && book.user.id

    let companyName = book.company.name
    let companyId = book.company.id
    let userId = book.user.id
    let userName = book.user.name
    let userCompany = book.user.company

    let userCompanyLink = ""
    if (userCompany) {
      userCompanyLink = (
        <a href={"/admin/companies/" + userCompany.id} target="_blank">
          {`(${userCompany.name || "No Name"})`}
        </a>
      )
    }

    if (this.ams) {
      return (
        <div>
          <select onChange={(e) => this.amChangeHandler(e.target.value)} defaultValue={this.am.id}>
            <option value=""></option>
            {this.ams.map((am) => (
              <option key={am.id} value={am.id}>
                {am.name}
              </option>
            ))}
          </select>
          {companyBook ? (
            <span>
              For Company:{" "}
              <a href={"/admin/companies/" + companyId} target="_blank">
                {companyName || "No Name"}
              </a>{" "}
              by&nbsp;
            </span>
          ) : userBook ? (
            <span>
              For User:{" "}
              <a href={"/admin/users/" + userId} target="_blank">
                {userName || "No Name"}
              </a>{" "}
              {userCompanyLink} by&nbsp;
            </span>
          ) : (
            <span>Public&nbsp;by&nbsp;</span>
          )}
        </div>
      )
    } else {
      return null
    }
  }
}

export default Owners
