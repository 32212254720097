import React from "react"
import { TitleFieldProps } from "@rjsf/utils"

export function TitleFieldTemplate({ id, title }: TitleFieldProps) {
  return (
    <h2 className="font-medium text-xl pt-2 pb-4" id={id}>
      {title}
    </h2>
  )
}
