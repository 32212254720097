import React, { useState, useEffect, useMemo, useCallback, useRef } from "react"
import { unparse } from "papaparse"
import { ReadyState } from "./ready"
import { Spreadsheet } from "~/src/components"
import { RowId, SpreadsheetRow } from "~/src/components/Spreadsheet/types"
import { TableKey, RowValue, HeaderValue } from "./schema"
import { useTable } from "~/src/hooks"
import { isNil } from "~/src/lib/any"
import { permitKeys } from "~/src/lib/object"
import { Validations } from "./validate"
import { RowFilterFn } from "./filter"
import { ErrorData, ErrorTracker } from "./errorTracker"
import { Sidebar } from "./Sidebar"
import { Summary } from "./Summary"
import { AttachmentHandler } from "./AttachmentHandler"
import { withDefaultOptions } from "./defaultColumns"
import { SidebarCTAButtonProps } from "../FulfillmentFlow/ReviewDropshipList"

import "./DropshipEditor.scss"

export type SidebarCTAButtonComponent = (props: SidebarCTAButtonProps) => JSX.Element

export interface DriverProps {
  dropshipListName: string
  dropshipListId: string
  isReadOnly?: boolean
  submitUrl: string
  submitMethod?: "patch" | "post"
  fileInputHTTPName?: string
  sidebarKind: SidebarKind
  SidebarCTAButton?: SidebarCTAButtonComponent
  requireUserConfirmation?: boolean
}

export enum SidebarKind {
  Edit = "edit", // Errors present in the spreadsheet contents
  Review = "review", // A summary pane of the spreadsheet contents
  None = "none", // No sidebar at all, spreadsheet takes up all the space
}

export function Driver(props: DriverProps) {
  const { dropshipListName, isReadOnly, sidebarKind, submitUrl } = props
  const headerKey: TableKey = "headers"
  const rowKey: TableKey = "rows"

  const { namespace, collection: rowsCollection, table: rowsTable } = useTable(rowKey)
  const { collection: headersCollection } = useTable(headerKey)

  const [readyState, setReadyState] = useState<ReadyState>("initial")
  const [rowFilter, setRowFilter] = useState<RowFilterFn | null>(null)
  const [validations, setValidations] = useState<Validations | null>(null)
  const [errors, setErrors] = useState<ErrorData>({ count: 0, errors: {}, errorsByFamily: {}, display: {} })
  const [colData, setColData] = useState<HeaderValue["columns"]>([])
  const [rowData, setRowData] = useState<RowValue[]>([])
  const [customFieldFormats, setCustomFieldFormats] = useState<HeaderValue["formats"]>({})
  const [nextId, setNextId] = useState(0)
  const isMountedRef = useRef(true)
  const downloadAnchorRef: React.Ref<HTMLAnchorElement> = useRef(null)
  const onDownload = useCallback(() => {
    if (!downloadAnchorRef.current) {
      return
    }

    const colKeys = colData.map((col) => col.key)
    const permitColumns = (row: RowValue) => permitKeys(row, colKeys)
    const csvData = unparse(rowData.map(permitColumns))
    downloadAnchorRef.current.href = "data:text/plain;charset=utf-8," + encodeURIComponent(csvData)
    downloadAnchorRef.current.download = `${dropshipListName.slice(0, 64)} working copy.csv`
    downloadAnchorRef.current.click()
  }, [dropshipListName, rowData, colData])

  const filteredRows = rowFilter ? rowData.filter(rowFilter) : rowData

  const onApplyFilter = (filter: RowFilterFn | null) => {
    setRowFilter(() => filter)
  }
  const onUnapplyFilter = () => {
    setRowFilter(() => null)
  }

  const sidebar = useMemo(() => {
    switch (sidebarKind) {
      case SidebarKind.Review:
        return <Summary rows={rowData} customFormats={customFieldFormats} />
      case SidebarKind.Edit:
        return (
          <Sidebar
            draftName={dropshipListName}
            columns={colData}
            rows={rowData}
            filteredRows={filteredRows}
            totalRowCount={rowData.length}
            errors={errors}
            onApplyFilter={onApplyFilter}
            onUnapplyFilter={onUnapplyFilter}
            SidebarCTAButton={props.SidebarCTAButton}
            submitUrl={submitUrl}
            submitMethod={props.submitMethod}
            fileInputHTTPName={props.fileInputHTTPName}
            requireUserConfirmation={props.requireUserConfirmation}
          />
        )
      case SidebarKind.None:
        return null
    }
  }, [sidebarKind, rowFilter, colData, filteredRows, rowData, errors])

  const sidebarCss = sidebarKind == SidebarKind.None ? "" : " include-sidebar"

  // Initial table scan
  useEffect(() => {
    setReadyState("initial")
    Promise.all([
      headersCollection.first(),
      rowsCollection.toArray(),
      rowsTable
        .orderBy("id")
        .last()
        .then((row: RowValue) => row.id), // NOTE(rschifflin): Very annoying that we can't combine where("namespace = ...").orderBy("...")
    ])
      .then((result: [HeaderValue, RowValue[], RowValue["id"]]) => {
        if (!isMountedRef.current) {
          return
        }
        const [headerData, rowData, lastRowId] = result
        const validations = new Validations(headerData.columns, headerData.formats)

        setColData(withDefaultOptions(headerData.columns))
        setRowData(rowData)
        setCustomFieldFormats(headerData.formats)
        setValidations(validations)
        setNextId(lastRowId + 1)

        // First error pass
        const errorTracker = new ErrorTracker(headerData.columns.map((col) => col.key))
        rowData.forEach((row: RowValue) => {
          errorTracker.addErrorRow(row.id, validations.validateRow(row))
        })

        setErrors(errorTracker.state())
        setReadyState("success")
      })
      .catch((err) => {
        if (isMountedRef.current) {
          setReadyState("failure")
        }
        throw err
      })

    return () => {
      isMountedRef.current = false
    }
  }, [])

  switch (readyState) {
    case "initial":
      return <div>Loading your spreadsheet. Please be patient.</div>
    case "failure":
      return <div>Failed to load the spreadsheet. Please try again.</div>
    case "success":
      if (isReadOnly) {
        return (
          <div className={`dropship-editor-container${sidebarCss}`}>
            {sidebar}
            <AttachmentHandler rows={rowData} columns={colData} />
            <a ref={downloadAnchorRef} style={{ display: "none" }} />
            <div className="dropship-editor-spreadsheet-container">
              <Spreadsheet
                isReadOnly={true}
                columns={colData}
                rows={filteredRows}
                formats={customFieldFormats}
                errors={errors.display}
                onDownload={onDownload}
              />
            </div>
          </div>
        )
      } else {
        const makeNewEmptyRow = () => {
          const row = { id: nextId, namespace }
          colData.forEach(({ key }) => {
            row[key] = ""
          })
          setNextId(nextId + 1)
          return row
        }

        const onRowsChange = (changedRows: SpreadsheetRow[]) => {
          const errorTracker = new ErrorTracker(colData.map((col) => col.key))
          const changedRowMap: Map<RowId, RowValue> = new Map(changedRows.map((r) => [r.id, { namespace, ...r }]))
          const newRowData: RowValue[] = []
          rowData.forEach((row) => {
            const changedRow = changedRowMap.get(row.id)
            if (changedRow) {
              errorTracker.addErrorRow(row.id, validations?.validateRow(changedRow))
              newRowData.push(changedRow)
            } else {
              errorTracker.addErrorRow(row.id, errors.errors[row.id])
              newRowData.push(row)
            }
          })
          setErrors(errorTracker.state())
          setRowData(newRowData)

          // TODO: Handle this promise in case rejected
          rowsTable.bulkPut(Array.from(changedRowMap.values()))
        }

        const onRowsCreate = (selectedRow: SpreadsheetRow, opts: { direction?: "up" | "down" }) => {
          // Don't bother to persist empty rows. They'll be saved when edited by onRowsChange
          const newEmptyRow = makeNewEmptyRow()
          setRowData((rowData) => {
            if (rowData.length === 0) {
              return [newEmptyRow]
            } else if (isNil(selectedRow)) {
              return [...rowData, newEmptyRow]
            } else {
              let rowIndex = rowData.findIndex((row) => row.id == selectedRow.id)
              if (opts.direction === "down") {
                rowIndex += 1
              }
              return [...rowData.slice(0, rowIndex), newEmptyRow, ...rowData.slice(rowIndex)]
            }
          })

          const errorTracker = new ErrorTracker(
            colData.map((col) => col.key),
            errors
          )
          errorTracker.addErrorRow(newEmptyRow.id, validations?.validateRow(newEmptyRow))
          setErrors(errorTracker.state())
        }

        const onRowsDelete = (deletedRows: SpreadsheetRow[]) => {
          const deletedRowIds = new Set(deletedRows.map((r) => r.id))
          const errorTracker = new ErrorTracker(
            colData.map((col) => col.key),
            errors
          )
          const newRowData: RowValue[] = []
          let placeholderInitialRow: RowValue | null = null

          rowData.forEach((row) => {
            if (deletedRowIds.delete(row.id)) {
              errorTracker.removeErrorRow(row.id)
            } else {
              newRowData.push(row)
            }
          })

          if (newRowData.length === 0) {
            placeholderInitialRow = makeNewEmptyRow()
            errorTracker.addErrorRow(placeholderInitialRow.id, validations?.validateRow(placeholderInitialRow))
            setRowData([placeholderInitialRow])
          } else {
            setRowData(newRowData)
          }
          setErrors(errorTracker.state())

          const deletedKeys = deletedRows.map((r) => [namespace, r.id])
          // TODO: Handle this promise in case rejected
          rowsTable.bulkDelete(deletedKeys).then(() => {
            if (placeholderInitialRow) {
              rowsTable.put(placeholderInitialRow)
            }
          })
        }

        return (
          <div className={`dropship-editor-container${sidebarCss}`}>
            {sidebar}
            <AttachmentHandler rows={rowData} columns={colData} />
            <a ref={downloadAnchorRef} style={{ display: "none" }} />
            <div className="dropship-editor-spreadsheet-container">
              <Spreadsheet
                columns={colData}
                rows={filteredRows}
                formats={customFieldFormats}
                errors={errors.display}
                onRowsChange={onRowsChange}
                onRowsCreate={onRowsCreate}
                onRowsDelete={onRowsDelete}
                onDownload={onDownload}
              />
            </div>
          </div>
        )
      }
  }
}
