import * as React from "react"
import { twMerge } from "tailwind-merge"

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  kind: "primary" | "secondary"
}
type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean
  kind: "primary" | "secondary"
}

const buttonLinkBase = [
  "p-2",
  "md:p-3",
  "text-center",
  "text-sm",
  "transition",
  "border",
  "duration-150",
  "rounded-lg",
  "font-medium",
]

const disabledStyleClassNames = (kind: "primary" | "secondary"): Array<string> => {
  let classNames = ["cursor-not-allowed", "opacity-80"]

  switch (kind) {
    case "primary": {
      classNames.push("bg-gray-300")
      break
    }
    case "secondary": {
      classNames.push("stroke-gray-800", "text-gray-300")
      break
    }
  }

  return classNames
}

const hoverStyleClassNames = (kind: "primary" | "secondary"): Array<string> => {
  let classNames: Array<string> = []

  switch (kind) {
    case "primary": {
      classNames.push("hover:bg-navy-900")
      break
    }
    case "secondary": {
      classNames.push("hover:border-gray-800", "hover:stroke-gray-800")
      break
    }
  }

  return classNames
}

const buttonLinkKindClassnames = (kind: "primary" | "secondary"): Array<string> => {
  switch (kind) {
    case "primary": {
      return ["text-white", "font-medium", "bg-navy-800", "focus:bg-navy-900", "focus:stroke-blueslate-100"]
    }
    case "secondary": {
      return ["border-gray-200", "stroke-gray-200", "text-gray-800"]
    }
  }
}

const buttonCommon = [
  "p-2",
  "md:p-3",
  "min-w-20",
  "text-center",
  "text-sm",
  "rounded-lg",
  "transition",
  "border",
  "duration-150",
  "disabled:cursor-not-allowed",
]

const buttonKindClassnames = (kind: "primary" | "secondary"): Array<string> => {
  switch (kind) {
    case "primary": {
      return [
        "text-white",
        "font-medium",
        "bg-navy-800",
        "hover:bg-navy-900",
        "focus:bg-navy-900",
        "focus:stroke-blueslate-100",
        "disabled:bg-gray-300",
      ]
    }
    case "secondary": {
      return [
        "border-gray-200",
        "stroke-gray-200",
        "text-gray-800",
        "hover:border-gray-800",
        "hover:stroke-gray-800",
        "disabled:stroke-gray-800",
        "disabled:text-gray-300",
      ]
    }
  }
}

export function ButtonLink({ kind, className, disabled, href: originalHref, ...props }: LinkProps) {
  let href = originalHref

  if (disabled) {
    href = undefined
  }

  return (
    <a
      className={twMerge(
        buttonLinkBase,
        buttonLinkKindClassnames(kind),
        className,
        !disabled && hoverStyleClassNames(kind),
        disabled && disabledStyleClassNames(kind)
      )}
      href={href}
      {...props}
    />
  )
}

export function Button({ className, kind, ...props }: ButtonProps) {
  return <button className={twMerge(buttonCommon, buttonKindClassnames(kind), className)} {...props} />
}
