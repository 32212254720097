import React, { CSSProperties, DetailedHTMLProps, HTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"
import style from "./Spinner.module.scss"

export interface SpinnerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  kind?: string
  size?: string
}

export function Spinner(props: SpinnerProps) {
  const { className, kind = "primary", size = "60px", ...restProps } = props

  return (
    <div
      className={twMerge([style.base, style[kind], className])}
      style={{ "--size": size } as CSSProperties}
      {...restProps}
    />
  )
}
