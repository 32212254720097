import { camelCase, debounce, fromPairs, mapKeys, omit, sortBy, update } from "lodash-es"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { humanize } from "underscore.string"
import { Spinner } from "~/src/components"
import { isNil, isString, isntNil } from "~/src/lib/any"
import { appClient } from "~/src/lib/appClients"
import { twMerge } from "tailwind-merge"
import * as SerializedRecord from "~/src/serializedRecords"
import style from "./LegacyCatalog.module.scss"
import { LegacyCatalogItem, LegacyCatalogItemClickEvent } from "./LegacyCatalogItem"
import { LegacyCatalogSearchForm } from "./LegacyCatalogSearchForm"

interface ItemState extends SerializedRecord.CatalogItem {
  itemIndex: number
}

type ItemSorter = (item: ItemState) => number

const sortOptions: Record<string, ItemSorter> = {
  newestFirst(a) {
    return -a.createdAt
  },
  oldestFirst(a) {
    return a.createdAt
  },
  favorites(a) {
    return a.love ? 0 : 1
  },
  priceLowToHigh(a) {
    return a.price
  },
  priceHighToLow(a) {
    return -a.price
  },
  minimumLowToHigh(a) {
    return -a.minimum
  },
  minimumHighToLow(a) {
    return a.minimum
  },
  hidden(a) {
    return a.hidden ? 0 : 1
  },
}

const sorts = fromPairs(Object.keys(sortOptions).map((k) => [k, humanize(k)]))

type SortState = keyof typeof sortOptions | undefined

const filterOptions = {
  nameOrBrand(item: ItemState, value: string) {
    if (isString(value)) return item.name?.toLowerCase().includes(value?.toLowerCase()) === true

    return false
  },
  category(item: ItemState, value: string) {
    if (typeof value === "number") return item.topLevelCategories.has(value)

    return false
  },
}

type FiltersState = { [P in keyof typeof filterOptions]?: string | number }

type SortIndexesState = Record<string, number[]>

export interface LegacyCatalogProps {
  categories: { id: number; name: string; warning: string }[]
}

export function LegacyCatalog(props: LegacyCatalogProps) {
  const { categories: categoriesRaw } = props
  const itemMapRef = useRef(new Set())
  const [items, setItems] = useState<ItemState[]>([])
  const [sort, setSort] = useState<SortState | undefined>()
  const [sortIndexes, setSortIndexes] = useState<SortIndexesState>({})
  const [filters, setFilters] = useState<FiltersState>({})
  const [fetching, setFetching] = useState(true)
  const categories = useMemo(() => fromPairs(categoriesRaw.map(({ id, name }) => [id, name])), [categoriesRaw])
  const sortedItems = useMemo(
    () => (isNil(sort) ? items : sortIndexes[sort]?.map((v) => items[v])) ?? items,
    [items, sort, sortIndexes]
  )

  // Download LegacyCatalog payload from Catalog#load_v2
  useEffect(() => {
    ;(async () => {
      let page = 1
      let totalCount = Infinity
      let lastCount = Infinity
      let currentCount = 0
      const per_page = 200

      try {
        while (currentCount < totalCount && lastCount > 0) {
          const response = await appClient.get<{ data: ItemState[]; count: number }>("catalog/load_v2", {
            params: { offset: currentCount, per_page: page <= 1 ? 50 : per_page },
          })

          const { data, count } = response?.data ?? {}

          page += 1
          currentCount += data.length
          lastCount = data.length
          if (totalCount > count) totalCount = count

          setItems((items) => {
            // remove possible duplicates from offset query
            const filteredData = data.filter((i) => !itemMapRef.current.has(i.ideabookProductId))
            filteredData.forEach((i) => itemMapRef.current.add(i.ideabookProductId))

            return items.concat(prepareItemState(filteredData, items.length))
          })
        }
      } finally {
        setFetching(false)
      }
    })()
  }, [setItems])

  // Cache sort index
  useEffect(() => {
    if (isntNil(sort) && (isNil(sortIndexes[sort]) || sortIndexes[sort].length !== items.length)) {
      const newSortIndexes = { ...sortIndexes }
      newSortIndexes[sort] = sortBy(items, sortOptions[sort]).map((item) => item.itemIndex)
      setSortIndexes(newSortIndexes)
    }
  }, [items, sort, setSortIndexes])

  const handleItemLikeClick = async (event: LegacyCatalogItemClickEvent) => {
    const { productId, itemIndex } = event
    const { data } = await appClient.put<{ love: boolean }>(`/api/i/product_preferences/${productId}/toggle/love`)

    setSortIndexes((sI) => omit(sI, ["favorites"]))
    setItems((items) => update([...items], `[${itemIndex}]`, (item) => ({ ...item, love: data.love })))
  }

  const handleItemHideClick = async (event: LegacyCatalogItemClickEvent) => {
    const { productId, itemIndex } = event
    const { data } = await appClient.put<{ hidden: boolean }>(`/api/i/product_preferences/${productId}/toggle/hidden`)

    setSortIndexes((sI) => omit(sI, ["hidden"]))
    setItems((items) => update([...items], `[${itemIndex}]`, (item) => ({ ...item, hidden: data.hidden })))
  }

  const handleNameOrBrandChange = debounce((value: string) => {
    const newFilters = { ...filters }

    if (value.length > 2) newFilters.nameOrBrand = value
    if (value.length < 1) delete newFilters.nameOrBrand

    setFilters(newFilters)
  }, 200)

  const handleCategoryFilterChange = (categoryId?: number) => {
    const newFilters = { ...filters }

    if (isNil(categoryId)) delete newFilters.category
    if (typeof categoryId === "number") newFilters.category = categoryId

    setFilters(newFilters)
  }

  const handleSortChange = (sortName?: string) => {
    setSort(sortName)
  }

  const handleClearFiltersClick = () => {
    setSort(undefined)
    setFilters({})
  }

  const preparedItems =
    Object.keys(filters).length > 0
      ? sortedItems.filter((item) =>
          Object.entries(filters).reduce(
            (acc, [filterName, arg]) => acc && filterOptions[filterName]?.(item, arg),
            true
          )
        )
      : sortedItems

  return (
    <div className={twMerge([style.base])}>
      <div className={style.heading}>
        <div className={style.headingText}>
          <h1 className="font-souvenir">Your Catalog</h1>
          <span className="show-for-medium-up">Every product we&apos;ve ever shown you, in one place.</span>
          <span className="show-for-small-down">Every product we&apos;ve ever shown you, in one place.</span>
        </div>
        <LegacyCatalogSearchForm
          categories={categories}
          onCategoryFilterChange={handleCategoryFilterChange}
          onNameOrBrandFilterChange={handleNameOrBrandChange}
          onClearFilterClick={handleClearFiltersClick}
          onSortChange={handleSortChange}
          sorts={sorts}
        />
      </div>

      <div className={style.itemList}>
        {preparedItems.map((item) => (
          <LegacyCatalogItem
            key={item.ideabookProductId}
            onLikeClick={handleItemLikeClick}
            onHideClick={handleItemHideClick}
            {...item}
          />
        ))}
      </div>
      {fetching === false && preparedItems.length <= 0 && (
        <div className="empty-search">
          <h6>We couldn&apos;t find any products matching your search terms</h6>
        </div>
      )}
      {fetching && (
        <div className={style.loader}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function prepareItemState(data: any[], indexStart = 0): ItemState[] {
  return data.map((datum, index: number) => {
    const newDatum = mapKeys(datum, (_, k) => camelCase(k))

    newDatum.lowPrice = +newDatum.lowPrice
    newDatum.price = +newDatum.price
    newDatum.topLevelCategories = new Set(newDatum.topLevelCategories.split(/\s*,\s*/).map((x: string) => +x))
    newDatum.itemIndex = index + indexStart

    return newDatum as ItemState
  })
}
