import React, { useEffect } from "react"
import { transliterate as tr } from "transliteration"
import { Download, InputSheet } from "~/src/components"
import { isBlank, isNil } from "~/src/lib/any"
import { mapValues } from "~/src/lib/object"
import { RoundedButton } from "./RoundedButton"
import style from "./UploadStep.module.scss"
import { WizardNextStep } from "./WizardNextStep"
import { WizardStep } from "./WizardStep"
import { WizardStepSidebar } from "./WizardStepSidebar"

export function UploadStep(props) {
  const { headers, setHeaders, setRawData, nextStep, onNextClick, rawData } = props

  const handleInputSheetComplete = ({ data }) => {
    setRawData(data)
  }

  const handleDownloadTemplate = () => {
    const defaultHeaders = Object.keys(headers)
    return defaultHeaders.join()
  }

  const handleResetDatabase = () => {
    confirm("Your browser database will be reset clearing saved data for all dropship wizards!")
    window?.indexedDB?.deleteDatabase?.("dropship_wizard")
    window.location.hash = "#upload"
    window.location.reload()
  }

  // Clear state when starting again
  useEffect(() => {
    setRawData([])
    setHeaders({})
  }, [])

  return (
    <WizardStep
      sidebarRender={() => (
        <WizardStepSidebar>
          <h3 style={{ margin: 0 }}>A new way to upload dropship lists</h3>
          <ul>
            <li>Import your csv</li>
            <li>Match your columns to corresponding categories</li>
            <li>Take the time to review and fix any errors</li>
            <li>When you're all done, attach the ready-to-go dropship list to your fulfillment request.</li>
          </ul>
          <p> Thank you! This is a huge help to our fulfillment processes.</p>

          <p>
            <strong style={{ color: "var(--good-color)" }}>Have questions?</strong> Checkout{" "}
            <a
              href="https://www.notion.so/brilliantmade/Dropship-Wizard-6ab772d6736740a78e9d33f5861661eb"
              target="_blank"
            >
              our Notion for how tos and tips
            </a>
          </p>

          <div className="gap"></div>

          <p>Having trouble with the wizard? Try resetting your browser database.</p>
          <br />

          <RoundedButton onClick={handleResetDatabase}>Reset Database</RoundedButton>

          <WizardNextStep stepName={nextStep} disabled={isBlank(rawData)} onClick={onNextClick} />
        </WizardStepSidebar>
      )}
    >
      <div className={style.base}>
        <h1>Welcome to our new Dropship Wizard!</h1>

        <p>This wizard will attempt to validate mailing addresses, kits, and phone numbers</p>

        <p style={{ color: "var(--bad-color)" }}>
          <strong>Dropship Wizard is in beta!</strong> Please report issues and make suggestions in&nbsp;
          <strong>#ada</strong>.
        </p>

        <div className={style.uploadContainer}>
          <h3>Upload your dropship* to get started</h3>
          <div className={style.callout}>
            <p>
              *Your dropship must include columns for <strong>Name</strong>, <strong>Address 1</strong>,&nbsp;
              <strong>Address 2</strong>, <strong>City</strong>, <strong>State</strong> and <strong>Zip Code</strong>.
            </p>
            <p>
              If you do not specify, <strong>Kit</strong> will automatically select a kit attached to your fulfillment
              request and <strong>Country</strong> will default to US.
              <br />
              <i>
                They don't need to have these exact names. For example, 'Street Address' or 'Address Line 1' works.
                You'll match headers in the next step.
              </i>
            </p>

            <p>For all possible fields, download the template!</p>

            <Download Component={RoundedButton} filename={`dropship_template.csv`} onDownload={handleDownloadTemplate}>
              Download Template
            </Download>
          </div>
          <div className={style.uploadButton}>
            <label>Select File &nbsp;&nbsp; →</label>
            <InputSheet
              name="dropshipList"
              options={{
                header: false,
                dynamicTyping: false,
                transform: (cell) => {
                  if (!cell) return

                  return tr(cell.trim())
                },
              }}
              onComplete={handleInputSheetComplete}
            />
          </div>
        </div>
      </div>
    </WizardStep>
  )
}

UploadStep.onNext = (props) => {
  const { rawData, headers, setHeaders } = props

  const newHeaders = mapValues(
    ({ pattern, options, ...restVal }, key) => ({
      ...restVal,
      pattern: pattern instanceof RegExp ? pattern : new RegExp(key.trim().replace(/[_-\s]+/, ".?"), "i"),
      columnIdx: null,
    }),
    headers
  )

  const firstRow = rawData?.[0] ?? []
  const headerEntries = Object.entries(newHeaders)

  firstRow.forEach((columnText, idx) => {
    const [key] =
      headerEntries.find(([key, { pattern, columnIdx }]) => isNil(columnIdx) && pattern.test(columnText)) ?? []

    if (newHeaders[key]) {
      newHeaders[key].columnIdx = idx
    }
  })

  setHeaders(newHeaders)
}
