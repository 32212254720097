import React, { useMemo } from "react"
import { SpreadsheetRow } from "./types"
import { ReadOnlySpreadsheet, ReadOnlySpreadsheetProps } from "./ReadOnlySpreadsheet"
import { EditableSpreadsheet, EditableSpreadsheetProps } from "./EditableSpreadsheet"
import "./Spreadsheet.scss"

interface ReadOnlyVariantProps<R extends SpreadsheetRow> extends ReadOnlySpreadsheetProps<R> {
  isReadOnly: true
}

interface EditableVariantProps<R extends SpreadsheetRow> extends EditableSpreadsheetProps<R> {
  isReadOnly?: false
}

export type SpreadsheetProps<R extends SpreadsheetRow> = ReadOnlyVariantProps<R> | EditableVariantProps<R>

export function Spreadsheet<R extends SpreadsheetRow>(props: SpreadsheetProps<R>) {
  const { columns: rawColumns, rows: rawRows, formats, errors, defaultColumnOptions: rawDefaults, ...restProps } = props
  const columns = useMemo(() => {
    return rawColumns.map(({ key: rawKey, name, ...restColumn }) => {
      const key = rawKey ?? name.toString()
      const format = formats?.[key]
      if (format?.format === "select") {
        return { name, key, options: format.options, ...restColumn }
      } else {
        return { name, key, ...restColumn }
      }
    })
  }, [rawColumns])

  const rows = useMemo(() => {
    return rawRows.map((row) => {
      const rowErrors = errors?.[row.id] ? { errors: errors[row.id] } : { errors: null }
      return Object.assign({}, row, rowErrors)
    })
  }, [rawRows, errors])

  const defaultColumnOptions = rawDefaults || { minWidth: 150 }

  if (restProps.isReadOnly) {
    return (
      <ReadOnlySpreadsheet columns={columns} rows={rows} defaultColumnOptions={defaultColumnOptions} {...restProps} />
    )
  } else {
    return (
      <EditableSpreadsheet columns={columns} rows={rows} defaultColumnOptions={defaultColumnOptions} {...restProps} />
    )
  }
}
