import React from "react"
import { twMerge } from "tailwind-merge"
import { ExclamationCircleIcon } from "@heroicons/react/20/solid"

import { Bee } from "./BeeKit"
import { QuickSend } from "../types/preferredGift"

export function QuickSendEditBaseErrorsFlash({ quickSend, className }: { quickSend: QuickSend; className?: string }) {
  return Array.from(new Set(quickSend.baseErrors)).map((err, i) => {
    return (
      <Bee.Flash kind="error" className={twMerge("mx-6 mt-4", className)} key={`base-error-${i}`}>
        <div className="flex gap-2 text-xs items-center">
          <ExclamationCircleIcon className="h-4 w-4" />
          <span dangerouslySetInnerHTML={{ __html: err }} />
        </div>
      </Bee.Flash>
    )
  })
}
