import React, { useEffect, useState } from "react"
import { editor, languages as monacoLanguages } from "monaco-editor"
import Editor from "@monaco-editor/react"
import IModelContentChangedEvent = editor.IModelContentChangedEvent
import { Theme, useSystemTheme } from "~/src/hooks/useSystemTheme"

export type CodeEditorInputProps = React.ComponentProps<typeof Editor> & {
  name?: string
  id?: string
}

const { getLanguages } = monacoLanguages
export const languages = getLanguages().map((lang) => lang.id)

const themeMap = {
  [Theme.Light]: "light",
  [Theme.Dark]: "vs-dark",
}

export function CodeEditorInput({ defaultValue, id, name, onChange, ...props }: CodeEditorInputProps) {
  const [theme, setTheme] = useState("light")
  const [value, setValue] = useState<string | undefined>(defaultValue)

  const handleChange = (value: string | undefined, ev: IModelContentChangedEvent) => {
    onChange && onChange(value, ev)
    setValue(value)
  }

  const systemTheme = useSystemTheme()

  // Dark mode setup
  useEffect(() => {
    setTheme(themeMap[systemTheme])
  }, [systemTheme])

  return (
    <>
      <input type="hidden" id={id} name={name} value={value} />
      <Editor theme={theme} defaultValue={defaultValue} onChange={handleChange} {...props} />
    </>
  )
}

CodeEditorInput.defaultProps = {
  height: "8em",
}
