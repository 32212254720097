import React from "react"
import {
  getTemplate,
  getUiOptions,
  type FieldTemplateProps,
  type FormContextType,
  type RJSFSchema,
  type StrictRJSFSchema,
} from "@rjsf/utils"

import { Field } from "~/src/components/BeeKit/Field"

export function FieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: FieldTemplateProps<T, S, F>
) {
  const { id, label, children, rawErrors, help, description, hidden, required, displayLabel, registry, uiSchema } =
    props
  const uiOptions = getUiOptions(uiSchema)
  const WrapIfAdditionalTemplate = getTemplate<"WrapIfAdditionalTemplate", T, S, F>(
    "WrapIfAdditionalTemplate",
    registry,
    uiOptions
  )

  if (hidden) {
    return <div className="hidden">{children}</div>
  } else {
    return (
      <WrapIfAdditionalTemplate {...props}>
        <Field errorMessages={rawErrors} label={displayLabel ? label : undefined} optional={!required}>
          {children}
        </Field>
      </WrapIfAdditionalTemplate>
    )
  }
}
