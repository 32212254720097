import React from "react"
import { isFn } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import style from "./WizardStep.module.scss"

export function WizardStep(props) {
  const { children, className, sidebarRender, ...restProps } = props

  return (
    <div className={twMerge([style.base, isFn(sidebarRender) && style.hasSidebar, className])}>
      {sidebarRender?.()}

      {children}
    </div>
  )
}
