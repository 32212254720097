import { Dialog, DialogPanel } from "@headlessui/react"
import * as React from "react"
import { useRef, useState } from "react"
import "./ConfirmationDialog.scss"

type ModalContent = {
  title: string
  description?: string
}

export function useConfirmation(): [
  (title: string, description?: string) => Promise<boolean>,
  () => React.ReactElement,
] {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ModalContent>({ title: "" })
  const resolveCallbackRef = useRef<((confirmed: boolean) => void) | null>(null)

  const open = (content: ModalContent) => {
    setModalContent(content)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const onConfirm = () => {
    if (resolveCallbackRef.current) {
      resolveCallbackRef.current(true)
    }
    close()
  }

  const onCancel = () => {
    if (resolveCallbackRef.current) {
      resolveCallbackRef.current(false)
    }
    close()
  }

  const confirm = (title: string, description?: string): Promise<boolean> => {
    open({ title, description })

    return new Promise((resolve) => {
      resolveCallbackRef.current = resolve
    })
  }

  const ConfirmationDialog = () => {
    return (
      <Dialog
        className="confirmation-dialog__container"
        open={isOpen}
        onClose={onCancel}
        aria-label={`confirmation: ${modalContent.title}`}
      >
        <div className="confirmation-dialog__thing">
          <DialogPanel className="confirmation-dialog">
            <div className="confirmation-dialog__header">
              <div className="confirmation-dialog__title">{modalContent.title}</div>
              <button className="confirmation-dialog__close-button" onClick={onCancel}>
                <i className="fa fa-lg fa-close" />
              </button>
            </div>
            {modalContent.description ? (
              <div className="confirmation-dialog__description">{modalContent.description}</div>
            ) : null}
            <div className="confirmation-dialog__buttons">
              <button className="confirmation-dialog__confirm-button" onClick={onConfirm}>
                Ok
              </button>
              <button className="confirmation-dialog__cancel-button" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    )
  }

  return [confirm, ConfirmationDialog]
}
