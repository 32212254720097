import { unparse } from "papaparse"
import React, { useEffect, useState } from "react"
import { Download } from "~/src/components"
import { RoundedButton } from "./RoundedButton"
import style from "./FinishStep.module.scss"
import { WizardStep } from "./WizardStep"
import { isntNil } from "~/src/lib/any"

export function FinishStep(props) {
  const { collection, currentAdminUserId, csrfToken, fulfillmentRequest, fulfillmentKits, headers } = props
  const [csvData, setCSVData] = useState(null)

  // Prepare download data
  // TODO: Run in background
  useEffect(() => {
    ;(async () => {
      const drops = await collection.toArray()
      const variantColumns = {}

      // Initialize `variant_*` columns
      for (let kit of fulfillmentKits) {
        const { fulfillment_kit_items: kitItems } = kit

        for (let kitItem of kitItems) {
          const { variant_id } = kitItem
          variantColumns[`variant_${variant_id}`] = 0
        }
      }

      // Prepare rows for CSV
      const rows = drops.map((drop) => {
        const newRow = {}

        // the fulfillment request will tell us if we can use this for batch store orders
        if (isntNil(fulfillmentRequest.batch_store_order_user_id)) {
          newRow.user_id = fulfillmentRequest.batch_store_order_user_id
        } else {
          newRow.order_id = fulfillmentRequest.order_id
        }

        // Set order id column
        newRow.fulfillment_request_id = fulfillmentRequest.id

        newRow.sequence_number = null

        // Populate variant_* columnds
        Object.assign(newRow, variantColumns)
        const kitItems = fulfillmentKits.find((k) => k.name === drop.kit)?.fulfillment_kit_items ?? []

        for (let kitItem of kitItems) {
          const { variant_id, quantity } = kitItem
          newRow[`variant_${variant_id}`] += quantity
        }

        // Populate remaining columns
        for (let [name, { prefix = "" }] of Object.entries(headers)) {
          const csvHeader = prefix + name
          newRow[csvHeader] = drop[name]
        }

        // Add columns that may be needed
        const additionalColumns = [
          "tracking_number",
          "shipping_method",
          "internal",
          "length",
          "width",
          "height",
          "weight",
          "insurance_amount",
        ]

        for (let column of additionalColumns) {
          newRow[column] = null
        }

        return newRow
      })

      setCSVData(unparse(rows))
    })()
  }, [collection, fulfillmentRequest, fulfillmentKits, headers, setCSVData])

  const handleAttachToFulfillmentRequest = async () => {
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const fD = new FormData()

    fD.set("attachment[admin_user_id]", currentAdminUserId)
    fD.set("attachment[attachable_id]", fulfillmentRequest.id)
    fD.set("attachment[attachable_type]", "FulfillmentRequest")
    fD.set("attachment[category]", "Dropship List")
    fD.set("attachment[file]", csvBlob, filenameFrom(fulfillmentRequest))
    fD.set("attachment[name]", filenameFrom(fulfillmentRequest))
    fD.set("authenticity_token", csrfToken)
    fD.set("utf8", "✓")

    await fetch("/admin/attachments", {
      method: "POST",
      body: fD,
      redirect: "manual",
    })

    // Send user back to fulfillment request
    window.location.href = `/admin/fulfillment_requests/${fulfillmentRequest.id}`
  }

  return (
    <WizardStep>
      <h1>Your dropship list is ready to download!</h1>

      <p>Stay tuned for when this wizard will create some magic shipments</p>

      {csvData ? (
        <div className={style.actionButtons}>
          <RoundedButton onClick={handleAttachToFulfillmentRequest}>Attach to Fulfillment Request</RoundedButton>

          <Download Component={RoundedButton} data={csvData} filename={filenameFrom(fulfillmentRequest)}>
            Download
          </Download>
        </div>
      ) : null}
    </WizardStep>
  )
}

function filenameFrom(fulfillmentRequest) {
  if (isntNil(fulfillmentRequest.batch_store_order_user_id)) {
    return `batch_order_fr_${fulfillmentRequest.id}_dropship.csv`
  } else {
    return `fulfillment_request_${fulfillmentRequest.id}_recipients.csv`
  }
}
