import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"
import ComponentUtil from "./component_util"
import Product from "./product"

@inject(["ideabookStore"])
@observer
class Products extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.group = props.ideabook_grouping
    this.ideabook_products = this.group.ideabook_products
    this.guid = ComponentUtil.guid()
    this.parent = props.parent
  }

  @action toggleProductMover() {
    this.parent.toggleProductMover()
  }

  componentDidMount() {
    let sortable = $(`div#${this.guid}`)
    sortable.sortable({
      opacity: 0.5,
      connectWith: "div.ideabook-products",
      scroll: true,
      over: (e, ui) => {
        // let origZone = $(ui.sender)
        // let dropZone = $(e.target)
        //
        // console.log("Orig Zone:")
        // console.log(origZone)
        // console.log("New Zone:")
        // console.log(dropZone)
      },
      receive: (e, ui) => {
        $(ui.sender).sortable("cancel")

        let ideabookProductId = parseInt(ui.item[0].id)
        let origGroupId = parseInt(ui.sender[0].dataset.ideabookGroupingId)
        let newGroupId = parseInt($(e.target)[0].dataset.ideabookGroupingId)

        let newGroup = _.find(this.props.ideabookStore.ideabook.ideabook_groupings, (g) => {
          return g.ideabook_grouping.id === newGroupId
        })

        let origGroup = _.find(this.props.ideabookStore.ideabook.ideabook_groupings, (g) => {
          return g.ideabook_grouping.id === origGroupId
        })

        let ideabookProduct = _.find(origGroup.ideabook_grouping.ideabook_products, (p) => {
          return p.ideabook_product.id === ideabookProductId
        })

        ideabookProduct.ideabook_product.ideabook_grouping_id = newGroupId
        this.props.ideabookStore.enqueueForUpdate(ideabookProduct)

        newGroup.ideabook_grouping.ideabook_products.push(ideabookProduct)
        origGroup.ideabook_grouping.ideabook_products.remove(ideabookProduct)
      },
      update: (e, ui) => {
        let sortedIds = sortable.sortable("toArray")
        let newGroupId = parseInt($(e.target)[0].dataset.ideabookGroupingId)
        let newGroup = _.find(this.props.ideabookStore.ideabook.ideabook_groupings, (g) => {
          return g.ideabook_grouping.id === newGroupId
        })
        this.props.ideabookStore.repositionProductsByIds(newGroup.ideabook_grouping.ideabook_products, sortedIds, true)
      },
    })
  }

  render() {
    if (this.ideabook_products) {
      return (
        <div
          id={this.guid}
          data-ideabook-grouping-id={this.group.id}
          className="ideabook-products ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
        >
          {this.ideabook_products.map((i_product) => (
            <Product key={i_product.ideabook_product.id} ideabook_product={i_product.ideabook_product} parent={this} />
          ))}
        </div>
      )
    } else {
      return null
    }
  }
}

export default Products
