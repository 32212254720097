import Dexie from "dexie"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { isFn, isPresent } from "~/src/lib/any"
import { mapValues } from "~/src/lib/object"

const NAMESPACE_SEPARATOR = "__"

const DexieContext = createContext()

export function DexieProvider(props) {
  const { config, children } = props
  const { database, namespace, stores, version = 1 } = config
  const [client, setClient] = useState(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const client = new Dexie(database)

    client.version(version).stores(mapValues((s) => s.join(", "), stores))

    setClient(client)
    setIsReady(true)
  }, [database, stores, namespace, version])

  const providerValue = useMemo(
    () => ({
      client,
      namespace,
    }),
    [client, namespace]
  )

  return (
    <DexieContext.Provider value={providerValue}>
      {isFn(children) ? children({ isReady }) : children}
    </DexieContext.Provider>
  )
}

export function useDexie() {
  return useContext(DexieContext)
}

export function useTable(tableName) {
  const { client, namespace = "" } = useDexie()
  const table = client.table(tableName)
  const collection = namespace.length > 1 ? table.where("namespace").equals(namespace) : table

  return { collection, namespace, table }
}

function applyNamespace(namespace, tableName) {
  return [namespace, tableName].filter(isPresent).join(NAMESPACE_SEPARATOR)
}
