import React from "react"
import { getSubmitButtonOptions, SubmitButtonProps } from "@rjsf/utils"

import { Button } from "~/src/components/BeeKit/Button"

export function SubmitButtonTemplate({ uiSchema }: SubmitButtonProps) {
  const { norender, props, submitText } = getSubmitButtonOptions(uiSchema)
  if (norender) {
    return null
  }

  return (
    <Button type="submit" {...props}>
      {submitText}
    </Button>
  )
}
