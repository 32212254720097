import React, { forwardRef } from "react"
import { PathBuilder } from "~/src/lib/iname"

export type HiddenInputProps = { name?: PathBuilder | string } & Omit<JSX.IntrinsicElements["input"], "name">

/**
 * A Beekit hidden input with support for a `PathBuilder` name & fowards refs.
 */
export const HiddenInput = forwardRef<HTMLInputElement, HiddenInputProps>(function Input(props, ref) {
  const { name, ...restProps } = props

  return <input type="hidden" name={name?.toString()} ref={ref} {...restProps} />
})
