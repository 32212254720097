import React, { useRef } from "react"
import { isFn } from "~/src/lib/any"

// TODO: Document
// TODO: Support URL as a downloadable source
export function Download(props) {
  const { children, Component = "button", data = "", filename, onClick, onDownload, ...restProps } = props
  const anchorRef = useRef(null)

  const handleButtonClick = async (e) => {
    if (isFn(onDownload)) {
      const lazyData = await onDownload()
      anchorRef.current.href = encodeHref(lazyData)
    }

    anchorRef.current.click()
    onClick?.(e)
  }

  return (
    <>
      <Component onClick={handleButtonClick} {...restProps}>
        {children}
      </Component>
      <a
        ref={anchorRef}
        href={isFn(onDownload) ? null : encodeHref(data)}
        download={filename}
        style={{ display: "none" }}
      />
    </>
  )
}

function encodeHref(data) {
  return "data:text/plain;charset=utf-8," + encodeURIComponent(data)
}
