import React from "react"
import { twMerge } from "tailwind-merge"
import { Icon } from "~/src/components/Icon"
import { SelectableType } from "~/src/types/productSelection"

export type StockIndicatorProps = {
  quantity: number
  required?: boolean
  type?: SelectableType
  className?: string
}

/**
 * Renders a standard BeeKit stock indicator.
 * @param props
 * @param props.quantity quantity of items in stock
 * @param props.required whether or not the requires us to hold stock
 * @param props.type type of item, either StoreKit or StoreProduct
 * @param props.className classes passed through to top-level div.
 * @returns
 */
export function StockIndicator(props: StockIndicatorProps) {
  const { quantity: count, className, required = true, type = SelectableType.StoreProduct } = props

  const text = () => {
    switch (true) {
      case type === SelectableType.StoreKit: {
        return `${count} available`
      }
      case !required: {
        return "In stock"
      }
      default: {
        return `${count} in stock`
      }
    }
  }

  const dotColor = () => {
    switch (true) {
      case count >= 20 || !required:
        return "text-green-500"
      case count > 0:
        return "text-amber-500"
      default:
        return "text-red-600"
    }
  }

  return (
    <div className={twMerge("flex gap-2 items-center", className)}>
      <Icon.Dot className={dotColor()} />
      <span className="text-sm whitespace-nowrap">{text()}</span>
    </div>
  )
}
