import React from "react"
import { intersperse } from "~/src/lib/array"
import { Format } from "~/src/lib/format"

export type MoneyRangeProps = {
  amounts: number[]
  formatter?: (amount: number) => string
} & JSX.IntrinsicElements["span"]

export function MoneyRange(props: MoneyRangeProps) {
  const { amounts = [0, 0], formatter = Format.usd, ...restProps } = props

  return (
    <span {...restProps}>
      {intersperse(
        (_, i) => (
          <span key={"sep_" + i}> • </span>
        ),
        amounts.map((p, i) => <span key={"amount_" + i}>{formatter(p)}</span>)
      )}
    </span>
  )
}
