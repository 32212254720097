import { useCallback, useEffect, useState } from "react"

/**
 * Hook that abstracts usage of `hashchange` event.
 */
export function useHashChange() {
  const [rawHash, setRawHash] = useState(window?.location?.hash)

  const handleHashChange = useCallback(() => {
    setRawHash(window?.location?.hash)
  }, [setRawHash])

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange, false)

    return () => window.removeEventListener("hashchange", handleHashChange)
  }, [setRawHash])

  const hash = rawHash[0] === "#" ? rawHash.slice(1) : rawHash

  return {
    hash,
    match(regex: RegExp) {
      if (typeof regex === "string") {
        return new RegExp(`^${regex}$`).test(hash) || null
      }

      return regex.test(hash) || null
    },
  }
}
