import React from "react"
import Papa from "papaparse"
import { isFn, isPresent } from "~/src/lib/any"
import { firstValue } from "~/src/lib/object"
import { read, utils } from "xlsx"

/**
 * An file input component that accepts CSV and parses it with **papaparse**
 * @param {*} props
 */
export function InputSheet(props) {
  const { type: _type, onComplete, options = {}, ...restProps } = props

  const handleChange = async (e) => {
    const [file] = e?.currentTarget?.files ?? []

    let data = file
    if (file?.type?.endsWith?.("spreadsheetml.sheet")) {
      data = await csvFrom(file)
    }

    Papa.parse(data, {
      ...options,
      complete(result) {
        onComplete?.(result)
      },
    })
  }

  return (
    <input
      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      type="file"
      onChange={handleChange}
      {...restProps}
    />
  )
}

async function csvFrom(xslx) {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const workbook = read(event.target.result, {
        type: isPresent(reader.readAsBinaryString) ? "binary" : "array",
      })
      const sheet = firstValue(workbook.Sheets)
      resolve(utils.sheet_to_csv(sheet))
    }

    isPresent(reader.readAsBinaryString) ? reader.readAsBinaryString(xslx) : reader.readAsArrayBuffer(xslx)
  })
}
