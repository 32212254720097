import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import DataGrid, { SelectColumn, TextEditor } from "react-data-grid"
import { EditorProps, FillEvent, Position } from "react-data-grid/lib/types"
import { create } from "zustand"
import { isArray, isFn, isNil, isString, isntNil } from "~/src/lib/any"
import { firstKey, firstValue } from "~/src/lib/object"
import { ActionToolbar } from "./ActionToolbar"
import { SelectEditor } from "./SelectEditor"
import { ValuePresenter } from "./ValuePresenter"
import { keyComboFrom } from "./keys"
import {
  BaseSpreadsheetProps,
  SpreadsheetActionBus,
  SpreadsheetColumn,
  SpreadsheetColumnOptions,
  SpreadsheetRow,
} from "./types"

export interface EditableSpreadsheetProps<R extends SpreadsheetRow> extends BaseSpreadsheetProps<R> {
  onRowsChange: (rows: R[]) => void
  onRowsCreate: (row: R, opts: { direction?: "up" | "down" }) => void
  onRowsDelete: (deletedRows: R[]) => void
}

const useStore = create<SpreadsheetActionBus>((set) => ({
  actions: [],
  push(action: string) {
    return set((s) => ({ ...s, actions: s.actions.concat(action).slice(-5) }))
  },
}))

function useSpreadsheetActions() {
  const push = useStore((s) => s.push)

  return {
    actionCreateRow(e) {
      e.currentTarget.blur()
      push("createRow")
    },
    actionDeleteRows(e) {
      e.currentTarget.blur()
      push("deleteRows")
    },
  }
}

export function EditableSpreadsheet<R extends SpreadsheetRow>(props: EditableSpreadsheetProps<R>) {
  const {
    rows,
    columns: rawColumns,
    onRowsChange,
    onRowsCreate,
    onRowsDelete,
    onDownload,
    defaultColumnOptions,
    ...restProps
  } = props
  const rowKeyGetter = props.rowKeyGetter ?? ((r) => r.id)
  const columnEditor = (column_key: string, options?: SpreadsheetColumnOptions<R>) => {
    if (isNil(options)) {
      return TextEditor
    } else if (isArray(options)) {
      const editorForArrayOpts = (props: EditorProps<R>) => {
        const { onRowChange, row, editorPortalTarget, rowHeight } = props
        if (editorPortalTarget instanceof HTMLElement) {
          return (
            <SelectEditor
              value={row[column_key]}
              onChange={(value: string) => onRowChange({ ...row, [column_key]: value }, true)}
              options={options}
              rowHeight={rowHeight}
              menuPortalTarget={editorPortalTarget}
            />
          )
        } else {
          return <TextEditor {...props} />
        }
      }

      return editorForArrayOpts
    } else if (isFn(options)) {
      const editorForFnOpts = (props: EditorProps<R>) => {
        const { onRowChange, row, editorPortalTarget, rowHeight } = props
        const appliedOptions = options(row)
        if (isNil(appliedOptions) || !(editorPortalTarget instanceof HTMLElement)) {
          return <TextEditor {...props} />
        }

        return (
          <SelectEditor
            value={row[column_key]}
            onChange={(value: string) => onRowChange({ ...row, [column_key]: value }, true)}
            options={appliedOptions}
            rowHeight={rowHeight}
            menuPortalTarget={editorPortalTarget}
          />
        )
      }
      return editorForFnOpts
    }
  }

  const columns = useMemo(() => {
    const columns = rawColumns.map(({ key, name, options, ...restColumn }) => {
      const column: SpreadsheetColumn<R> = {
        name,
        key,
        editor: columnEditor(key, options),
        cellClass(row) {
          if (row?.errors?.[key]) return "rdg-cell-has-error"
        },
        formatter: ValuePresenter,
        ...restColumn,
      }

      return column
    })

    columns.unshift(SelectColumn)
    return columns
  }, [rawColumns])

  // Stateful spreadsheet data
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<React.Key>>(() => new Set())
  const { actionCreateRow, actionDeleteRows } = useSpreadsheetActions()
  const lastChangedRows = useRef<R[]>([])
  const selectedCell = useRef<Position | undefined>()

  // Adds support for drag and fill "handle"
  const handleFill = ({ columnKey, sourceRow, targetRows }: FillEvent<R>): R[] => {
    const changedRows = targetRows.map((row) => ({ ...row, [columnKey]: sourceRow[columnKey] }))
    lastChangedRows.current = changedRows

    return changedRows
  }

  const handleRowsDelete = useCallback(() => {
    if (
      selectedRowKeys.size == 0 ||
      (selectedRowKeys.size > 1 && !confirm(`Do you want to delete ${selectedRowKeys.size} selected rows?`))
    ) {
      return
    }

    const deletedRowKeys = new Set(selectedRowKeys)
    const deletedRows: R[] = []
    rows.forEach((row) => {
      const rowKey = rowKeyGetter(row)

      if (deletedRowKeys.delete(rowKey)) {
        deletedRows.push(row)
      }
    })
    setSelectedRowKeys(deletedRowKeys)

    if (deletedRows.length > 0) {
      onRowsDelete?.(deletedRows)
    }
  }, [rows, rowKeyGetter, selectedRowKeys, setSelectedRowKeys, onRowsDelete])

  const handleRowsCreate = useCallback(() => {
    const rowIdx = selectedCell.current?.rowIdx
    onRowsCreate?.(rows[rowIdx ?? 0], { direction: isNil(rowIdx) ? "up" : "down" })
  }, [onRowsCreate, selectedCell, rows])

  const handleRowsChange = useCallback(
    (newRows: R[]) => {
      let changedRows =
        lastChangedRows.current?.length > 0
          ? [...lastChangedRows.current]
          : isntNil(selectedCell.current)
            ? [newRows[selectedCell.current.rowIdx]]
            : []

      let rowDiff: Record<string, R> | undefined
      if (changedRows.length === 1 && isntNil(selectedCell.current)) {
        const [changedRow] = changedRows
        const column = columns[selectedCell.current.idx]

        if (isString(column.key)) {
          rowDiff = { [column.key]: changedRow[column.key] }
        } else {
          throw new TypeError(`Column key must be a string instead got ${typeof column.key}`)
        }
      }

      if (isntNil(rowDiff) && selectedRowKeys.size > 1) {
        const [changedRow] = changedRows

        if (selectedRowKeys.has(rowKeyGetter(changedRow))) {
          if (
            confirm(
              `Do you want to set "${firstKey(rowDiff)?.toString()}" to "${firstValue(rowDiff)}" for ${
                selectedRowKeys.size
              } selected rows?`
            )
          ) {
            const changedRowKeys = new Set(selectedRowKeys)
            changedRows = []

            newRows.forEach((row) => {
              if (changedRowKeys.delete(rowKeyGetter(row))) changedRows.push({ ...row, ...rowDiff })
            })

            setSelectedRowKeys(changedRowKeys)
          }
        }
      }

      onRowsChange?.(changedRows)
      lastChangedRows.current = []
    },
    [setSelectedRowKeys, selectedRowKeys, rowKeyGetter, onRowsChange]
  )

  const handleKeyDown = (e: KeyboardEvent) => {
    const rowIdx = isNil(selectedCell.current) ? null : selectedCell.current.rowIdx
    const keyCombo = keyComboFrom(e)

    switch (keyCombo) {
      // Delete current row
      case "ctrl+backspace": {
        handleRowsDelete()

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Create new row below
      case "ctrl+enter": {
        handleRowsCreate()

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Select all
      case "ctrl+a": {
        setSelectedRowKeys(new Set(rows.map(rowKeyGetter)))

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Clear selection
      case "ctrl+d": {
        setSelectedRowKeys(new Set())

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Add current row and one above to selection
      case "shift+arrowup": {
        if (isNil(rowIdx)) return

        const newSelectedRowKeys = new Set(selectedRowKeys)
        newSelectedRowKeys.add(rowKeyGetter(rows[rowIdx]))

        const nextRow = rows[rowIdx - 1]
        if (nextRow) {
          newSelectedRowKeys.add(rowKeyGetter(nextRow))
        }

        setSelectedRowKeys(newSelectedRowKeys)
        return
      }
      // Add current row and one below to selection
      case "shift+arrowdown": {
        if (isNil(rowIdx)) return

        const newSelectedRowKeys = new Set(selectedRowKeys)
        newSelectedRowKeys.add(rowKeyGetter(rows[rowIdx]))

        const nextRow = rows[rowIdx + 1]
        if (nextRow) {
          newSelectedRowKeys.add(rowKeyGetter(nextRow))
        }

        setSelectedRowKeys(newSelectedRowKeys)
        return
      }
    }
  }

  useEffect(() => {
    const destroySub = useStore.subscribe((state: SpreadsheetActionBus) => {
      switch (state.actions.at(-1)) {
        case "createRow": {
          handleRowsCreate()
          return
        }
        case "deleteRows": {
          handleRowsDelete()
          return
        }
      }
    })

    return () => {
      destroySub()
    }
  }, [handleRowsCreate, handleRowsDelete])

  const actions = useMemo(() => {
    const editActions = [
      { icon: "Plus", tooltip: "Add row below active row", onUse: actionCreateRow },
      {
        icon: "Trash",
        tooltip: "Delete all selected rows",
        className: selectedRowKeys.size > 0 ? "" : "disabled",
        onUse: actionDeleteRows,
      },
    ]

    return isNil(onDownload)
      ? editActions
      : editActions.concat([{ icon: "Download", tooltip: "Download working spreadsheet", onUse: onDownload }])
  }, [selectedRowKeys, actionCreateRow, actionDeleteRows, onDownload])

  return (
    <div className="spreadsheet" onKeyDownCapture={handleKeyDown}>
      {rows.length > 0 ? (
        <>
          <ActionToolbar actions={actions} />
          <DataGrid
            columns={columns}
            onRowsChange={handleRowsChange}
            onSelectedRowsChange={setSelectedRowKeys}
            onFill={handleFill}
            onSelectedCellChange={(pos) => (selectedCell.current = pos)}
            defaultColumnOptions={{
              resizable: true,
              ...defaultColumnOptions,
            }}
            rows={rows}
            selectedRows={selectedRowKeys}
            rowKeyGetter={rowKeyGetter}
            className="rdg-light"
            {...restProps}
          />
        </>
      ) : null}
    </div>
  )
}
