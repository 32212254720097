import React from "react"
import { Button } from "~/src/components/BeeKit/Button"

import type { ArrayFieldTemplateProps } from "@rjsf/utils"

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  return (
    <div>
      {props.items.map((element) => (
        <div key={element.index}>{element.children}</div>
      ))}
      <Button kind="secondary" type="button" onClick={props.onAddClick}>
        Add
      </Button>
    </div>
  )
}
