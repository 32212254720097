import React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"

@inject(["ideabookStore"])
@observer
class AdditionalActions extends React.Component {
  constructor(props, _railsContext) {
    super(props)
  }

  @action touchIdeabook(elem) {
    $(elem).find("i.refresh-status").remove()
    this.props.ideabookStore.touchIdeabook(
      (success) => {
        $(elem).append("<i class='refresh-status blink'>...re-indexing...will reload in 30 seconds....</i>")
        setTimeout(() => {
          window.location.reload()
        }, 30 * 1000)
      },
      (error) => {
        $(elem).append("<i class='refresh-status'>...failed to re-index. ${error || ''}</i>")
      }
    )
  }

  @action touchIdeabookProducts(elem) {
    $(elem).find("i.refresh-status").remove()
    this.props.ideabookStore.touchIdeabookProducts(
      (success) => {
        $(elem).append("<i class='refresh-status'>...refreshed.</i>")
        setTimeout(() => {
          $(elem).find("i.refresh-status").remove()
        }, 5 * 1000)
      },
      (error) => {
        $(elem).append("<i class='refresh-status'>...failed to refresh. ${error || ''}</i>")
      }
    )
  }

  @action toggleDebugMode(elem) {
    this.props.ideabookStore.debugMode.set(!this.props.ideabookStore.debugMode.get())
  }

  render() {
    return (
      <div id="additional_actions_box" className="ui-widget-content u-cf">
        <h4>Additional Actions</h4>
        <ul>
          <li className="u-cf" onClick={(e) => this.touchIdeabookProducts(e.target)}>
            Refresh customer ideabook
          </li>
          <li className="u-cf" onClick={(e) => this.toggleDebugMode(e.target)}>
            {this.props.ideabookStore.debugMode.get() ? "Turn OFF debug mode" : "Turn ON debug mode"}
          </li>
        </ul>
      </div>
    )
  }
}

export default AdditionalActions
