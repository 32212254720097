import React from "react"
import { humanize } from "underscore.string"
import { WizardIndex, WizardStep } from "~/src/hooks"
import { twMerge } from "tailwind-merge"
import { Button } from "."
import style from "./WizardCrumbs2.module.scss"

export type WizardCrumbs2Props<S extends WizardStep & WizardIndex> = {
  currentStep?: S
  onClick?: (step: WizardStep & WizardIndex) => void
  steps: WizardStep[]
} & Omit<JSX.IntrinsicElements["div"], "onClick">

export function WizardCrumbs2<S extends WizardStep & WizardIndex>(props: WizardCrumbs2Props<S>) {
  const { className, steps, currentStep = { ...steps[0], index: 0 }, onClick, ...restProps } = props

  return (
    <div className={twMerge([style.base, className])} {...restProps}>
      {steps.map((step, index) => {
        const isPast = index < currentStep.index
        const isCurrent = index === currentStep.index
        const isFuture = index > currentStep.index

        return (
          <Button
            key={step.id}
            className={twMerge([
              style.stepButton,
              isPast && style.isPast,
              isCurrent && style.isCurrent,
              isFuture && style.isFuture,
            ])}
            onClick={() => onClick?.({ ...step, index })}
          >
            {step.name ?? humanize(step.id)}
          </Button>
        )
      })}
    </div>
  )
}
