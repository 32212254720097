import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"
import Products from "./products"

@inject(["ideabookStore"])
@observer
class Group extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.group = props.ideabook_grouping
    this.parent = props.parent
    this.state = { all_selected: false }
  }

  @action toggleEdit(elem, enqueue = false) {
    let button = $(elem)
    let parent = button.parent()
    let parentSibling = $(parent.siblings("div")[0])
    parent.hide()
    parentSibling.show()
    if (enqueue) {
      this.props.ideabookStore.enqueueForUpdate({ ideabook_grouping: this.group })
    }
  }

  @action reactToEnter(e) {
    if (e.keyCode === 13) {
      this.toggleEdit(e.target, true)
    }
  }

  @action togglePanel(e) {
    let icon = $(e.target)
    let header = icon.parent()
    let panel = header.next(".ui-accordion-content")
    let isOpen = panel.is(":visible")

    if (isOpen) {
      icon.removeClass("ui-icon-triangle-1-s").addClass("ui-icon-triangle-1-e")
      panel.slideUp()
    } else {
      icon.removeClass("ui-icon-triangle-1-e").addClass("ui-icon-triangle-1-s")
      panel.slideDown()
    }
  }

  @action updateField(field, value, enqueue = false) {
    this.group[field] = value
    if (enqueue) {
      this.props.ideabookStore.enqueueForUpdate({ ideabook_grouping: this.group })
    }
  }

  @action deleteGroup(group) {
    $("input").blur() // We need to keep the scroll from jumping after closing the dialog.
    let self = this
    let dialogBox = $(`#group_delete_dialog_${group.id}`)
    dialogBox.dialog({
      modal: true,
      dialogClass: "no-close",
      minWidth: 400,
      maxWidth: 725,
      maxHeight: 500,
      buttons: [
        {
          text: "CANCEL",
          click: function () {
            dialogBox.dialog("close")
          },
        },
        {
          text: "DELETE",
          click: function () {
            self.parent.deleteGroup(
              group,
              (success) => {
                dialogBox.dialog("close")
              },
              (error) => {
                dialogBox.html("Uh Oh. Something went wrong: " + error)
              }
            )
          },
        },
      ],
    })
    return false
  }

  @action toggleHidden() {
    this.group.hidden = !this.group.hidden
    this.props.ideabookStore.enqueueForUpdate({ ideabook_grouping: this.group })
  }

  @action toggleProductMover() {
    this.parent.toggleProductMover()
  }

  @action selectAll() {
    let newState = !this.state.all_selected
    this.setState({ all_selected: newState })
    _.each(this.group.ideabook_products, (i_product) => {
      i_product.ideabook_product.product.selected = newState
    })
    this.toggleProductMover()
  }

  render() {
    let debugText = ""
    if (this.props.ideabookStore.debugMode.get()) {
      debugText = `(!!DEBUGGER!! position: ${this.group.position}, id: ${this.group.id})`
    }

    const productCount = this.group?.ideabook_products?.length ?? 0

    return (
      <div id={this.group.id} className="ideabook-grouping">
        <h3 className="accordion-header ui-accordion-header ui-helper-reset ui-state-default ui-accordion-icons ui-corner-all">
          <span
            onClick={(e) => this.togglePanel(e)}
            className="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"
          ></span>
          <div className="name-label">
            <span className="text">
              {this.group.name || "No name"} {this.group.hidden ? " (hidden)" : ""}
              {debugText}
            </span>
            <span
              className="ui-icon ui-icon-pencil"
              onClick={(e) => this.toggleEdit(e.target)}
              title="Change Title"
            ></span>
          </div>
          <div className="name-input" style={{ display: "none" }}>
            <input
              id="name"
              name="name"
              type="text"
              value={this.group.name || ""}
              onChange={(e) => this.updateField(e.target.name, e.target.value)}
              onKeyDown={(e) => this.reactToEnter(e)}
            />
            <span
              className="ui-icon ui-icon-check"
              onClick={(e) => this.toggleEdit(e.target, true)}
              title="Save Changes"
            ></span>
          </div>

          <div className="product-count">
            {productCount} <span>{productCount === 1 ? "product" : "products"}</span>
          </div>

          <div className="number-columns">
            <select
              id="columns"
              name="columns"
              type="number"
              onChange={(e) => this.updateField(e.target.name, e.target.value, true)}
              value={this.group.columns || ""}
            >
              {["", 2, 3, 4].map(function (v, i) {
                return (
                  <option key={i} value={v}>
                    {v}
                  </option>
                )
              })}
            </select>
            &nbsp;
            <span>columns</span>
          </div>

          <div className="right-menu">
            <i className="fa fa-asterisk" onClick={() => this.selectAll()} title="Select All"></i>
            <i className="fa fa-trash" onClick={() => this.deleteGroup(this.group)} title="Delete Group"></i>
            <i
              className={this.group.hidden ? "fa fa-eye-slash" : "fa fa-eye"}
              onClick={() => this.toggleHidden()}
              title="Mark as Hidden/Shown"
            ></i>
          </div>
        </h3>

        <Products ideabook_grouping={this.group} parent={this} />
        <div id={`group_delete_dialog_${this.group.id}`} style={{ display: "none" }}>
          You are about to delete this group from the lookbook. Are you sure you want to proceed?
        </div>
      </div>
    )
  }
}

export default Group
