import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"
import Dropzone from "react-dropzone"
import { readFileURL } from "~/src/lib/file"

@inject(["ideabookStore"])
@observer
class ImageUpload extends React.Component {
  state = {
    fileUrl: null,
  }

  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ideabook = props.ideabookStore.ideabook
    this.field = props.field
    this.urlField = props.urlField
    this.label = props.label || _.capitalize(_.upperCase(this.field))
  }

  @action uploadImage(field, file, successCallback, errorCallback) {
    this.props.ideabookStore.uploadFile(
      field,
      file,
      (resp) => {
        successCallback(resp)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  @action onDrop(acceptedFiles) {
    const [file] = acceptedFiles

    readFileURL(file).then((fileUrl) => {
      this.setState({ fileUrl })
    })

    this.uploadImage(
      this.field,
      acceptedFiles[0],
      (success) => {
        console.log(success)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  render() {
    return (
      <div className="input text u-cf">
        <div className="cols" style={{ width: "120px" }}>
          <label style={{ display: "inline-block", float: "left", width: "100%", padding: "0px" }}>{this.label}</label>
        </div>
        <div style={{ float: "left", marginLeft: "10px" }}>
          <Dropzone
            multiple={false}
            ref={(node) => {
              this.dropzone = node
            }}
            onDrop={this.onDrop.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                Click or Drag an image
              </div>
            )}
          </Dropzone>
        </div>
        {this.state.fileUrl ? (
          <div style={{ float: "right", marginTop: "10px" }}>
            <div>
              <img width="80%" src={this.state.fileUrl} />
            </div>
          </div>
        ) : null}
        {this.props.ideabookStore.ideabook[this.urlField] ? (
          <div>
            <div style={{ float: "left", width: "100%" }}>
              <a href={this.props.ideabookStore.ideabook[this.urlField]} target="_blank">
                See current image
              </a>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default ImageUpload
