import * as React from "react"
import { isString } from "../lib/any"

export const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = React.useState<string>("")

  React.useEffect(() => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")
    if (!isString(csrfToken)) {
      throw new Error(`meta[name="csrf-token"] could not be found!`)
    } else {
      setCsrfToken(csrfToken)
    }
  }, [])

  return csrfToken
}
