import React from "react"
import { twMerge } from "tailwind-merge"
import cornerTriangleStyle from "./CornerTriangle.module.scss"

export interface CornerTriangleProps {
  color: string
  size: number
}

export function CornerTriangle(props: CornerTriangleProps) {
  const { color = "black", size = 20, ...restProps } = props

  return (
    <div
      className={twMerge(["corner-triangle", cornerTriangleStyle.base])}
      style={{
        // https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
        ["--triangle-size" as any]: size + "px",
        ["--triangle-color" as any]: color,
      }}
      {...restProps}
    >
      <div></div>
    </div>
  )
}
