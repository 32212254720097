import React, { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"
import { flashClassName } from "./sharedClassNames"

export type FlashProps = {
  kind?: "success" | "error"
  className?: string
}

/**
 * Standard flash message component.
 */
export function Flash(props: PropsWithChildren<FlashProps>) {
  const { className, kind = "success", children } = props
  return <div className={twMerge(flashClassName(kind), className)}>{children}</div>
}
