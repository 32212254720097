import React, { forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import { FieldError } from "."
import style from "./Input.module.scss"

export interface InputError {
  message?: string
}

export type InputProps = { error?: InputError | boolean } & JSX.IntrinsicElements["input"]

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { className, error, ...restProps } = props

  let errorMessage: string | undefined

  if (typeof error != "boolean") {
    errorMessage = error?.message
  }

  return (
    <div className={twMerge([style.base, error && style.hasError])}>
      <input className={twMerge([className])} ref={ref} {...restProps} />
      {errorMessage && <FieldError className={style.fieldError} message={errorMessage} />}
    </div>
  )
})
