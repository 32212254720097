import * as React from "react"
import { HTMLProps } from "react"
import { twMerge } from "tailwind-merge"

type AdaRadioProps = Omit<HTMLProps<HTMLInputElement>, "type">
export function AdaRadio({ className, disabled, ...props }: AdaRadioProps) {
  const radioClass = twMerge(disabled ? "cursor-not-allowed text-gray-500" : "", className)

  return <input {...props} className={radioClass} disabled={disabled} type="radio" />
}
