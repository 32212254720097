import React, { forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import styles from "./Button.module.scss"

export type ButtonProps = { error?: boolean } & JSX.IntrinsicElements["button"]

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props: ButtonProps, ref) {
  const { className, error, type = "button", style, ...restProps } = props

  return (
    <div className={twMerge([styles.base, error && styles.hasError, className])} style={style}>
      <button type={type} ref={ref} {...restProps} />
    </div>
  )
})
