import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { isNil } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import style from "./StorefrontDropdown.module.scss"

export type StorefrontDropdownOption = { label: string; value?: string }

export type StorefrontDropdownProps = {
  defaultValue?: StorefrontDropdownOption["value"]
  name?: string
  onOptionSelect?: (option: StorefrontDropdownOption) => void
  options: StorefrontDropdownOption[]
  placeholder?: string
}

export type StorefrontDropdownRef = {
  clear: () => void
}

export const StorefrontDropdown = forwardRef<StorefrontDropdownRef, StorefrontDropdownProps>(
  function StorefrontDropdown(props, ref) {
    const { name, placeholder, options, defaultValue, onOptionSelect } = props
    const [hidden, setHidden] = useState(true)
    const [option, setOption] = useState<StorefrontDropdownOption | undefined>(
      isNil(defaultValue) ? undefined : options.find((o) => o.value === defaultValue)
    )
    const baseRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        clear() {
          setOption(undefined)
        },
      }),
      [setOption]
    )

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const { target } = event
        if (target instanceof Node && !baseRef?.current?.contains(target)) setHidden(true)
      }

      document.addEventListener("click", handleClickOutside)

      return () => document.removeEventListener("click", handleClickOutside)
    }, [setHidden])

    const handleOptionClick = (option: StorefrontDropdownOption) => () => {
      setOption(option)
      onOptionSelect?.(option)
      setHidden(true)
    }

    return (
      <div className={twMerge([style.base])} ref={baseRef}>
        <div className={style.selectedOption} onClick={() => setHidden(false)}>
          {option?.label ?? placeholder}
        </div>

        {typeof name === "string" ? <input type="hidden" name={name} value={option?.value ?? ""} /> : undefined}

        <ul className={style.list} hidden={hidden}>
          <>
            {options.map((option) => (
              <li key={option.label} className={style.item} onClick={handleOptionClick(option)}>
                {option.label}
              </li>
            ))}
          </>
        </ul>
      </div>
    )
  }
)
