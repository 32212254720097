import axios from "axios"

document.addEventListener("DOMContentLoaded", (event) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content")
})

export default class IdeabookService {
  static Fetch(id, callback, errCallback) {
    let cacheBuster = new Date().getTime()
    axios
      .get(`/admin/lookbooks/${id}/api_lookbook.json?cacheBuster=${cacheBuster}`)
      .then((resp) => callback(resp.data))
      .catch((error) => errCallback(error))
  }

  static SearchByNameOrNumber(searchTerm, callback, errCallback) {
    let cacheBuster = new Date().getTime()
    axios
      .get(`/admin/lookbooks/api_lookbook_search.json?q=${searchTerm}&cacheBuster=${cacheBuster}`)
      .then((resp) => callback(resp.data))
      .catch((error) => errCallback(error))
  }

  static AvailableTags(callback, errCallback) {
    return axios
      .get(`/admin/lookbooks/api_available_tags.json`)
      .then((resp) => {
        if (typeof callback === "function") {
          callback(resp.data)
        }

        return resp.data
      })
      .catch((error) => {
        if (typeof errCallback === "function") {
          errCallback(error)
        }

        return Promise.reject(error)
      })
  }

  static AddToIdeabook(id, ideabookProductIds, callback, errCallback) {
    axios
      .post(`/admin/lookbooks/${id}/api_add_products.json`, {
        ideabookProductIds: ideabookProductIds,
      })
      .then((resp) => {
        callback(resp)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static UploadFile(formData, callback, errCallback) {
    let id = formData.get("ideabook[id]")

    axios
      .post(`/admin/lookbooks/${id}/api_file_upload`, formData)
      .then((resp) => {
        callback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static UploadProductImage(formData, callback, errCallback) {
    let id = formData.get("ideabook[id]")

    axios
      .post(`/admin/lookbooks/${id}/api_product_image_upload`, formData)
      .then((resp) => {
        callback(resp.data)
      })
      .catch((error) => {
        console.log("Inside catch of UploadProductImage")
        console.log(error.response)
        errCallback(error)
      })
  }

  static UploadIdeabookZip(formData, callback, errCallback) {
    let id = formData.get("ideabook[id]")

    axios
      .post(`/admin/lookbooks/${id}/api_lookbook_zipfile_upload`, formData)
      .then((resp) => {
        callback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static TouchIdeabook(ideabook, callback, errCallback) {
    let id = ideabook.id

    axios
      .post(`/admin/lookbooks/${id}/api_touch_lookbook`)
      .then((resp) => {
        callback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static TouchIdeabookProducts(ideabook, callback, errCallback) {
    let id = ideabook.id

    axios
      .post(`/admin/lookbooks/${id}/api_touch_lookbook_products`)
      .then((resp) => {
        callback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static RepositionProducts(ideabook, positions, successCallback, errCallback) {
    let id = ideabook.id

    axios
      .post(`/admin/lookbooks/${id}/api_reposition_products`, {
        positions: positions,
      })
      .then((resp) => {
        successCallback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static RepositionGroups(ideabook, positions, successCallback, errCallback) {
    let id = ideabook.id

    axios
      .post(`/admin/lookbooks/${id}/api_reposition_groups`, {
        positions: positions,
      })
      .then((resp) => {
        successCallback(resp.data)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static UpdateModel(className, id, attrs, callback, errCallback) {
    console.log("updating model")
    console.log(`Classname ${className}`)
    console.log(`id ${id}`)
    console.log(attrs)
    axios
      .post("/admin/lookbooks/api_model_update", {
        class_name: className,
        id: id,
        attributes: attrs,
      })
      .then((resp) => {
        console.log(resp)
        callback(resp.data)
      })
      .catch((error) => {
        console.log(error)
        errCallback(error)
      })
  }

  static DeleteModel(className, id, ideabook_id, callback, errCallback) {
    axios
      .request({
        url: `/admin/lookbooks/${ideabook_id}/api_model_delete`,
        method: "delete",
        params: {
          class_name: className,
          class_id: id,
        },
      })
      .then((resp) => {
        callback(resp)
      })
      .catch((error) => {
        errCallback(error)
      })
  }

  static DeleteExternalUser(contact_id, ideabook_id, onSuccess, onError) {
    axios
      .request({
        url: `/admin/lookbooks/${ideabook_id}/api_delete_external_user`,
        method: "delete",
        params: {
          contact_id,
        },
      })
      .then((resp) => {
        onSuccess(resp)
      })
      .catch((error) => {
        onError(error)
      })
  }

  static CreateIdeabook(name, duplication_source_id, products, successCallback, errorCallback) {
    axios
      .request({
        url: "/admin/lookbooks/api_lookbook_create.json",
        method: "post",
        data: {
          name: name,
          duplication_source_id: duplication_source_id,
          product_ids: products.map((p) => {
            return p.ideabook_product.product.id
          }),
        },
      })
      .then((resp) => {
        successCallback(resp)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }

  static ZipTrackers(ideabook_id, successCallback, errorCallback) {
    let cacheBuster = new Date().getTime()
    axios
      .request({
        url: `/admin/lookbooks/${ideabook_id}/api_thread_trackers.json?cacheBuster=${cacheBuster}`,
        method: "get",
      })
      .then((resp) => {
        successCallback(resp)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }

  static FetchPhotos(productId, companyId, successCallback, errorCallback) {
    let cacheBuster = new Date().getTime()
    let url = `/admin/lookbooks/api_lookbook_photos.json?product_id=${productId}&cacheBuster=${cacheBuster}`
    if (companyId) {
      url += `&company_id=${companyId}`
    }
    axios
      .request({
        url: url,
        method: "get",
      })
      .then((resp) => {
        successCallback(resp)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }

  static FetchCompaniesAndUsers(successCallback, errorCallback) {
    let cacheBuster = new Date().getTime()
    axios
      .request({
        url: `/admin/lookbooks/api_companies_and_users.json?cacheBuster=${cacheBuster}`,
        method: "get",
      })
      .then((resp) => {
        successCallback(resp)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}
