import React from "react"
import { twMerge } from "tailwind-merge"
import style from "./FieldError.module.scss"

export type FieldErrorProps = {
  message?: string
} & JSX.IntrinsicElements["div"]

/**
 * Must be inside parent positioned relatively.
 * @param props
 * @returns
 */
export function FieldError(props: FieldErrorProps) {
  const { className, message: rawMessage, ...restProps } = props

  const message = rawMessage?.split(/\s+/)?.slice(1)?.join(" ")

  return (message?.length ?? 0) > 0 ? (
    <>
      <div className={twMerge([className, style.floater])} {...restProps}>
        <div className={style.message}>{message}</div>
      </div>
    </>
  ) : (
    <></>
  )
}
