import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"
import Dropzone from "react-dropzone"
import { AdaModal } from "~/src/components"

@inject(["ideabookStore"])
@observer
class ZipFiles extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.data = { file: null }
    this.state = { isModalVisible: false, errors: {} }
  }

  @action onDrop(acceptedFiles) {
    this.data.file = acceptedFiles[0]
    let dropNode = $(this.dropzone.node)
    let innerDiv = dropNode.find("div")
    innerDiv.html(
      '<img src="https://production-assets.brilliantmade.com/images/blue-green-spinner.gif" class="spinner" />'
    )

    this.props.ideabookStore.uploadIdeabookZip(
      this.data,
      (resp) => {
        innerDiv.html("<span>Your zipfile was sent off for processing. Check back soon.</span>")
      },
      (errors) => {
        this.setState({ errors: errors })
      }
    )
  }

  dateFormat(dt) {
    let utcDate = new Date(dt).toUTCString()
    let localDate = new Date(utcDate)

    let hours = localDate.getHours()
    let mins = localDate.getMinutes()
    if (hours > 12) hours = hours - 12
    if (mins < 10) mins = "0" + mins
    return localDate.getMonth() + 1 + "/" + localDate.getDate() + " " + hours + ":" + mins
  }

  dropZone() {
    return (
      <Dropzone
        className="dropzone zip-upload-drop-zone"
        multiple={false}
        ref={(node) => (this.dropzone = node)}
        onDrop={this.onDrop.bind(this)}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <span>
              <p>Drop Your Zipfile here.</p>
              <p>
                Please make sure that your image file names haven't changed and also make sure the files are in the root
                of your zipfile.
              </p>
            </span>
          </div>
        )}
      </Dropzone>
    )
  }

  renderErrors(errors) {
    const errorItems = Object.entries(errors).map(([filename, error]) => {
      return (
        <li key={filename}>
          {filename}: {error}
        </li>
      )
    })
    return (
      <div>
        <p>Errors found in zipfile:</p>
        <ul>{errorItems}</ul>
      </div>
    )
  }

  modalContents() {
    const { errors } = this.state

    if (Object.keys(errors).length) {
      return this.renderErrors(errors)
    } else {
      return this.dropZone()
    }
  }

  render() {
    let self = this
    let processing = this.props.ideabookStore.ideabook.zip_trackers.filter(function (t) {
      return t.state === "waiting" || t.state === "new"
    })
    let failed = this.props.ideabookStore.ideabook.zip_trackers.filter(function (t) {
      return t.state === "failed"
    })
    let finished = this.props.ideabookStore.ideabook.zip_trackers.filter(function (t) {
      return t.state === "finished"
    })

    let processingDOM
    let failedDOM
    let finishedDOM

    if (processing.length > 0) {
      processingDOM = processing.map((t) => {
        let md = t.meta_data
        let prefix = t.type === "ZipUploadTracker" ? "Ingesting " : ""
        return (
          <li key={t.id} className="u-cf processing">
            {prefix}
            {md.name}
          </li>
        )
      })
    } else {
      processingDOM = <span>None</span>
    }

    if (failed.length > 0) {
      failedDOM = failed.map((t) => {
        let md = t.meta_data
        let time = self.dateFormat(t.created_at)
        return (
          <li key={t.id} className="u-cf failed" title={md.error} alt={md.error}>
            {time} {md.name}
          </li>
        )
      })
    } else {
      failedDOM = <span>None</span>
    }

    if (finished.length > 0) {
      finishedDOM = finished.map((t) => {
        let md = t.meta_data
        let time = self.dateFormat(t.created_at)
        let name =
          t.type === "ZipUploadTracker" ? (
            "Ingested " + md.name
          ) : (
            <a href={md.url} target="_blank">
              {md.name}
            </a>
          )
        return (
          <li key={t.id} className="u-cf finished">
            {time} {name}
          </li>
        )
      })
    } else {
      finishedDOM = <span>None</span>
    }

    return (
      <div>
        <div id="zip_files_box" className="ui-widget-content u-cf">
          <h4>Zip Files</h4>
          <button onClick={() => this.setState((s) => ({ ...s, isModalVisible: true }))}>Upload Zipfile</button>
          <br />
          <b>In progress</b>
          <ul>{processingDOM}</ul>
          <b>Failed</b>
          <ul>{failedDOM}</ul>
          <b>Finished</b>
          <ul>{finishedDOM}</ul>
        </div>
        <AdaModal
          aria-label="Upload Zipfile Dialog"
          cancelBtn={{ text: "Done" }}
          open={this.state.isModalVisible}
          okBtn={null}
          onClose={() => this.setState((s) => ({ ...s, isModalVisible: false }))}
          title="Upload Zipfile"
        >
          {this.modalContents()}
        </AdaModal>
      </div>
    )
  }
}

export default ZipFiles
