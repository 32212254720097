import React from "react"
import IdeabookStore from "../stores/ideabook_store"
import Ideabook from "./Ideabook"
import { Provider } from "mobx-react"

export class IdeabookApp extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.ideabookStore = null
    this.state = { dirty: false }
  }

  componentDidMount() {
    let self = this
    IdeabookStore.init(this.props.ideabook.id, this.props.env, (store) => {
      self.ideabookStore = store
      self.setState({ dirty: true })
    })
  }

  render() {
    if (this.ideabookStore) {
      this.state.dirty = false
      return (
        <Provider ideabookStore={this.ideabookStore}>
          <Ideabook />
        </Provider>
      )
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <h1 className="loading">Loading....</h1>
        </div>
      )
    }
  }
}
