import React, { ReactNode, MouseEventHandler, useState } from "react"
import { isFn, isNil } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import { Icon } from "~/src/components"
import style from "./ActionToolbar.module.scss"

export type ActionToolbarProps = {
  actions: Action[]
}

export type Action = {
  tooltip: string
  icon: string
  onUse: MouseEventHandler<HTMLButtonElement>

  className?: string
  children?: (args: ActionChildArgs) => ReactNode
}

type IconKeys = keyof typeof Icon
type IconValue = (typeof Icon)[IconKeys]
type ActionChildArgs = {
  onUse: MouseEventHandler<HTMLButtonElement>
  ActionIcon?: IconValue
  className?: string
}

export function ActionToolbar(props: ActionToolbarProps) {
  const { actions, ...restProps } = props

  return actions.length > 0 ? (
    <ul className={style.base} {...restProps}>
      {actions.map((action, idx) => {
        const { tooltip, icon, onUse, className, children } = action
        const ActionIcon = Icon[icon]

        return (
          <ActionTooltip key={idx} tooltip={tooltip}>
            {isFn(children) ? (
              children({ ActionIcon, onUse, className })
            ) : (
              <button className={className} onClick={onUse}>
                {isNil(ActionIcon) ? <Icon.Question title={"?"} /> : <ActionIcon />}
              </button>
            )}
          </ActionTooltip>
        )
      })}
    </ul>
  ) : null
}

type ActionTooltipProps = {
  tooltip: string
  children: ReactNode
}

function ActionTooltip(props: ActionTooltipProps) {
  const { tooltip, children, ...restProps } = props
  const [isVisible, setVisible] = useState(false)

  return (
    <li
      className={twMerge([style.actionItem])}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      {...restProps}
    >
      {children}
      {isVisible && <div className={style.actionItemTooltip}>{tooltip}</div>}
    </li>
  )
}
