import type { ThemeProps } from "@rjsf/core"

import { ArrayFieldTemplate } from "./ArrayFieldTemplate"
import { BaseInputTemplate } from "./BaseInputTemplate"
import { ErrorListTemplate } from "./ErrorListTemplate"
import { FieldTemplate } from "./FieldTemplate"
import { SubmitButtonTemplate } from "./SubmitButtonTemplate"
import { TitleFieldTemplate } from "./TitleFieldTemplate"

const templates: ThemeProps["templates"] = {
  ArrayFieldTemplate,
  BaseInputTemplate,
  ButtonTemplates: {
    SubmitButton: SubmitButtonTemplate,
  },
  ErrorListTemplate,
  FieldTemplate,
  TitleFieldTemplate,
}
export default templates
