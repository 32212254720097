import { useState } from "react"
import { isFn, isString } from "~/src/lib/any"

/**
 * Much like `useState`, but saves JSON.stringified state in `localStorage` at
 * `key`. Complex values like functions and regular expressions are not preserved
 * during serialization! Be careful.
 * @param {*} key
 * @param {*} initialValue
 */
export function useLocalStorage<S>(key: string, initialValue: S | (() => S)) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<S>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      if (isString(item)) return JSON.parse(item) as S
      if (isFn(initialValue)) return initialValue()
      return initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)

      if (isFn(initialValue)) return initialValue()
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: S) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore: S = typeof value === "function" ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  return [storedValue, setValue] as [S, (prevState: S) => S]
}
