import * as React from "react"
import { Ada } from "~/src/components/AdaKit"
import { Modal, DialogTitle, DialogDescription } from "~/src/components/AdaKit/Modal"
import { useFlipper } from "~/src/hooks"

type ZipActionModalProps = {
  onSubmit: () => void
  productCount: number | undefined
  logoCount: number | undefined
  lookbookId: number
}

export function ZipActionModal({ productCount, logoCount, onSubmit, lookbookId }: ZipActionModalProps) {
  let featureEnabled = useFlipper("lookbook_quick_brand_modal")
  let [isOpen, setModalState] = React.useState(false)
  let openModal = () => setModalState(true)
  let closeModal = () => setModalState(false)

  let handleZipAction = () => {
    closeModal()
    onSubmit()
  }

  let hasLogos = logoCount && logoCount >= 1
  let hasProducts = productCount && productCount >= 1

  let modalRequired = hasProducts && hasLogos && featureEnabled

  return modalRequired ? (
    <>
      <i className="fa fa fa-file-archive-o fa-lg" onClick={() => openModal()} title="Create Image ZIP"></i>
      <Modal open={isOpen} onClose={closeModal}>
        <DialogTitle>{productCount} items from this lookbook can be quick branded</DialogTitle>
        <section>Do you want to Quick Brand before zipping up this lookbook?</section>
        <div className="flex justify-center gap-4">
          <Ada.Button kind="secondary" onClick={handleZipAction}>
            Zip lookbook
          </Ada.Button>
          <Ada.ButtonLink href={`${lookbookId}/quick_brand`} kind="primary">
            Launch quick brand
          </Ada.ButtonLink>
        </div>
      </Modal>
    </>
  ) : (
    <i className="fa fa fa-file-archive-o fa-lg" onClick={() => onSubmit()} title="Create Image ZIP"></i>
  )
}
