import React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"

@inject(["ideabookStore"])
@observer
class Orders extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.orders = props.ideabookStore.ideabook.orders
  }

  render() {
    if (this.orders.length > 0) {
      return (
        <div id="orders_box" className="ui-widget-content">
          <h4>Orders</h4>
          <ul>
            {this.orders.map((o) => {
              let path = "/admin/orders/"
              if (o.order.type === "CustomOrder") {
                path = "/admin/custom_orders/"
              }
              return (
                <li key={o.order.id}>
                  <a href={`${path}${o.order.id}`}>
                    #{o.order.id} {o.order.name_override} - {o.order.total} {o.order.status}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Orders
