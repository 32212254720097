import _ from "lodash-es"
import { observable, toJS } from "mobx"
import IdeabookService from "../services/ideabook_service"

export default class IdeabookStore {
  static init(id, env, callback) {
    let self = new IdeabookStore()
    self.updateQueue = observable([])
    self.expanded = observable.box(true)
    self.debugMode = observable.box(false)
    self.socket = null
    self.env = env
    self.observeUpdateQueue()
    self.observeTrackers()

    IdeabookService.Fetch(
      id,
      (data) => {
        // Set some placeholders
        data.ideabook.half_cover = ""
        data.ideabook.cover = ""
        data.ideabook.og_image = ""

        self.ideabook = observable(data.ideabook)
        callback(self)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  fetchPhotosForProduct(productId, success, error) {
    IdeabookService.FetchPhotos(
      productId,
      this.ideabook.company.id,
      (resp) => {
        success(resp.data)
      },
      (err) => {
        error(err)
      }
    )
  }

  fetchCompaniesAndUsers(success, error) {
    IdeabookService.FetchCompaniesAndUsers(
      (resp) => {
        success(resp.data)
      },
      (err) => {
        error(err)
      }
    )
  }

  touchIdeabook(successCallback, errorCallback) {
    IdeabookService.TouchIdeabook(
      this.ideabook,
      (success) => {
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  touchIdeabookProducts(successCallback, errorCallback) {
    IdeabookService.TouchIdeabookProducts(
      this.ideabook,
      (success) => {
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  uploadFile(field, file, successCallback, errorCallback) {
    this.ideabook[field] = file

    let data = new FormData()
    data.append(`ideabook[${field}]`, file)
    data.append("ideabook[id]", this.ideabook.id)

    IdeabookService.UploadFile(
      data,
      (success) => {
        successCallback(success)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  uploadProductImage(uploadData, successCallback, errorCallback) {
    let data = new FormData()

    data.append("ideabook[id]", this.ideabook.id)
    data.append("file", uploadData.file)
    data.append("product_id", uploadData.product_id)

    if (uploadData.variant_id) {
      data.append("variant_id", uploadData.variant_id)
    }

    if (uploadData.new_color_name) {
      data.append("new_color_name", uploadData.new_color_name)
    }

    // Do the upload now....TODO
    IdeabookService.UploadProductImage(
      data,
      (success) => {
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  uploadIdeabookZip(uploadData, successCallback, errorCallback) {
    let data = new FormData()

    data.append("ideabook[id]", this.ideabook.id)
    data.append("ideabook[zipfile]", uploadData.file)

    IdeabookService.UploadIdeabookZip(
      data,
      (success) => {
        successCallback(success)
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          errorCallback(error.response.data.message)
        } else {
          errorCallback(error)
        }
      }
    )
  }

  repositionProductsByIds(ideabook_products, ids, saveNow = false) {
    ideabook_products = _.sortBy(ideabook_products, (g) => {
      return _.indexOf(ids, g.ideabook_product.id.toString())
    })

    let positions = {}

    for (let i = 0; i < ideabook_products.length; i++) {
      let obj = ideabook_products[i]
      positions[obj.ideabook_product.id] = i
    }

    IdeabookService.RepositionProducts(
      this.ideabook,
      positions,
      (success) => {
        console.log(success)
      },
      (error) => {
        alert(error)
        console.log(error)
      }
    )

    return this
  }

  repositionGroupsByIds(ids) {
    this.ideabook.ideabook_groupings = _.sortBy(this.ideabook.ideabook_groupings, (g) => {
      return _.indexOf(ids, g.ideabook_grouping.id.toString())
    })

    let positions = {}

    for (let i = 0; i < this.ideabook.ideabook_groupings.length; i++) {
      this.ideabook.ideabook_groupings[i].ideabook_grouping.position = i + 1

      let obj = this.ideabook.ideabook_groupings[i].ideabook_grouping
      positions[obj.id] = i
      // this.enqueueForUpdate(this.ideabook.ideabook_groupings[i])
    }

    IdeabookService.RepositionGroups(
      this.ideabook,
      positions,
      (success) => {
        console.log(success)
      },
      (error) => {
        alert(error)
        console.log(error)
      }
    )

    return this
  }

  createIdeabook(name, selectedProducts, successCallback, errorCallback) {
    IdeabookService.CreateIdeabook(
      name,
      this.ideabook.id,
      selectedProducts,
      (success) => {
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  createCustomRequestUser(obj, successCallback, errorCallback) {
    this.updateObject(
      obj,
      (resp) => {
        let newCustomRequestUser = JSON.parse(resp.object)
        let user = _.find(this.ideabook.enumerations.users, (u) => {
          return u.id === newCustomRequestUser.user_id
        })
        newCustomRequestUser.user = user
        this.ideabook.custom_request_users.push({ custom_request_user: newCustomRequestUser })
        this.ideabook.shared_with.push({
          ...user,
          id: newCustomRequestUser.id,
          type: "CustomRequestUser",
          user_id: user.id,
        })
        successCallback(resp)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  deleteCustomRequestUser(id, successCallback, errorCallback) {
    this.deleteObject(
      { custom_request_user: { id: id } },
      (resp) => {
        _.remove(this.ideabook.shared_with, (u) => {
          return u.user_id === id || u.id === id
        })
        successCallback(resp)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  deleteExternalUser(id, onSuccess, onError) {
    IdeabookService.DeleteExternalUser(
      id,
      this.ideabook.id,
      (success) => {
        _.remove(this.ideabook.shared_with, (u) => {
          return u.user_id === id || u.id === id
        })
        onSuccess(success)
      },
      (err) => {
        onError(err)
      }
    )
  }

  createGroup(group, successCallback, errorCallback) {
    this.updateObject(
      group,
      (resp) => {
        try {
          let newGroup = JSON.parse(resp.object)
          newGroup.ideabook_products = []
          this.ideabook.ideabook_groupings.unshift({ ideabook_grouping: newGroup })
          successCallback(resp)
        } catch (e) {
          errorCallback(e)
        }
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  deleteGroup(group, successCallback, errorCallback) {
    this.deleteObject(
      group,
      (resp) => {
        _.remove(this.ideabook.ideabook_groupings, (g) => {
          return g.ideabook_grouping.id === group.ideabook_grouping.id
        })

        successCallback(this.ideabook.ideabook_groupings, resp)
      },
      (err) => {
        errorCallback(this.ideabook.ideabook_groupings, err)
      }
    )
  }

  deleteIdeabookProduct(iProd, successCallback, errorCallback) {
    this.deleteObject(
      iProd,
      (resp) => {
        _.forEach(this.ideabook.ideabook_groupings, (g) => {
          let group = g.ideabook_grouping
          _.remove(group.ideabook_products, (p) => {
            return p.ideabook_product.id === iProd.ideabook_product.id
          })
        })
        successCallback(resp)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  enqueueForUpdate(obj) {
    if (this._notAlreadyEnqueued(obj)) {
      let enqueueable = {}
      enqueueable[this._enqueueKey(obj)] = obj
      this.updateQueue.push(enqueueable)
    }
  }

  observeUpdateQueue() {
    let self = this
    setInterval(() => {
      while (self.updateQueue.length > 0) {
        let enqueued = self.updateQueue.shift()
        let obj = Object.values(enqueued)[0]
        self.updateObject(
          obj,
          (resp) => {
            // Notify the client that
            // everything is saving ok.
            // TODO
          },
          (err) => {
            // try again after some time passes
            if (obj.attempt && obj.attempt < 4) {
              obj.attempt += 1
              setTimeout(() => {
                self.enqueueForUpdate(obj)
              }, 5 * 1000) // 250 milliseconds
            }
          }
        )
      }
    }, 250) // check for updates 4 times per second
  }

  // TODO move the code in here into something
  // dry. This was mostly copied from
  // the observeTrafficChanges() function in the
  // traffic board project.
  observeTrackers() {
    let self = this
    let channelId = "thread_tracker"

    let eventBusUrl = ""

    if (["staging", "production", "experimental"].includes(this.env)) {
      eventBusUrl += "wss://"
    } else {
      eventBusUrl += "ws://"
    }

    eventBusUrl += `${window.location.host}/eventbus`

    if (self.socket) self.socket.close()

    self.socket = new WebSocket(eventBusUrl)

    self.socket.onopen = () => {
      let subscribeMsg = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "Eventbus",
          stream: channelId,
        }),
        data: JSON.stringify({
          stream: channelId,
        }),
      }

      self.socket.send(JSON.stringify(subscribeMsg))
    }

    self.socket.onmessage = (evt) => {
      if (!self.ideabook) {
        return
      }

      let data = JSON.parse(evt.data)
      if (data.type === "ping") return
      if (data.type === "confirm_subscription") return

      let message = data.message

      if (message && message.meta_data) {
        message.meta_data = JSON.parse(message.meta_data)
        let ideabook_id = message.meta_data.ideabook_id

        if (ideabook_id === self.ideabook.id) {
          let ind = self.ideabook.zip_trackers.findIndex(function (t) {
            return t.id === message.id
          })
          if (ind > -1) {
            self.ideabook.zip_trackers[ind] = message
          } else {
            self.ideabook.zip_trackers.unshift(message)
          }
        }
      }
    }
  }

  updateObject(obj, successCallback, errorCallback) {
    console.group("IdeabookStore#updateObject")

    let parts = this._objectParts(obj)
    let className = _.upperFirst(_.camelCase(parts.class_name))
    let id = parts.id

    // prepare the object backend acceptance
    let ommittedKeys = [
      "id",
      "attempt",
      "half_cover_url",
      "cover_url",
      "og_image_url",
      "num_group_pages",
      "num_groups",
      "half_cover_xlarge_url",
      "cover_xlarge_url",
    ]

    // remove objects from the object. We only update the
    // top level object.
    _.forIn(parts.attributes, (v, k) => {
      if (typeof v === "object" && !_.endsWith(k, "_id")) {
        ommittedKeys.push(k)
      }
    })

    let attrs = _.omit(parts.attributes, ommittedKeys)

    // search_tags must transformed to search_tag_list
    // to satisfy act-as-taggable-on
    if (parts.attributes.search_tags && parts.attributes.search_tags.length > 0) {
      const { search_tags = [] } = parts.attributes
      attrs.search_tag_list = search_tags.map((x) => x.name).join(",")
    }

    console.log({ attrs })

    IdeabookService.UpdateModel(
      className,
      id,
      attrs,
      (success) => {
        successCallback(success)
      },
      (err) => {
        errorCallback(err)
      }
    )

    console.groupEnd()
  }

  deleteObject(obj, successCallback, errorCallback) {
    let parts = this._objectParts(obj)
    let className = _.upperFirst(_.camelCase(parts.class_name))
    let id = parts.id

    IdeabookService.DeleteModel(
      className,
      id,
      this.ideabook.id,
      (success) => {
        successCallback(success)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  searchByNameOrNumber(searchTerm, successCallback, errorCallback) {
    IdeabookService.SearchByNameOrNumber(
      searchTerm,
      (success) => {
        successCallback(success)
      },
      (err) => {
        errorCallback(err)
      }
    )
  }

  addToIdeabook(id, ideabookProductIds, successCallback, errorCallback) {
    IdeabookService.AddToIdeabook(
      id,
      ideabookProductIds,
      (success) => {
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  _objectParts(obj) {
    let jsObj = toJS(obj)
    let className = Object.keys(jsObj)[0]
    let attrs = Object.values(jsObj)[0] || {}
    return {
      class_name: className,
      id: attrs.id,
      attributes: attrs,
    }
  }

  _enqueueKey(obj) {
    let parts = this._objectParts(obj)
    return `${parts.class_name}_${parts.id}`
  }

  _alreadyEnqueued(obj) {
    let k = this._enqueueKey(obj)
    return !!_.find(this.updateQueue, k)
  }

  _notAlreadyEnqueued(obj) {
    return !this._alreadyEnqueued(obj)
  }
}
