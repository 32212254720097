import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { isNil } from "~/src/lib/any"
import { Country } from "~/src/lib/country"
import { iname } from "~/src/lib/iname"

import { Bee } from "../BeeKit"
import { AddressFieldsImplementationProps } from "./addressFields.types"

export function AddressFieldsWithoutHookForm(props: AddressFieldsImplementationProps) {
  const {
    className,
    defaultValues = {},
    fieldClassStyling = "",
    includeNameField = true,
    includeCompanyField = true,
    disabled = false,
    internationalAllowed,
    serverErrors,
    fieldNamePrefix,
    restrictedCountryCodes = [],
    includeAddressValidation = false,
  } = props

  const names = iname(fieldNamePrefix)

  const [countryCode, setCountryCode] = useState<string>(defaultValues[names.addressCountry._] || "US")
  const country =
    internationalAllowed && restrictedCountryCodes.length > 0 && !restrictedCountryCodes.includes(countryCode)
      ? Country.fromAlpha2(restrictedCountryCodes[0])
      : Country.fromAlpha2(countryCode)

  const fieldStyle = twMerge("w-full border border-gray-300 rounded", fieldClassStyling)

  const countryOptions = Country.allPriorityFirst()
    .filter((c) => restrictedCountryCodes.length == 0 || restrictedCountryCodes.includes(c.alpha2))
    .map((c) => ({ value: c.alpha2, label: c.name, disabled: Country.disabledCountries().includes(c.alpha2) }))

  return (
    <fieldset className={twMerge("flex flex-col gap-4 w-full", className)}>
      <input type="hidden" name={names.includeAddressValidations._} defaultValue={includeAddressValidation ? 1 : 0} />
      {includeNameField ? (
        <Bee.Field label="Address name" defaultErrorMessages={serverErrors?.addressName} className="w-full">
          <Bee.Input
            type="text"
            name={names.addressName._}
            defaultValue={defaultValues[names.addressName._]}
            required={includeNameField}
            disabled={disabled}
          />
        </Bee.Field>
      ) : null}
      {includeCompanyField ? (
        <Bee.Field label="Address company" defaultErrorMessages={serverErrors?.addressCompany} className="w-full">
          <Bee.Input
            type="text"
            name={names.addressCountry._}
            defaultValue={defaultValues[names.addressCountry._]}
            required={includeCompanyField}
            disabled={disabled}
          />
        </Bee.Field>
      ) : null}
      {/* Country */}
      <Bee.Field label="Country" defaultErrorMessages={serverErrors?.addressCountry} className="w-full">
        <select
          className={fieldStyle}
          disabled={disabled || !internationalAllowed}
          name={names.addressCountry._}
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        >
          {countryOptions.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
      </Bee.Field>
      {/* Street Address */}
      <Bee.Field label="Street address" defaultErrorMessages={serverErrors?.address1} className="w-full">
        <Bee.Input
          type="text"
          name={names.address1._}
          defaultValue={defaultValues[names.address1._]}
          disabled={disabled}
          required
        />
      </Bee.Field>
      {/* Address line 2 */}
      <Bee.Field label="Address line 2" defaultErrorMessages={serverErrors?.address2} className="w-full">
        <Bee.Input type="text" name={names.address2._} defaultValue={defaultValues[names.address2._]} />
      </Bee.Field>
      {/* City */}
      <div className="md:grid md:grid-cols-[2fr_1fr_1fr] flex flex-col gap-4 w-full ">
        <Bee.Field label="City" defaultErrorMessages={serverErrors?.addressCity}>
          <Bee.Input
            type="text"
            name={names.addressCity._}
            defaultValue={defaultValues[names.addressCity._]}
            disabled={disabled}
            required
          />
        </Bee.Field>
        {/* State */}
        {country instanceof Error || isNil(country.states) ? (
          <></>
        ) : (
          <div>
            <Bee.Field label="State" defaultErrorMessages={serverErrors?.addressState}>
              <select
                className={fieldStyle}
                name={names.addressState._}
                required={country.isStateRequired()}
                defaultValue={defaultValues[names.addressState._]}
                disabled={disabled}
              >
                {country.states.map((s) => (
                  <option key={s.alpha2} value={s.alpha2}>
                    {s.name}
                  </option>
                ))}
              </select>
            </Bee.Field>
          </div>
        )}
        {/* Zip */}
        <Bee.Field label="Zip Code" defaultErrorMessages={serverErrors?.addressZip}>
          <Bee.Input
            type="text"
            name={names.addressZip._}
            defaultValue={defaultValues[names.addressZip._]}
            disabled={disabled}
            required
          />
        </Bee.Field>
        {/* Phone Number */}
        {!(country instanceof Error) && country.isInternational() ? (
          <Bee.Field label="Phone Number" defaultErrorMessages={serverErrors?.addressPhone}>
            <Bee.Input
              type="text"
              name={names.addressPhone._}
              defaultValue={defaultValues[names.addressPhone._]}
              disabled={disabled}
              required={country.isInternational()}
            />
          </Bee.Field>
        ) : (
          <></>
        )}
      </div>
    </fieldset>
  )
}
