import React, { Fragment } from "react"
import { humanize } from "underscore.string"
import { WizardStep } from "~/src/hooks"
import { takeUntil } from "~/src/lib/array"
import { twMerge } from "tailwind-merge"
import { NavButton } from "./NavButton"
import style from "./WizardCrumbs.module.scss"

export type WizardCrumbsProps = { currentStep: string; steps: WizardStep[] } & JSX.IntrinsicElements["div"]

export function WizardCrumbs(props: WizardCrumbsProps) {
  const { className, currentStep, steps, ...restProps } = props

  return (
    <div className={twMerge([style.base, className])} {...restProps}>
      {takeUntil((s) => s.id === currentStep, steps).map(({ id }, index, arr) => {
        const isLast = index === arr.length - 1

        return (
          <Fragment key={id}>
            <NavButton isActive={isLast}>
              <a href={currentStep === id ? undefined : "#" + id}>{humanize(id)}</a>
            </NavButton>
            {!isLast ? <span className={style.nextArrow}> &gt; </span> : null}
          </Fragment>
        )
      })}
    </div>
  )
}
