import { partition } from "~/src/lib/array"

import { QuickSendSelectionKind } from "~/src/types/preferredGift"
import { QuickSendSelectionItem } from "~/src/types/productSelection"

const VALID_MERGE_FIELDS = ["recipient_name", "sender_name", "company_name"]

type SummarizedSelections = {
  chosenSelections: QuickSendSelectionItem[]
  giftedSelections: QuickSendSelectionItem[]
  estimatedTotal: number
}

export function summarizeSelections(selections: QuickSendSelectionItem[]): SummarizedSelections {
  const chosenSelections = selections.filter((item) => item.kind == QuickSendSelectionKind.Chosen)
  const giftedSelections = selections.filter((item) => item.kind == QuickSendSelectionKind.Gifted)
  const estimatedTotal =
    giftedSelections
      .filter(({ selectable: { prepaid } }) => !prepaid)
      .reduce((sum, { selectable }) => sum + Number(selectable.displayPrice), 0) +
    Math.max(
      0,
      ...chosenSelections
        .filter(({ selectable: { prepaid } }) => !prepaid)
        .map(({ selectable }) => Number(selectable.displayPrice))
    )

  return { chosenSelections, giftedSelections, estimatedTotal }
}

export function summarizeMergeFields(body: string): [string[], string[]] {
  const mergeFields = findMergeFields(body)
  return partition(mergeFields, (mergeField: string) => VALID_MERGE_FIELDS.includes(mergeField))
}

function findMergeFields(body: string) {
  const findMergeFieldRegExp = /{{[^{}]*}}/g
  const replaceBracketsRegexp = /{{|}}/g
  return [...body.matchAll(findMergeFieldRegExp)].map((match) => match[0].replaceAll(replaceBracketsRegexp, "").trim())
}
