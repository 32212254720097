import * as React from "react"
import { useContext } from "react"
import { FulfillmentContext } from "../FulfillmentContext"

type AutoAllocateFieldProps = {
  fieldName: string
  id: string
}

const label = "Create shipments + deduct inventory for new responses"

export function AutoAllocateField({ fieldName, id }: AutoAllocateFieldProps) {
  const { autoAllocate, setAutoAllocate } = useContext(FulfillmentContext)

  return (
    <>
      <li className="no-border">
        <strong>Inventory Allocation</strong>
      </li>
      <li id={`${id}_input`} className="flex-boolean-label input boolean">
        <label htmlFor={id}>
          <input type="hidden" name={fieldName} value="false" />
          <input
            type="checkbox"
            id={id}
            name={fieldName}
            checked={autoAllocate}
            value="true"
            onChange={(e) => setAutoAllocate(e.target.checked)}
          />
          <div>
            <span>{label}</span>
            <br />
            <div className="hints">
              Enabling this feature generates shipments for responses as they are collected, deducting inventory from
              the Store and applying it to those shipments.
              <br />
              When enabling this setting, please review and adjust the Fulfillment Method setting to best suit the
              fulfillment needs for new responses as they are collected.
              <br />
              Please note, if "{label}" is disabled, you do not need to set a "Fulfillment Method" because shipments
              will be created later.
            </div>
            <br />
            <div className="hints">
              <strong>IMPORTANT:</strong>
              &#32;Client must have a store set up and a signed fulfillment agreement to use this feature.
            </div>
          </div>
        </label>
      </li>
    </>
  )
}
