import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"

@inject(["ideabookStore"])
@observer
class Input extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ideabook = props.ideabookStore.ideabook
    this.field = props.field
    this.label = props.label || _.capitalize(_.upperCase(this.field))
    this.className = props.className
    this.useCaution = props.useCaution && props.useCaution === "true"
    this.state = { readOnly: this.useCaution }
    this.originalValue = this.ideabook[this.field]
  }

  @action updateField(field, value) {
    this.ideabook[field] = value
    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.ideabook })
  }

  @action toggleWriteable() {
    if (!this.state.readOnly) {
      this.updateField(this.field, this.originalValue)
    } else {
      alert("I hope you know what you are doing.")
    }
    this.setState({ readOnly: !this.state.readOnly })
  }

  render() {
    let writeToggle = ""
    if (this.useCaution) {
      writeToggle = (
        <button
          className="write-toggle"
          onClick={(e) => {
            this.toggleWriteable()
            e.preventDefault()
          }}
        >
          {this.state.readOnly ? "Unlock" : "Revert"}
        </button>
      )
    }

    return (
      <div>
        <div className="input text">
          <label>{this.label}:</label>
          <input
            type="text"
            className={this.props.className || null}
            name={this.field}
            value={this.ideabook[this.field] || ""}
            onChange={(e) => this.updateField(e.target.name, e.target.value)}
            readOnly={this.state.readOnly}
          />
          {writeToggle}
        </div>
      </div>
    )
  }
}

export default Input
