import { twMerge } from "tailwind-merge"

export function buttonClassName(kind: "primary" | "secondary") {
  return twMerge(
    [
      "inline-block",
      "px-4",
      // Remove border width from padding to get that pixel perfect look
      "py-[calc(theme(spacing.2)-theme(borderWidth.DEFAULT))]",
      "rounded-lg",
      "font-medium",
      "text-center",
      "whitespace-nowrap",
      "border",
    ],
    kind === "primary" && [
      "border-navy-800",
      "disabled:border-gray-300",
      "bg-navy-800",
      "disabled:bg-gray-300",
      "text-white",
    ],
    kind === "secondary" && [
      "border-gray-900",
      "disabled:border-gray-300",
      "bg-transparent",
      "text-grey-900",
      "disabled:text-gray-300",
    ]
  )
}

export function flashClassName(kind: "success" | "error") {
  return twMerge(
    ["flex", "flex-col", "p-4", "rounded-lg"],
    kind === "success" ? ["bg-emerald-400", "text-white"] : ["bg-red-100", "text-red-600"]
  )
}
