import { SchemaOf } from "yup"
import { ShippingCostEstimatorFormState } from "~/src/components"
import yup from "~/src/lib/yup-extended"

const { array, object, mixed, number, string } = yup

const NUMBER_TYPE_ERROR_MESSAGE = "${path} is required"

export const shippingCostEstimatorFormStateSchema: SchemaOf<ShippingCostEstimatorFormState> = object().shape({
  baseMarginPercentage: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).integer().positive().required(),
  allowedCarriers: mixed().oneOf(["exclude_economy", "no_restrictions"]).required(),
  shippingKit: mixed()
    .oneOf(["corrugated_box", "padded_mailer", "no_additional_packaging", "custom_packaging"])
    .required(),
  customPackagingWeight: mixed().when("shippingKit", {
    is: "custom_packaging",
    then: number().positive().required(),
    otherwise: mixed().notRequired(),
  }),
  destinations: array()
    .of(
      object().shape({
        key: mixed(),
        alpha2: string().required(),
        zoning: mixed().oneOf(["commercial", "residential"]).required(),
        quantity: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).integer().min(1).required(),
        carrier: string(),
        service: string(),
      })
    )
    .min(1),
  packageDimension: object()
    .notRequired()
    .shape({
      weight: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).positive(),
      length: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).positive(),
      width: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).positive(),
      height: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).positive(),
    }),
  variants: array()
    .of(
      object().shape({
        id: number().required().integer().positive(),
        quantity: number().typeError(NUMBER_TYPE_ERROR_MESSAGE).required().integer().min(1),
        productId: mixed(),
        productName: mixed(),
        unitWeightOz: number().positive().required(),
        key: mixed(),
      })
    )
    .min(1),
  timeWindow: mixed().oneOf(["most_economical_rate", "by_certain_date"]).required(),
  byDays: number()
    .typeError(NUMBER_TYPE_ERROR_MESSAGE)
    .integer()
    .min(1)
    .when("timeWindow", (timeWindow, schema) => {
      if (timeWindow === "by_certain_date") return schema.required()

      return schema.notRequired()
    }), // Depends on timeWindow
})
