import { Collection, Table } from "dexie"
import { escapeRegExp } from "lodash-es"
import React, { FunctionComponent, useState } from "react"
import { useHashChange, useLocalStorage, useTable } from "~/src/hooks"
import { Awaitable, isBlank, isFn, isNil, isntNil } from "~/src/lib/any"
import { Country } from "~/src/lib/country"
import { FinishStep } from "./FinishStep"
import { MatchHeadersStep } from "./MatchHeadersStep"
import { ReviewStep } from "./ReviewStep"
import { UploadStep } from "./UploadStep"
import { WizardCrumbs } from ".."

// Rails context types
export type FulfillmentKit = {
  name: string
}

export type FulfillmentRequest = {
  id: string
  batch_store_order_user_id: number
  fulfillment_kits?: FulfillmentKit[]
}

// Dropship specific types
export type Row = {
  id: number // Required table key
  namespace: string // Required table key
  address_1?: string
  address_2?: string
  city?: string
  company?: string
  country?: string
  kit?: string
  name?: string
  notecard_message?: string
  notes?: string
  notification_email?: string
  order_recipient_id?: string
  phone?: string
  recipient_email?: string
  slack?: string
  state?: string
  zip?: string
  shipment_group_name?: string
  custom_field?: string
  errorMessages?: string[]
  errors?: Record<string, string>
}

// Bulk upload specific types
export type BulkUploadColumnOptions = {
  key?: string
  isGroup?: boolean
  isRequired?: boolean
  isValidationStrict?: boolean
  validationHint?: string[] | JSX.Element
  pattern?: RegExp
  prefix?: string
  width?: number
  columnIdx?: number
  transformCell?: (args: { value: string; row: Row; header: any; props: StepComponentProps<Row> }) => string
  options?:
    | { label: string; value: string }[]
    | ((props: any, row: Row) => { label: string; value: string }[] | undefined)
}

export type BulkUploadColumns = Record<string, BulkUploadColumnOptions>

// Wizard types
export interface StepComponent<R> extends FunctionComponent<StepComponentProps<R>> {
  onNext?: (props: StepComponentProps<R>) => Awaitable<void>
}

export interface StepComponentProps<R> {
  collection: Collection<R, [string, number]>
  csrfToken: string
  currentAdminUserId: string
  currentStep: string
  fulfillmentKits?: FulfillmentKit[]
  fulfillmentRequest: FulfillmentRequest
  headers: BulkUploadColumns
  inTransition: boolean
  namespace: string
  nextStep?: string
  rawData?: string[][]
  setHeaders: (value: Record<string, BulkUploadColumnOptions>) => void
  setRawData: (value: string[][]) => void
  table: Table<R, [string, number]>
  onNextClick?: () => Awaitable<void>
}

export type Step<R> = {
  id: string
  next?: string
  Component: StepComponent<R>
}

const STEPS: Step<Row>[] = [
  {
    id: "upload",
    next: "match-headers",
    Component: UploadStep,
  },
  {
    id: "match-headers",
    next: "review",
    Component: MatchHeadersStep,
  },
  {
    id: "review",
    next: "finish",
    Component: ReviewStep,
  },
  {
    id: "finish",
    Component: FinishStep,
  },
]

const ZERO_FIRST_STATES = new Set([
  "CT", // "Connecticut",
  "MA", // "Massachusetts",
  "ME", // "Maine",
  "NH", // "New Hampshire",
  "NJ", // "New Jersey",
  "PR", // "Puerto Rico",
  "RI", // "Rhode Island",
  "VT", // "Vermont",
  "VI", // "Virgin Islands",
  // "Army Post Office Europe",
  // "Fleet Post Office Europe"
])

const HEADERS: BulkUploadColumns = {
  kit: {
    options({ fulfillmentKits: fKs = [] }) {
      return fKs.map((k) => ({ label: k.name, value: k.name }))
    },
    // If there is only one kit and none are given, apply that kit
    transformCell({ value, props: { fulfillmentKits: fKs = [] } }) {
      if (isBlank(value)) {
        if (fKs.length === 1) return fKs[0].name
        return value
      }

      const matchedKit = fKs.find((k) => value == k.name)

      if (isntNil(matchedKit)) return matchedKit.name

      const looseValue = new RegExp(value.trim().toLowerCase().split(/\s+/).map(escapeRegExp).join("\\s+"), "i")
      const kit = fKs.find((k) => looseValue.test(k.name))

      if (isntNil(kit)) return kit.name

      return value
    },
    isRequired: false,
    isGroup: true,
    pattern: /forced_preference?/i,
    isValidationStrict: true,
    validationHint: ["Watch out for extra spaces in the kit name!"],
    width: 150,
  },
  notes: { pattern: /notes?/i, prefix: "fulfillment_", isRequired: false, isGroup: true, width: 200 },
  notecard_message: { pattern: /notecard_message?/i, isRequired: false, width: 200 },
  name: { prefix: "address_", isValidationStrict: true, width: 200 },
  company: { prefix: "address_", isRequired: false, isValidationStrict: true, width: 200 },
  address_1: { pattern: /(street|address.+1)/i, isValidationStrict: true, width: 200 },
  address_2: { pattern: /address.+2/i, width: 150 },
  city: { prefix: "address_", isValidationStrict: true, width: 150 },
  state: {
    prefix: "address_",
    isValidationStrict: true,
    options(_props, { country: alpha2 }) {
      const country = Country.fromAlpha2(isntNil(alpha2) ? alpha2 : "US")

      if (country instanceof Error || isNil(country.states)) return

      return country.states.map((s) => ({ label: s.name, value: s.alpha2 }))
    },
    width: 150,
  },
  zip: {
    prefix: "address_",
    pattern: /(zip|postal.code)/i,
    transformCell({ row: { country, state }, value: rawValue }) {
      const value = rawValue?.replace(/\s+/g, "")?.toUpperCase() ?? ""

      // TODO: Remove me
      if (isntNil(state) && (isBlank(country) || country?.toUpperCase() === "US")) {
        return ZERO_FIRST_STATES.has(state) ? value.padStart(5, "0") : value
      }

      return value
    },
    validationHint: (
      <>
        <p>
          <strong>Format Legend:</strong> <em>N</em> is a number, <em>A</em> is a letter, <em>#</em> is a number or
          letter and <em>CC</em> is the country&apos;s 2-letter code. Any other character is literal.
        </p>
        <p>Make sure to double check the country if the zip appears to be correct.</p>
      </>
    ),
  },
  country: {
    isRequired: false,
    isValidationStrict: true,
    prefix: "address_",
    options: Country.allPriorityFirst().map((c) => ({ label: c.name, value: c.alpha2 })),
    transformCell({ value }) {
      if (isBlank(value)) return "US"

      return value
    },
    width: 150,
  },
  phone: { isRequired: false, isValidationStrict: true, prefix: "address_", width: 200 },
  recipient_email: { isRequired: false, width: 150 },
  notification_email: { isRequired: false, width: 150 },
  order_recipient_id: { isRequired: false, width: 150 },
  slack: { isRequired: false },
  shipment_group_name: { isRequired: false },
  custom_field: { isRequired: false },
}

export interface DropshipWizardMainProps {
  csrf_token: string
  current_admin_user_id: string
  fulfillmentRequest: FulfillmentRequest
}

export function DropshipWizardMain(props: DropshipWizardMainProps) {
  // TODO: Nest all incoming props from Rails into a `railsContext`
  const {
    csrf_token: csrfToken,
    current_admin_user_id: currentAdminUserId,
    fulfillmentRequest,
    fulfillmentRequest: { fulfillment_kits: fulfillmentKits } = {},
  } = props
  const { hash } = useHashChange()

  const [headers, setHeaders] = useLocalStorage<BulkUploadColumns>(storageId("headers"), () => HEADERS)
  const [rawData, setRawData] = useLocalStorage<string[][]>(storageId("raw_data"), [])
  const { collection, namespace, table } = useTable("drops")
  const [inTransition, setInTransition] = useState(false)

  // TODO: Decouple dynamic column properties for headers (namely columnIdx)
  const fullHeaders = { ...HEADERS }
  for (const [headerName, opts] of Object.entries(headers)) {
    if (isNil(opts.columnIdx)) continue

    fullHeaders[headerName].columnIdx = opts.columnIdx
  }

  const { Component = UploadStep, id: currentStep, next: nextStep } = STEPS.find((step) => step.id === hash) ?? STEPS[0]
  const componentProps: StepComponentProps<Row> = {
    collection,
    csrfToken,
    currentAdminUserId,
    currentStep,
    fulfillmentKits,
    fulfillmentRequest,
    headers: fullHeaders,
    inTransition,
    namespace,
    nextStep,
    rawData,
    setHeaders,
    setRawData,
    table,
  }

  const handleNextClick = async () => {
    if (isFn(Component?.onNext)) {
      setInTransition(true)
      await Component?.onNext?.(componentProps)
      setInTransition(false)
    }
  }

  return (
    <>
      <WizardCrumbs steps={STEPS} currentStep={currentStep} />
      <Component onNextClick={handleNextClick} {...componentProps} />
    </>
  )
}

/**
 * Private Components
 */

function storageId(name: string): string {
  return window.location.pathname.split(/\//).slice(-3).concat(name).join("_")
}
