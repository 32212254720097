import React, { useState, useEffect } from "react"
import { isPresent } from "~/src/lib/any"

export type CheckBoxMultiCategorySelection = { value: string; category: string }
export type CheckBoxMultiCategoryConfig = { [category: string]: Category }

type Selection = CheckBoxMultiCategorySelection
type Config = CheckBoxMultiCategoryConfig

type Options = { [value: string]: string }

type Category = {
  label: string | React.ReactNode
  selectAllText?: string | React.ReactNode
  options: Options
}

export type CheckBoxMultiCategorySelectProps = {
  style?: "label" | "checkbox"
  config: Config
  defaultSelections?: Selection[]
  selections?: Selection[]
  onChange: (values: Selection[]) => void
  selectAllText?: string
}

export namespace CheckBoxMultiCategorySelections {
  export function getCategoryName(selections: CheckBoxMultiCategorySelection[]) {
    return selections.reduce((result: string[], selection) => {
      if (result.includes(selection.category)) {
        return result
      } else {
        return [...result, selection.category]
      }
    }, [])
  }
}

/**
 * Internal data structure for tracking changes to options within categories.
 * Organized as a Map of Category Name => Set of Option Values.
 * Instead of mutating an instance, use the provided methods to create new CategoryLists
 */
class CategoryList {
  /** The underlying data structure */
  categories: Map<string, Set<string>>

  /** An array of selections, { value: <option value>, category: <category name> }[] */
  selections: Selection[]

  /** The count of option values within the CategoryList */
  size: number

  /**
   * The constructor of the `CategoryList` class
   *
   * @param selections An array of selections { value: <option value>, category: <category name> }[]
   */
  constructor(selections: Selection[]) {
    this.categories = new Map()
    this.selections = selections
    this.size = 0

    selections.forEach((selection) => {
      const category = this.categories.get(selection.category)
      if (category) {
        category.add(selection.value)
      } else {
        this.categories.set(selection.category, new Set([selection.value]))
      }
    })

    for (const values of this.categories.values()) {
      this.size += values.size
    }
  }

  /**
   * Helper for the React component.
   * Builds a `CategoryList` from a Config prop.
   *
   * @param config Object containing the configuration of each category, passed to the React component as props
   */
  static fromConfig(config: Config): CategoryList {
    const selections: Selection[] = []
    Object.entries(config).forEach(([categoryName, category]) => {
      Object.keys(category.options).forEach((optionValue) => {
        selections.push({ value: optionValue, category: categoryName })
      })
    })

    return new CategoryList(selections)
  }

  /**
   * Returns a set of option values for the given category Name.
   * Returns the empty set if the category does not exist.
   *
   * @param categoryName Name of the category
   */
  getCategory(categoryName: string): Set<string> {
    const category = this.categories.get(categoryName)
    return category ? category : new Set()
  }

  /**
   * Returns a boolean for whether or not this option value exists in this category
   *
   * @param categoryName Name of the category
   * @param value Value of the option
   */
  has(categoryName: string, value: string): boolean {
    return this.getCategory(categoryName).has(value)
  }

  /**
   * Returns a new `CategoryList` with the provided option + category added
   *
   * @param categoryName Name of the category
   * @param value Value of the option
   */
  add(categoryName: string, value: string): CategoryList {
    const oldCategory = this.getCategory(categoryName)
    const newCategory = new Set(oldCategory.values())
    newCategory.add(value)
    return this.withCategory(categoryName, newCategory)
  }

  /**
   * Returns a new `CategoryList` with the provided option + category removed
   *
   * @param categoryName Name of the category
   * @param value Value of the option
   */
  delete(categoryName: string, value: string): CategoryList {
    const oldCategory = this.getCategory(categoryName)
    const newCategory = new Set(oldCategory.values())
    newCategory.delete(value)
    return this.withCategory(categoryName, newCategory)
  }

  /**
   * Returns a new `CategoryList` with the same values, but with the named category containing newOptionValues inserted.
   * If a category was already present under this name, it is replaced
   *
   * @param categoryName Name of the category
   * @param newOptionValues Set of option values under this category
   */
  withCategory(newCategoryName: string, newOptionValues: Set<string>): CategoryList {
    const newCategorySelections: Selection[] = []
    for (const newOptionValue of newOptionValues.values()) {
      newCategorySelections.push({ value: newOptionValue, category: newCategoryName })
    }

    const newSelections = this.selections
      .filter((selection) => selection.category != newCategoryName)
      .concat(newCategorySelections)

    return new CategoryList(newSelections)
  }

  /**
   * Returns a new `CategoryList` with the same values, but with the named category removed.
   *
   * @param categoryName Name of the category
   */
  withoutCategory(categoryName: string): CategoryList {
    return this.withCategory(categoryName, new Set())
  }

  /**
   * Returns a new `CategoryList`, the difference of this `CategoryList` and another.
   * The new `CategoryList` only contains categories with option values that are not held in common by either existing list.
   *
   * @param rhs CategoryList to compare against
   */
  difference(rhs: CategoryList): CategoryList {
    const selections: Selection[] = []

    for (const categoryName of this.categories.keys()) {
      for (const value of this.differenceCategory(categoryName, rhs).values()) {
        selections.push({ value, category: categoryName })
      }
    }

    return new CategoryList(selections)
  }

  /**
   * Returns a new `Set` of option values, the difference of the named category's option values
   * between this `CategoryList` and another.
   *
   * @param categoryName Name of the category
   * @param rhs CategoryList to compare against
   */
  differenceCategory(categoryName: string, rhs: CategoryList): Set<string> {
    const newCategory: Set<string> = new Set()
    const lhsCategory = this.getCategory(categoryName)
    const rhsCategory = rhs.getCategory(categoryName)
    for (const key of lhsCategory.keys()) {
      if (!rhsCategory.has(key)) {
        newCategory.add(key)
      }
    }
    for (const key of rhsCategory.keys()) {
      if (!lhsCategory.has(key)) {
        newCategory.add(key)
      }
    }

    return newCategory
  }
}

type SelectionProps = {
  allOptions: CategoryList
  selectedOptions: CategoryList
  setSelectedOptions: React.Dispatch<React.SetStateAction<CategoryList>>
  isControlled: boolean
}

function SelectAllCheckBox(props: SelectionProps & CheckBoxMultiCategorySelectProps) {
  const { allOptions, selectedOptions, setSelectedOptions, selectAllText, onChange, isControlled } = props
  const allChecked = allOptions.difference(selectedOptions).size == 0

  return isPresent(selectAllText) && allOptions.size > 0 ? (
    <label className="flex gap-2 items-center">
      <input
        type="checkbox"
        checked={allChecked}
        onChange={() => {
          if (allChecked) {
            if (!isControlled) {
              setSelectedOptions(new CategoryList([]))
            }
            onChange([])
          } else {
            if (!isControlled) {
              setSelectedOptions(allOptions)
            }
            onChange(allOptions.selections)
          }
        }}
      />
      {selectAllText}
    </label>
  ) : null
}

function SelectAllCategoryCheckBox(
  props: { categoryName: string; category: Category } & SelectionProps & CheckBoxMultiCategorySelectProps
) {
  const { style, allOptions, selectedOptions, setSelectedOptions, category, categoryName, onChange, isControlled } =
    props

  if (style == "checkbox") {
    const categoryChecked = selectedOptions.getCategory(categoryName).size > 0
    return allOptions.getCategory(categoryName).size > 0 ? (
      <label className="flex gap-2 items-center ">
        <input
          type="checkbox"
          checked={categoryChecked}
          onChange={() => {
            if (categoryChecked) {
              const newOptions = selectedOptions.withoutCategory(categoryName)
              if (!isControlled) {
                setSelectedOptions(newOptions)
              }
              onChange(newOptions.selections)
            } else {
              const newOptions = selectedOptions.withCategory(categoryName, allOptions.getCategory(categoryName))
              if (!isControlled) {
                setSelectedOptions(newOptions)
              }
              onChange(newOptions.selections)
            }
          }}
        />
        {category.label}
      </label>
    ) : null
  } else {
    const categoryChecked = allOptions.differenceCategory(categoryName, selectedOptions).size == 0
    return isPresent(category.selectAllText) && allOptions.getCategory(categoryName).size > 1 ? (
      <label className="flex gap-2 items-center ">
        <input
          type="checkbox"
          checked={categoryChecked}
          onChange={() => {
            if (categoryChecked) {
              const newOptions = selectedOptions.withoutCategory(categoryName)
              if (!isControlled) {
                setSelectedOptions(newOptions)
              }
              onChange(newOptions.selections)
            } else {
              const newOptions = selectedOptions.withCategory(categoryName, allOptions.getCategory(categoryName))
              if (!isControlled) {
                setSelectedOptions(newOptions)
              }
              onChange(newOptions.selections)
            }
          }}
        />
        {category.selectAllText}
      </label>
    ) : null
  }
}

function OptionCheckBox(
  props: { categoryName: string; optionValue: string; optionLabel: string } & SelectionProps &
    CheckBoxMultiCategorySelectProps
) {
  const { selectedOptions, setSelectedOptions, categoryName, optionValue, optionLabel, onChange, isControlled } = props
  const optionChecked = selectedOptions.has(categoryName, optionValue)
  return (
    <label key={`${categoryName}:${optionValue}`} className="flex gap-2 items-center">
      <input
        type="checkbox"
        name={categoryName}
        value={optionValue}
        checked={optionChecked}
        onChange={() => {
          const newOptions = optionChecked
            ? selectedOptions.delete(categoryName, optionValue)
            : selectedOptions.add(categoryName, optionValue)

          if (!isControlled) {
            setSelectedOptions(newOptions)
          }

          onChange(newOptions.selections)
        }}
      />
      {optionLabel}
    </label>
  )
}

function CategoryCheckBoxes(
  props: { categoryName: string; category: Category } & SelectionProps & CheckBoxMultiCategorySelectProps
) {
  const { style, allOptions, selectedOptions, categoryName, category } = props
  const { label } = category

  if (allOptions.getCategory(categoryName).size == 0) {
    return null
  }

  if (style == "checkbox") {
    return (
      <div className="flex flex-col gap-3">
        <SelectAllCategoryCheckBox {...props} />
        {selectedOptions.getCategory(categoryName).size > 0 ? (
          <div className="flex flex-col gap-[10px] pl-6">
            {Object.entries(category.options).map(([optionValue, optionLabel]) => (
              <OptionCheckBox
                key={`${categoryName}:${optionValue}`}
                optionValue={optionValue}
                optionLabel={optionLabel}
                {...props}
              />
            ))}
          </div>
        ) : null}
      </div>
    )
  } else {
    return (
      <div className="flex flex-col gap-3">
        <h2 className="text-gray-500 font-medium">{label}</h2>
        <SelectAllCategoryCheckBox {...props} />
        {Object.entries(category.options).map(([optionValue, optionLabel]) => (
          <OptionCheckBox
            key={`${categoryName}:${optionValue}`}
            optionValue={optionValue}
            optionLabel={optionLabel}
            {...props}
          />
        ))}
      </div>
    )
  }
}

/**
 * Renders a list of categorized option values as checkboxes.
 * Additional checkboxes are rendered to allow all options to be checked, and all options within a category to be checked.
 * If a category has no options, it is not rendered.
 * If a category has only a single option, no select all (within category) checkbox is rendered.
 * Provides the current list of selections as a Selection array on change.
 *
 * @param props.config Category configuration. An object with category name keys, and category configuration values.
 *
 * @param props.config.category.label A string or react node to display the category
 *
 * @param props.config.category.selectAllText A string or react node to display the select all (within category) checkbox
 *
 * @param props.config.category.options An object of Option value -> Option label
 *
 * @param props.style Either "label" or "checkbox", defaults to "label". Indicates either a flat list of labeled categories, or a list where category names themselves
 * are checkboxes that show nested checkbox children when interacted.
 *
 * @param props.selections An array of { value, category } objects, with `value` representing the option value and `category` representing the category name.
 * Matching checkboxes will be checked. This will control the component- individual selection checkboxes will only change when this prop is updated.

 * @param props.defaultSelections An array of { value, category } objects, with `value` representing the option value and `category` representing the category name.
 * Matching checkboxes will be initially checked. Does not control the component.
 *
 * @param props.onChange An onChange handler for all of the provided checkboxes. It is called with an array of the current selections as { value, category } objects.
 * See `initialSelections` above.
 *
 * @param props.selectAllText A string to label the select all checkbox
 */
export function CheckBoxMultiCategorySelect(props: CheckBoxMultiCategorySelectProps) {
  const { config, style = "label", selectAllText, defaultSelections, selections } = props
  const isControlled = selections != null
  const allOptions = CategoryList.fromConfig(config)
  const [selectedOptions, setSelectedOptions] = useState(new CategoryList(defaultSelections || []))
  useEffect(() => {
    if (selections != null) {
      setSelectedOptions(new CategoryList(selections))
    }
  }, [selections])

  const innerProps = { allOptions, style, selectedOptions, setSelectedOptions, isControlled, ...props }

  return (
    <>
      {isPresent(selectAllText) ? <SelectAllCheckBox {...innerProps} /> : null}
      {Object.entries(config).map(([categoryName, category]) => (
        <CategoryCheckBoxes key={categoryName} categoryName={categoryName} category={category} {...innerProps} />
      ))}
    </>
  )
}
