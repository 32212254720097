import React from "react"
import { AddressFieldsImplementationProps } from "./addressFields.types"
import { AddressFieldsWithoutHookForm } from "./AddressFieldsWithoutHookForm"
import { AddressFieldsUsingHookForm } from "./AddressFieldsUsingHookForm"

export enum AddressFieldImplementation {
  DEFAULT = "default",
  HOOK_FORM = "react_hook_form",
}

export type AddressFieldsProps = AddressFieldsImplementationProps & {
  implementation: AddressFieldImplementation
}

/**
 * Common address fieldset. Changing country changes available states.
 * Supports multiple Implementation types:
 *   -- Default, or using only BeeKit components and built-in React State
 *   -- Using react-hook-form (type Implementation.HOOK_FORM)
 *
 * For the HOOK_FORM implementation,
 * this component needs to be nested under <FormProvider></FormProvider> for access to useFormContext()
 *
 * Both implementation types only require one argument: fieldNamePrefix.
 * The remaining arguments are optional and have reasonable defaults.
 * @param prop.implementation One of Implementation.DEFAULT or Implementation.HOOK_FORM
 * @param props.fieldNamePrefix String that will be added to the front of all input/select names
 * @param props.includeCompanyField Whether or not to include the Company field. Defaults to true.
 * @param props.includeNameField Whether or not to include the Company field. Defaults to true.
 * @param props.includeAddressValidation Whether or not to ask the backend to do address validation. Defaults to false.
 * @param props.internationalAllowed Whether country selection is allowed. Defaults to US if false.
 * @param props.restrictedCountryCodes Array of 2 letter country codes. If provided, only these countries can be selected in the dropdown.  Empty array means all countries can be selected.
 * @param props.disabled Disable all fields
 * @param props.className Classes for fieldset
 * @param props.serverErrors Any errors being returned by the backend.
 * @param props.formErrors Frontend validation errors from react-hook-form. Only used for Implementation.HOOK_FORM.
 * @param props.defaultValues Object containing default values for fields. Only used for Implementation.DEFAULT,
 *                            as default values for react-hook-form should be provided through the Form Context.
 * @example
 * <AddressFields
 *   implementation={Implementation.DEFAULT}
 *   fieldNamePrefix="quick_send[order_recipients_attributes][0][address_attributes]"
 *   defaultValues={{ address1: "1234 Main Street" }}
 *   serverErrors={{ addressCity: "too long" }}
 *   disabled={true}
 * />
 *
 * @example
 * <FormProvider>
 *   <AddressFields
 *     implementation={Implementation.HOOK_FORM}
 *     serverErrors={{ addressCity: "too long" }}
 *     formErrors={errors["quick_send"]?.["order_recipients_attributes"]?.[0]?.["address_attributes"]}
 *     internationalAllowed={false}
 *     supportedRegions={["US", "CA"]}
 *     fieldNamePrefix="quick_send[order_recipients_attributes][0][address_attributes]"
 *   />
 * </FormProvider>
 *
 * @param props
 */
export function AddressFields(props: AddressFieldsProps) {
  const { implementation, ...restProps } = props

  return implementation === AddressFieldImplementation.DEFAULT ? (
    <AddressFieldsWithoutHookForm {...restProps} />
  ) : (
    <AddressFieldsUsingHookForm {...restProps} />
  )
}
