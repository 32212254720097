import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { Icon } from "~/src/components"
import style from "./WizardStepSidebar.module.scss"

export function WizardStepSidebar(props) {
  const { children, className, ...restProps } = props
  const [isOpen, setOpen] = useState(true)

  return (
    <div className={twMerge([style.base, !isOpen && style.closed, className])}>
      <div className={style.buttons}>
        <button aria-label={isOpen ? "Minimize" : "Expand"} onClick={() => setOpen((s) => !s)}>
          {isOpen ? <Icon.Minus /> : <Icon.Plus color="currentColor" strokeWidth="3" />}
        </button>
      </div>

      {isOpen ? <div className={style.content}>{children}</div> : null}
    </div>
  )
}
