import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { format, isSameDay, parse } from "date-fns"
import React, { MouseEvent, useState } from "react"
import {
  ActiveModifiers,
  Button,
  ClassNames,
  DayOfWeek,
  DayPicker,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  DayProps,
  Matcher,
  SelectSingleEventHandler,
  useDayRender,
} from "react-day-picker"
import styles from "react-day-picker/dist/style.module.css"
import { twMerge } from "tailwind-merge"
import { ClearButton } from "./ClearButton"
import style from "./DatePicker.module.scss"
import { Icon } from "./Icon"

type AdditionalDatePickerProps = {
  containerClass?: string
  dateInputId?: string
  dateInputName?: string
  disabledDateStrings?: Array<string>
  disableWeekends?: boolean
  minDateString?: string
  onChange?: SelectSingleEventHandler
  selectedDateString?: string
}

type ReactDayPickerProps = DayPickerDefaultProps | DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps

export type DatePickerProps = ReactDayPickerProps & AdditionalDatePickerProps

export function DatePicker({
  containerClass,
  dateInputId,
  dateInputName = "selectedDate",
  disabledDateStrings,
  disableWeekends,
  footer,
  minDateString,
  modifiers,
  modifiersStyles,
  onChange,
  selectedDateString,
}: DatePickerProps) {
  const today = new Date()
  const selectedDate = selectedDateString ? parse(selectedDateString, "yyyy-MM-dd", today) : undefined

  const [selected, setSelected] = useState<Date | undefined>(selectedDate)
  const formattedDate = selected ? format(selected, "yyyy-MM-dd") : ""
  const content = selected ? formattedDate : "Select date"

  const classNames: ClassNames = {
    ...styles,
    caption: `${styles.caption} ${style.caption}`,
    day: `${styles.day} ${style.day}`,
    head_row: `${styles.head_row} ${style.head_row}`,
    root: `${styles.root} ${style.root}`,
    day_selected: `${styles.day_selected} ${style.day_selected}`,
    button: `${styles.button} ${style.button}`,
  }

  const disabledDateMatchers: Matcher[] = []

  // If weekends have been disabled, add a matcher to disable them
  if (disableWeekends) {
    const weekendMatcher: DayOfWeek = {
      dayOfWeek: [0, 6],
    }

    disabledDateMatchers.push(weekendMatcher)
  }

  // If there's a min date, add a matcher to disable dates before it
  if (minDateString) {
    try {
      const minDateMatcher: Matcher = {
        before: parse(minDateString, "yyyy-MM-dd", today),
      }
      disabledDateMatchers.push(minDateMatcher)
    } catch (e) {
      console.error(`Error parsing minDateString: ${e.message}`)
    }
  }

  // If there are full closures, add a matcher to disable them
  if (Array.isArray(disabledDateStrings) && disabledDateStrings.length > 0) {
    const additionalDisabledDates = disabledDateStrings.map((dateString) => parse(dateString, "yyyy-MM-dd", today))
    disabledDateMatchers.push((date: Date) => includesDay(additionalDisabledDates, date))
  }

  const onSelect = (day: Date | undefined, selectedDay: Date, activeModifiers: ActiveModifiers, e: MouseEvent) => {
    setSelected(day)
    onChange?.(day, selectedDay, activeModifiers, e)
  }

  return (
    <div className={twMerge("relative flex flex-grow", containerClass)}>
      <Popover className="relative">
        <PopoverButton className="flex flex-grow border border-gray-300 rounded-lg bg-white gap-2 py-3 pl-4 pr-10 items-center grow-1 shrink-0 basis-0">
          <Icon.Calendar title="Title" className="text-gray-400" />
          <span className="flex flex-col justify-center grow-1 shrink-0 self-stretch text-sm">{content}</span>
        </PopoverButton>

        <ClearButton
          onClick={() => setSelected(undefined)}
          className="absolute p-0 top-0 bottom-0 right-0 flex items-center justify-center w-10"
          title="Clear date"
        />

        <PopoverPanel anchor="bottom" className="flex flex-col">
          <DayPicker
            captionLayout="dropdown"
            classNames={classNames}
            defaultMonth={today}
            disabled={disabledDateMatchers}
            footer={footer}
            fromYear={today.getFullYear()}
            mode="single"
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            onSelect={onSelect}
            selected={selected}
            toYear={today.getFullYear() + 1}
            components={{ Day: DayWithAvailabilityDot }}
          />
        </PopoverPanel>
      </Popover>
      {(dateInputName || dateInputId) && (
        <input type="hidden" id={dateInputId} name={dateInputName} value={formattedDate} />
      )}
    </div>
  )
}

function Dot({ busy, limited }: { busy: boolean; limited: boolean }) {
  return (
    <span
      className={twMerge(
        "inline-block rounded-full size-1.5 bg-transparent",
        busy && "bg-rose-400",
        limited && "bg-yellow-300"
      )}
    />
  )
}

function DayWithAvailabilityDot(props: DayProps) {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)
  const buttonProps = dayRender.buttonProps

  const { busy, limited } = dayRender.activeModifiers

  const children = (
    <div className="tailwind-scope">
      <div className="flex gap-1">
        {(busy || limited) && <Dot busy={busy} limited={limited} />}
        {buttonProps.children}
      </div>
    </div>
  )

  if (dayRender.isHidden) {
    return <></>
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />
  }

  return (
    <Button {...dayRender.buttonProps} ref={buttonRef}>
      {children}
    </Button>
  )
}

function includesDay(dateArray: Date[], targetDate: Date): boolean {
  return dateArray.some((date) => isSameDay(targetDate, date))
}
