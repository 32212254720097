import React, { useEffect, useState } from "react"
import { CubeIcon } from "@heroicons/react/24/outline"
import { isNil } from "../lib/any"

export type ImageWithFallbackProps = {
  src: string
  className?: string
  children?: React.ReactNode
  imgProps?: JSX.IntrinsicElements["img"]
  onFinal?: () => void // Called when the image is loaded, errored or will never load (src is nil)
}

const DefaultFallback = () => {
  return (
    <div className="h-full w-full flex items-center justify-center text-gray-400 bg-gray-100 rounded-lg">
      <CubeIcon className="h-10 w-10 stroke-1" />
    </div>
  )
}

export function ImageWithFallback(props: ImageWithFallbackProps) {
  const { src, className, imgProps, onFinal, children } = props
  const [hasError, setHasError] = useState<boolean>(false)

  const onError = () => {
    setHasError(true)
    onFinal?.()
  }

  useEffect(() => {
    if (isNil(src)) {
      requestAnimationFrame(() => onFinal?.())
    }
  }, [src])

  return (
    <div className={className}>
      {src && !hasError ? (
        <img src={src} onError={onError} onLoad={() => onFinal?.()} {...imgProps} />
      ) : children ? (
        <>{children}</>
      ) : (
        <DefaultFallback />
      )}
    </div>
  )
}
