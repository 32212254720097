import * as React from "react"
import { useContext } from "react"
import FulfillLaterFields from "./FulfillLaterFields"
import { FulfillmentContext } from "../FulfillmentContext"
import { FulfillmentMethod } from "../types"
import { twMerge } from "tailwind-merge"
import { AdaFieldError as FieldError } from "~/src/components/AdaFieldError"
import { AdaRadio as Radio } from "~/src/components/AdaRadio"

function generateFulfillmentMethodOptions(isLegacy: boolean) {
  return [
    {
      label: "Auto-fulfill responses",
      value: FulfillmentMethod.AutoFulfill,
      description: (
        <>
          Enabling "Auto-fulfill Responses" means that new responses will be fulfilled by the Warehouse on a rolling
          basis as they are received.
          <br />
          Shipments will flow through Daily Store Orders.
        </>
      ),
    },
    {
      label: "Fulfill later",
      value: isLegacy ? FulfillmentMethod.LegacyFulfillLater : FulfillmentMethod.FulfillLater,
      description: (
        <>
          Enabling "Fulfill Later" means that new responses will have their shipments placed on_hold to be fulfilled by
          the Warehouse at a later date.
          {!isLegacy && (
            <>
              <br />
              Please provide an estimated number of recipients and a target ship date for warehouse resource planning.
            </>
          )}
        </>
      ),
    },
  ]
}

export type FulfillmentRequestDraft = {
  id: number
  estimatedNumberOfRecipients: number
  targetShipDate: string
  requestedById: number
  customOrderId: number | null
}

type FulfillmentFieldsProps = {
  initialFulfillmentMethod: string
  fulfillmentRequestDraft: FulfillmentRequestDraft
}

export function FulfillmentMethodSection({ fulfillmentRequestDraft }: FulfillmentFieldsProps) {
  const { errors, fulfillmentMethod, setFulfillmentMethod, autoAllocate, isLegacy } = useContext(FulfillmentContext)
  const disabled = !autoAllocate

  return (
    <li id="preference_collector_fulfillment_method_input" className="input required tailwind-scope">
      <div>
        <strong>Fulfillment Method</strong>
        <br />
        <span className="hints">
          When "Create shipments + deduct inventory for new responses" is enabled, a Fulfillment Method must be set to
          determine how to fulfill those responses as they are collected.
        </span>
        <br />
        {errors.fulfillmentMethod && (
          <>
            <FieldError errorMessage={errors.fulfillmentMethod} />
            <br />
          </>
        )}
        <span className="hints">Please choose an option below:</span>
      </div>
      <fieldset>
        {disabled && <input type="hidden" name="preference_collector[fulfillment_method]" value={fulfillmentMethod} />}
        {generateFulfillmentMethodOptions(isLegacy).map((option) => {
          const isSelected = fulfillmentMethod == option.value
          const title = disabled
            ? 'If "Create shipments + deduct inventory for new responses" is disabled, the Fulfillment Method will always be "Fulfill Later".'
            : undefined

          return (
            <div className="flex flex-row py-1 gap-2" key={option.value}>
              <div className="flex-initial">
                <Radio
                  checked={isSelected}
                  disabled={disabled}
                  id={option.value}
                  name="preference_collector[fulfillment_method]"
                  title={title}
                  value={option.value}
                  onChange={() => setFulfillmentMethod(option.value)}
                />
              </div>
              <div className="flex-auto">
                <label
                  className={twMerge("float-none", disabled && "cursor-not-allowed text-gray-400")}
                  htmlFor={option.value}
                  title={title}
                >
                  <div>{option.label}</div>
                  <div className="font-normal hints">{option.description}</div>
                </label>
                {option.value == FulfillmentMethod.FulfillLater && isSelected && (
                  <FulfillLaterFields draft={fulfillmentRequestDraft} />
                )}
              </div>
            </div>
          )
        })}
      </fieldset>
    </li>
  )
}
