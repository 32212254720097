import { withTheme, type ThemeProps } from "@rjsf/core"

import templates from "./templates"
import widgets from "./widgets"

const BeeTheme: ThemeProps = { templates, widgets }

export const Form = withTheme(BeeTheme)

export default BeeTheme
