import React, { Fragment, useMemo } from "react"
import { RowValue, HeaderValue, CustomFieldSelectFormat } from "./schema"
import { twMerge } from "tailwind-merge"
import "./Summary.scss"

type Formats = HeaderValue["formats"]
type Row = RowValue

export interface SummaryProps {
  rows: Row[]
  customFormats: Formats
  className?: string
}

export function Summary(props: SummaryProps) {
  const { rows, customFormats, className } = props

  const usaCount = useMemo(() => rows.filter((row) => row.address_country == "US").length, [rows])
  const otherCount = rows.length - usaCount

  const fieldTracker = useMemo(() => {
    const initialSummaryFields: { [key: string]: { [key: string]: number } } = {}
    const customSelectFields = Object.entries(customFormats).filter(([, { format }]) => format == "select")
    customSelectFields.forEach(([key, format]: [string, CustomFieldSelectFormat]) => {
      initialSummaryFields[key] = {}
      format.options.forEach(({ label }) => {
        initialSummaryFields[key][label] = 0
      })
    })

    return rows.reduce((fieldTracker, row) => {
      customSelectFields.forEach(([key, format]: [string, CustomFieldSelectFormat]) => {
        const value = row[key]
        const option = format.options.find((opt) => opt.value == value)
        if (option) {
          fieldTracker[key][option.label] += 1
        }
      })

      return fieldTracker
    }, initialSummaryFields)
  }, [rows, customFormats])

  return (
    <div className={twMerge(["summary-container", className])}>
      <div className="summary-content">
        <div className="summary-row">
          <b>Total entries</b>
          <b>{rows.length}</b>
        </div>
        <p />

        <div className="summary-row">
          <b>Location</b>
        </div>

        <div className="summary-row">
          <span>USA</span>
          <span>{usaCount}</span>
        </div>

        <div className="summary-row">
          <span>International</span>
          <span>{otherCount}</span>
        </div>
        <p />

        <div className="summary-row">
          <b>Custom fields</b>
        </div>
        {Object.entries(fieldTracker).map(([key, choices]) => {
          return (
            <Fragment key={`summary-field-${key}`}>
              <div>
                <span>{key}</span>
              </div>

              <ul>
                {Object.entries(choices).map(([label, quantity]) => {
                  return (
                    <li key={`summary-field-${key}-${label}`}>
                      <div className="summary-row">
                        <span>{label}</span>
                        <span>{quantity}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
              <p />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
