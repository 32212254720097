import React, { useEffect, useState, useRef } from "react"
import { createPortal } from "react-dom"
import { twMerge } from "tailwind-merge"
import style from "./CellTip.module.scss"

const CELL_TIP_PORTAL_ID = "cell-tip-portal"

/**
 *  TODO: Tightly coupled to Spreadsheet. Please refactor.
 *  TODO: Implement intersection observer.
 *  TODO: Tooltip scroll tracking.
 */
export function CellTip(props) {
  const { children, content, show = true, ...restProps } = props
  const [portalNode, setPortalNode] = useState(null)
  const rootRef = useRef(null)

  useEffect(() => {
    let portalNode = document.getElementById(CELL_TIP_PORTAL_ID)

    if (!portalNode) {
      portalNode = document.body.appendChild(document.createElement("div"))
      portalNode.id = CELL_TIP_PORTAL_ID
      portalNode.classList.add(style.root)
    }

    setPortalNode(portalNode)

    return () => {
      setPortalNode(null)

      if (portalNode.children <= 0) portalNode.remove()
    }
  }, [])

  const { x, y } = rootRef?.current?.getBoundingClientRect() ?? {}

  return (
    <div ref={rootRef} {...restProps}>
      {portalNode
        ? createPortal(
            <div
              className={twMerge(["cell-tip", style.base])}
              style={{
                top: "0px",
                left: "0px",
                transform: `translate3d(${x}px, ${y}px, 0)`,
              }}
            >
              <div className={style.content}>{content}</div>
            </div>,
            portalNode
          )
        : null}
      {children}
    </div>
  )
}
