import { initial, join, last } from "lodash-es"
import { Country } from "./country"

export const ON_DEMAND_SHIPPED_SEPARATELY_WARNING =
  "This is an On Demand product that will ship separately from any other products you select."

export function formatSupportedRegions(supportedRegions: string[]) {
  const regions = supportedRegions.map((rawCode) => {
    const code = rawCode.toLocaleUpperCase()

    if (code === "EU") return "European Union"
    if (code === "EU-IE") return "European Union (except Ireland)"

    return Country.fromAlpha2(code).name
  })

  return regions.length <= 2 ? regions.join(" & ") : join(initial(regions), ", ") + " & " + last(regions)
}
