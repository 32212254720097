import axios from "axios"
import _ from "lodash-es"
import { action, observable } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"
import { AdaModal } from "~/src/components"
import Details from "./details"
import Group from "./group"
import style from "./Groups.module.scss"
import { adaClient } from "~/src/lib/appClients"
import { ZipActionModal } from "~/src/components/QuickBranding"

@inject(["ideabookStore"])
@observer
class Groups extends React.Component {
  @observable moveToGroupId = null

  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.groups = props.ideabookStore.ideabook.ideabook_groupings
    this.state = { new_ideabook_name: "", isDetailsOpen: false }
  }

  componentDidMount() {
    let sortable = $("div.vertically-sortable")
    sortable.sortable({
      axis: "y",
      update: (e, ui) => {
        this._resort()
      },
    })

    let searchable = $("input#ideabook_search")
    searchable.autocomplete({
      source: (req, resp) => {
        this.props.ideabookStore.searchByNameOrNumber(
          req.term,
          (ideabooks) => {
            let books = []
            if (ideabooks && ideabooks.length > 0) {
              books = ideabooks.map((i) => {
                return `${i.id}: ${i.name}`
              })
            }
            resp(books)
          },
          (err) => {
            console.log(err)
          }
        )
      },
      position: { my: "bottom", at: "top" },
    })
  }

  @action addGroup(e) {
    let newGroup = {
      ideabook_grouping: {
        name: "New",
        id: null,
        position: 0,
        ideabook_products: [],
        ideabook_id: this.props.ideabookStore.ideabook.id,
      },
    }
    this.props.ideabookStore.createGroup(
      newGroup,
      (resp) => {
        if (!this.props.ideabookStore.expanded.get()) {
          $("div#groups_accordion .accordion-header span")
            .removeClass("ui-icon-triangle-1-s")
            .addClass("ui-icon-triangle-1-e")
          $("div#groups_accordion .ui-accordion-content").hide()
        }
        this._resort() // ensure that the new group is fully integrated into the set.
      },
      (err) => {
        console.log(err)
      }
    )
  }

  @action deleteGroup(group, successCallback, errorCallback) {
    this.props.ideabookStore.deleteGroup(
      { ideabook_grouping: group },
      (groups, success) => {
        this.groups = groups
        this.forceUpdate()
        successCallback(success)
      },
      (error) => {
        errorCallback(error)
      }
    )
  }

  @action toggleAll() {
    let expander = $(".accordion-expand-all")
    let panels = $("div#groups_accordion .ui-accordion-content")
    let icons = $("div#groups_accordion .accordion-header span")

    if (this.props.ideabookStore.expanded.get()) {
      expander.text("Expand All")
      panels.slideUp()
      icons.removeClass("ui-icon-triangle-1-s").addClass("ui-icon-triangle-1-e")
    } else {
      expander.text("Collapse All")
      panels.slideDown()
      icons.removeClass("ui-icon-triangle-1-e").addClass("ui-icon-triangle-1-s")
    }

    this.props.ideabookStore.expanded.set(!this.props.ideabookStore.expanded.get())
  }

  @action showDetails() {
    let container = document.getElementById("active_admin_content")
    container.style.cursor = "wait"

    this.props.ideabookStore.fetchCompaniesAndUsers(
      (data) => {
        this.props.ideabookStore.ideabook.enumerations.companies = data.companies
        this.setState((s) => ({ ...s, isDetailsOpen: true }))
        container.style.cursor = "auto"
      },
      (err) => {
        console.log(err)
        container.style.cursor = "auto"
      }
    )
  }

  @action moveProducts() {
    if (!this.moveToGroupId) {
      alert("Please select a group to move products to.")
      return
    }

    let newGroup = _.find(this.props.ideabookStore.ideabook.ideabook_groupings, (g) => {
      return g.ideabook_grouping.id === this.moveToGroupId
    })

    let moveables = this.selectedProducts(true)

    for (let i = 0; i < moveables.length; i++) {
      let ideabookProduct = moveables[i]
      newGroup.ideabook_grouping.ideabook_products.push(ideabookProduct)
      ideabookProduct.ideabook_product.product.selected = false
      ideabookProduct.ideabook_product.ideabook_grouping_id = newGroup.ideabook_grouping.id
      this.props.ideabookStore.enqueueForUpdate(ideabookProduct)
    }

    this.moveToGroupId = null
    $("select[name=new_group]").val("")
    this.toggleProductMover()
  }

  @action setGroup(value) {
    this.moveToGroupId = parseInt(value)
  }

  @action selectedProducts(removeFromGroups = false) {
    let selected = []

    for (let i = 0; i < this.props.ideabookStore.ideabook.ideabook_groupings.length; i++) {
      let grouping = this.props.ideabookStore.ideabook.ideabook_groupings[i]
      let selectedProds = _[removeFromGroups ? "remove" : "filter"](
        grouping.ideabook_grouping.ideabook_products,
        (p) => {
          return p.ideabook_product.product.selected === true
        }
      )

      selected.push(selectedProds)
    }

    return _.compact(_.flatten(selected))
  }

  @action toggleProductMover() {
    let contr = $("div#selection_control")
    contr[this.selectedProducts().length > 0 ? "slideDown" : "slideUp"]()
  }

  @action setNewIdeabookName(value) {
    this.setState({ new_ideabook_name: value })
  }

  @action createNewIdeabook(elem) {
    let selected = this.selectedProducts()
    this.props.ideabookStore.createIdeabook(
      this.state.new_ideabook_name,
      selected,
      (success) => {
        $("li.create-new-ideabook-status").remove()
        $(elem)
          .closest("ul")
          .append('<li class="create-new-ideabook-status"><span id="ideabook_create_status">Created!</span></li>')
        this.setState({ new_ideabook_name: "" })
        setTimeout(() => {
          $("#ideabook_create_status").remove()
        }, 5000)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  @action saveToExistingIdeabook(elem) {
    let selected = this.selectedProducts()
    let input = $("input#ideabook_search")
    let id = input.val().split(": ")[0]

    let ideabookProductIds = []
    if (selected) {
      ideabookProductIds = selected.map((p) => {
        return p.ideabook_product.id
      })
    }

    this.props.ideabookStore.addToIdeabook(
      id,
      ideabookProductIds,
      (success) => {
        $("li.add-to-ideabook-status").remove()
        $(elem)
          .closest("ul")
          .append(
            `<li class="add-to-ideabook-status"><span id="ideabook_save_status">Saved!</span>&nbsp;<a href="/admin/lookbooks/${id}" target="_blank">Open Ideabook</a></li>`
          )
      },
      (error) => {
        alert("An error occurred: " + error.response.data.message)
      }
    )
  }

  @action checkForUser() {
    let container = document.getElementById("active_admin_content")
    container.style.cursor = "wait"
    const orderables = this.selectedProducts(false)
    let ideabookProductIds = []
    if (orderables) {
      ideabookProductIds = orderables.map((p) => {
        return p.ideabook_product.id
      })
    }
    var icon = document.getElementById("create_order")
    this._setElementDetails(icon, style.spinner, "fa-credit-card", "Submitted Order")
    const newWindow = window.open()
    adaClient
      .post(`lookbooks/${this.props.ideabookStore.ideabook.id}/create_order`, {
        ideabook_product_ids: ideabookProductIds,
      })
      .then((response) => {
        newWindow.location.href = response.request.responseURL
        this._setElementDetails(icon, "fa-credit-card", style.spinner, "Create Order")
        container.style.cursor = "auto"
      })
      .catch((error) => {
        alert("An error occurred: " + error.message)
        newWindow.close()
        this._setElementDetails(icon, "fa-credit-card", style.spinner, "Create Order")
        container.style.cursor = "auto"
      })
  }

  @action triggerZipCreation() {
    axios.get(`/lookbooks/${this.props.ideabookStore.ideabook.id}/create_zip`)
  }

  _setElementDetails(element, newClass, oldClass, title) {
    element.classList.replace(oldClass, newClass)
    element.title = title
  }

  _resort() {
    let sortable = $("div.vertically-sortable")
    let sortedIds = sortable.sortable("toArray")
    this.props.ideabookStore.repositionGroupsByIds(sortedIds)
  }

  render() {
    const groupings = this.props?.ideabookStore?.ideabook?.ideabook_groupings
    const totalProducts = groupings.reduce(
      (acc, grouping) => acc + (grouping?.ideabook_grouping?.ideabook_products?.length ?? 0),
      0
    )

    if (this.groups) {
      return (
        <div>
          <div className="group_actions">
            <div>
              <span>
                <a className="accordion-expand-all" onClick={(e) => this.toggleAll()}>
                  Collapse All
                </a>
              </span>
            </div>

            <div className="product-count">{totalProducts} products</div>

            <div className="group_actions_right">
              <i className="fa fa-plus-circle fa-lg" onClick={(e) => this.addGroup()} title="Add Group"></i>
              <i className="fa fa-info fa-lg" onClick={(e) => this.showDetails()} title="Open Details"></i>
              <i
                id="create_order"
                className="fa fa-credit-card fa-lg"
                onClick={(e) => this.checkForUser()}
                title="Create Order"
              ></i>
              <a
                href={`/admin/lookbooks/${this.props.ideabookStore.ideabook.id}/create_pdf`}
                target="_blank"
                title="Create PDF"
              >
                <i className="fa fa-file-pdf-o fa-lg"></i>
              </a>
              <ZipActionModal
                productCount={this.props.ideabookStore.ideabook.quick_brandable_product_count}
                logoCount={this.props.ideabookStore.ideabook.company_logo_count}
                onSubmit={() => this.triggerZipCreation()}
                lookbookId={this.props.ideabookStore.ideabook.id}
              />
            </div>
          </div>
          <div id="groups_accordion" className="ui-accordion ui-widget ui-helper-reset">
            <div className="vertically-sortable">
              {this.props.ideabookStore.ideabook.ideabook_groupings.map((group) => (
                <Group key={group.ideabook_grouping.id} ideabook_grouping={group.ideabook_grouping} parent={this} />
              ))}
            </div>
          </div>
          <div id="selection_control" className="row" style={{ display: "none" }}>
            <div className="offset-by-one three cols">
              <ul>
                <li>Move to</li>
                <li>
                  <select
                    onChange={(e) => {
                      this.setGroup(e.target.value)
                    }}
                    name="new_group"
                  >
                    <option value="">Choose new group</option>
                    {this.props.ideabookStore.ideabook.ideabook_groupings.map((g) => {
                      return (
                        <option key={g.ideabook_grouping.id} value={g.ideabook_grouping.id}>
                          {g.ideabook_grouping.name}
                        </option>
                      )
                    })}
                  </select>
                </li>
                <li>
                  <button onClick={() => this.moveProducts()}>Move</button>
                </li>
              </ul>
            </div>
            <div className="four cols">
              <ul>
                <li>Add to new Lookbook</li>
                <li>
                  <input
                    name="new_ideabook_name"
                    placeholder="Name"
                    onChange={(e) => this.setNewIdeabookName(e.target.value)}
                    value={this.state.new_ideabook_name}
                  />
                </li>
                <li>
                  <button onClick={(e) => this.createNewIdeabook(e.target)}>Create</button>
                </li>
              </ul>
            </div>
            <div className="four cols">
              <ul>
                <li>Add to existing Lookbook</li>
                <li>
                  <input id="ideabook_search" name="ideabook_search" placeholder="Name" />
                </li>
                <li>
                  <button onClick={(e) => this.saveToExistingIdeabook(e.target)}>Save</button>
                </li>
              </ul>
            </div>
          </div>

          <AdaModal
            open={this.state.isDetailsOpen}
            onClose={() => this.setState((s) => ({ ...s, isDetailsOpen: false }))}
            okBtn={null}
            cancelBtn={{ text: "Done" }}
            title="Details"
            aria-label="Ideabook Details Dialog"
            className={style.detailsDialog}
          >
            <Details />
          </AdaModal>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Groups
