import React, { ReactElement } from "react"

import { TruckIcon } from "@heroicons/react/24/outline"
import { Cue } from "./BeeKit/Cue"
import { formatSupportedRegions } from "~/src/lib/shippingRestrictions"

export type ShippingRestrictionsCueProps = {
  regions: string[]
  className?: string
  icon?: ReactElement
}

export function ShippingRestrictionsCue({
  regions,
  className,
  icon = <TruckIcon className="text-gray-400 h-4 w-4" />,
}: ShippingRestrictionsCueProps) {
  const tipContent = (
    <>
      <strong className="text-gray-400 font-medium">Ships to:</strong>
      <br />
      <div>{formatSupportedRegions(regions)}</div>
    </>
  )

  return (
    <Cue icon={icon} className={className} tip={tipContent}>
      Shipping restrictions
    </Cue>
  )
}
