import React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"
import ProductImageUpload from "./product_image_upload"

@inject(["ideabookStore"])
@observer
class ProductPhotosDialog extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.ideabook_product = props.ideabook_product
    this.parent = props.parent
  }

  componentWillMount() {
    this.props.ideabookStore.fetchPhotosForProduct(
      this.ideabook_product.product.id,
      (data) => {
        this.props.ideabook_product.product.photos = data.photos
      },
      (err) => {
        console.log(err)
      }
    )
  }

  selectPhoto(obj) {
    this.parent.changePhoto(obj)
  }

  render() {
    return (
      <div id={`product_photos_dialog_${this.ideabook_product.id}`}>
        <ProductImageUpload field="test" parent={this} />
        {this.ideabook_product.product.photos.map((p) => {
          return (
            <div key={p.photo.id} className="photo-container" onClick={(e) => this.selectPhoto(p)}>
              <img src={p.photo.sizes.medium.url} />
              <br />
              <strong>{p.photo.name}</strong>
            </div>
          )
        })}
      </div>
    )
  }
}

export default ProductPhotosDialog
