import React from "react"
import { observable, action } from "mobx"
import { observer, inject } from "mobx-react"
import Dropzone from "react-dropzone"
import { readFileURL } from "~/src/lib/file"

@inject(["ideabookStore"])
@observer
class ProductImageUpload extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.data = {
      file: null,
      variant_id: null,
      new_color_name: null,
      product_id: this.props.parent.ideabook_product.product.id,
    }
    this.ideabook_product_id = this.props.parent.ideabook_product.id

    let variants = this.props.parent.ideabook_product.product.variants
    let available_variants = variants.map((v) => {
      let variant = v.variant
      return { id: variant.id, name: variant.name }
    })

    this.dropzone = React.createRef()

    this.state = {
      disabled: true,
      available_variants,
      previewURL: null,
    }
  }

  @action uploadProductImage() {
    const dropNode = $(this.dropzone.current)
    const innerDiv = dropNode.find("div")

    innerDiv.html(
      '<img src="https://production-assets.brilliantmade.com/images/blue-green-spinner.gif" class="spinner" />'
    )

    this.props.ideabookStore.uploadProductImage(
      this.data,
      (resp) => {
        if (
          !this.state.available_variants.find((v) => {
            return v.id === resp.photo.variant.id
          })
        ) {
          let variants = this.state.available_variants
          variants.push(resp.photo.variant)
          this.setState({ available_variants: variants, previewURL: null })
        }

        this.props.parent.ideabook_product.product.photos.unshift(resp)
        innerDiv.html("<span>Done. Another?</span>")
        dropNode.closest(".photo-container").find("select").val("")
      },
      (err) => {
        alert(err.response.data.error)
        innerDiv.html("<span>Try again after fixing the error.</span>")
        console.log(err.response)
      }
    )
  }

  @action onDrop(acceptedFiles) {
    const [file] = acceptedFiles

    this.data.file = file

    readFileURL(file).then((url) => {
      this.setState({ disabled: false, previewURL: url })
    })
  }

  @action chooseVariant(id) {
    if (isNaN(id)) {
      // then they are passing in a name like "Black"
      this.data.new_color_name = id
      this.data.variant_id = null
    } else {
      this.data.new_color_name = null
      this.data.variant_id = parseInt(id)
    }
  }

  combifySelects() {
    let self = this

    $(`select#${this.ideabook_product_id}_variant`).combify()

    let uiComboBox = $(`input[type='hidden']#${this.ideabook_product_id}_variant`).parent()
    uiComboBox.find("input").on("change", function (e) {
      self.chooseVariant($(e.target).val())
    })
  }

  componentDidMount() {
    this.combifySelects()
  }

  componentDidUpdate() {
    this.combifySelects()
  }

  render() {
    return (
      <div className="photo-container">
        <Dropzone multiple={false} ref={this.dropzone} onDrop={this.onDrop.bind(this)}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone variant-creator" {...getRootProps()}>
              <input {...getInputProps()} />
              <span>New Image</span>
              {this.state.previewURL && <img src={this.state.previewURL} />}
            </div>
          )}
        </Dropzone>
        <select
          name="variant_id"
          id={this.ideabook_product_id + "_variant"}
          onChange={(e) => this.chooseVariant(e.target.value)}
        >
          <option value="">Choose variant</option>
          {this.state.available_variants.map((v) => {
            return (
              <option key={v.id} value={v.id}>
                {v.name}
              </option>
            )
          })}
        </select>
        <button onClick={(e) => this.uploadProductImage()} disabled={this.state.disabled}>
          create
        </button>
      </div>
    )
  }
}

export default ProductImageUpload
