import React from "react"
import { UseFormReturn, useWatch } from "react-hook-form"
import { Input, Select } from "~/src/components"
import { isntNil } from "~/src/lib/any"
import { ShippingCostEstimatorFormState } from "../ShippingCostEstimator"
import style from "./PickShippingSpeed.module.scss"

export type PickShippingSpeedProps = {
  hookForm: UseFormReturn<ShippingCostEstimatorFormState>
}

function getOptions(useNewCarrierPermissions = false) {
  const current = [
    {
      value: "exclude_economy",
      label: "Ship via standard allowed carriers (FedEx, UPS, USPS, DHL eCS)",
    },
    {
      value: "no_restrictions",
      label: "Allow DHL eCommerce / UPS Worldwide Economy / FedEx IC / Asendia for intl shipments",
    },
  ]

  const updated = [
    {
      value: "no_restrictions",
      label: "All Speeds Including Economy (default)",
    },
    {
      value: "exclude_economy",
      label: "Standard Speeds Only (No Economy Rates, Faster Shipping)",
    },
  ]

  return useNewCarrierPermissions ? updated : current
}

export function PickShippingSpeed(props: PickShippingSpeedProps) {
  const {
    hookForm: {
      control,
      register,
      formState: { errors },
    },
  } = props
  const timeWindow = useWatch({ control, name: "timeWindow" })
  const baseMarginPercentage = useWatch({ control, name: "baseMarginPercentage" })

  return (
    <div className={style.base}>
      <div className={style.howAreYouShipping}>
        <hgroup>
          <h2>How are you shipping these kits?</h2>
        </hgroup>

        <div className={style.stackedField}>
          <label>When do these shipments need to arrive?</label>
          <Select
            {...register("timeWindow")}
            options={[
              { value: "most_economical_rate", label: "Most economical rate" },
              { value: "by_certain_date", label: "By certain date" },
            ]}
          />
        </div>

        {timeWindow === "by_certain_date" ? (
          <>
            <div className={style.stackedField}>
              <label>How many business days do you need your shipments to arrive in?</label>
              <Input error={errors.byDays} {...register("byDays", { valueAsNumber: true })} />
            </div>
          </>
        ) : (
          <>
            <div className={style.stackedField}>
              <label>Shipping carriers to use:</label>
              <Select {...register("allowedCarriers")} options={getOptions(true)} />
            </div>
          </>
        )}
      </div>

      <div>
        <hgroup>
          <h2>What margin do we set for this client?</h2>
        </hgroup>

        <div className={style.field}>
          <label htmlFor="baseMarginPercentage">Base Margin (%)</label>
          <Input
            id="baseMarginPercentage"
            error={errors.baseMarginPercentage}
            {...register("baseMarginPercentage", { valueAsNumber: true })}
          />
        </div>

        {isntNil(baseMarginPercentage) && baseMarginPercentage < 40 && (
          <div className={style.errorNotice}>A base margin of at least 40% is highly recommended!</div>
        )}
      </div>
    </div>
  )
}
