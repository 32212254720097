import React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"

@inject(["ideabookStore"])
@observer
class PriceGroupSelect extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.ideabook_product = props.ideabook_product
    this.price_groups = this.ideabook_product.product.price_structure.price_groups
  }

  @action updateField(name, value) {
    this.ideabook_product[name] = value
    this.props.ideabookStore.enqueueForUpdate({ ideabook_product: this.ideabook_product })
  }

  render() {
    if (this.price_groups.length > 1) {
      return (
        <select
          name="price_group_id"
          defaultValue={this.ideabook_product.price_group_id}
          onChange={(e) => {
            this.updateField(e.target.name, e.target.value)
          }}
        >
          {this.price_groups.map((g) => {
            return (
              <option key={g.price_group.id} value={g.price_group.id} title={g.price_group.name}>
                {g.price_group.short_name}
              </option>
            )
          })}
        </select>
      )
    } else if (this.price_groups.length === 1) {
      return <span>{this.price_groups[0].price_group.short_name}</span>
    } else {
      return null
    }
  }
}

export default PriceGroupSelect
