import { debounce } from "lodash-es"
import cuid from "cuid"
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form"
import { Button, Elliptick, Input, InputRadio, PickVariantsFormState, retoast } from "~/src/components"
import { isBlank } from "~/src/lib/any"
import { appClient } from "~/src/lib/appClients"
import { Format } from "~/src/lib/format"
import { twMerge } from "tailwind-merge"
import * as SerializedRecord from "~/src/serializedRecords"
import { ShippingCostEstimatorFormState } from "../ShippingCostEstimator"
import styles from "./PickVariants.module.scss"

export interface PickVariantsProps {
  hookForm: UseFormReturn<ShippingCostEstimatorFormState>
}

type Variant = {
  id: number
  product: {
    id: number
    name: string
    unitWeightOz: number
  }
}

export function PickVariants(props: PickVariantsProps) {
  const {
    hookForm: { control, formState, register, trigger, getValues },
  } = props

  const addVariantInputRef = useRef<HTMLInputElement>(null)
  const { fields = [], append, remove } = useFieldArray({ control, name: "variants", keyName: "key" })
  const shippingKit = useWatch({ control, name: "shippingKit" })
  const variants = useWatch({ control, name: "variants" })
  const [showAddNew, setShowAddNew] = useState(false)
  const [packageDimensions, setPackageDimensions] = useState<SerializedRecord.PackageDimension[]>([])

  const handleSaveVariant = async (variantId: string = "") => {
    if (isBlank(variantId) && !/\d+/.test(variantId)) {
      retoast.error("Must supply a variant id")
      return
    }

    const { data } = await appClient.get<Variant>(`/api/i/variants/${variantId}`)

    append({
      key: cuid(),
      id: data?.id,
      quantity: 1,
      productId: data?.product?.id,
      productName: data?.product?.name,
      unitWeightOz: data?.product?.unitWeightOz,
    })

    const variant_count = getValues("variants").filter((variant) => variant.id === +variantId).length
    if (variant_count > 1) {
      retoast.warning(`There are now ${variant_count} variants with id of ${variantId}.`)
    }

    trigger("variants")
    setShowAddNew(false)
  }

  const handleAddVariant = () => {
    if (!showAddNew) {
      setShowAddNew(true)
      return
    }

    const variantId = addVariantInputRef?.current?.value
    handleSaveVariant(variantId)
  }

  const refreshPackageDimensions = useCallback(
    debounce(
      async (shippingKit: PickVariantsFormState["shippingKit"]) => {
        const variants = getValues("variants") ?? []
        const query = {
          shippingKit,
          variants: variants.filter(Boolean).map(({ id, quantity }) => ({ id, quantity })),
        }

        const { data: packageDimensions } = await appClient.post<SerializedRecord.PackageDimension[]>(
          "/api/i/package_dimensions/search",
          { query }
        )

        setPackageDimensions(packageDimensions)
      },
      200,
      { trailing: true }
    ),
    [getValues, setPackageDimensions]
  )

  useEffect(() => {
    ;(async () => {
      if (await trigger("variants")) {
        refreshPackageDimensions(shippingKit)
      }
    })()
  }, [variants, shippingKit])

  const handleRemoveVariant = (index: number) => () => {
    remove(index)
    trigger("variants")
  }

  return (
    <div className={styles.base}>
      <div className={styles.whatToSend}>
        <hgroup>
          <h2>What are you looking to send?</h2>
        </hgroup>

        <div className={styles.whatToSendTable}>
          {[
            "Variant",
            "Quantity per Box",
            "Item",
            "Weight per Unit",
            <Button key={5} style={{ visibility: "hidden" }}>
              Remove
            </Button>,
          ].map((x, i) => (
            <div key={i}>
              <h3>{x}</h3>
            </div>
          ))}

          {fields
            .filter((i) => !!i)
            .map((field, index) => (
              <Fragment key={field?.key}>
                <div>
                  {field?.id} (Product&nbsp;
                  <a tabIndex={-1} href={`/admin/products/${field?.productId}`}>
                    {field?.productId}
                  </a>
                  )
                </div>
                <div>
                  <Input
                    type="number"
                    error={formState?.errors?.variants?.[index]?.quantity}
                    {...register(`variants.${index}.quantity` as const, { valueAsNumber: true })}
                  />
                </div>
                <div>{field?.productName}</div>
                <div>
                  {field?.unitWeightOz ? (
                    Format.oz(field?.unitWeightOz)
                  ) : (
                    <span className={styles.textInvalid}>
                      Not available
                      <br />
                      Master carton information required
                    </span>
                  )}
                </div>
                <div>
                  <Button onClick={handleRemoveVariant(index)}>Remove</Button>
                </div>
              </Fragment>
            ))}

          {(fields?.length ?? 0) <= 0 ? (
            <>
              <div className={styles.rowInvalid}>You must supply at least one variant!</div>
            </>
          ) : (
            <></>
          )}

          {showAddNew && (
            <>
              <div>
                <Input
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      e.stopPropagation()
                      handleSaveVariant(e.currentTarget.value)
                    }

                    if (e.key === "Escape") {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowAddNew(false)
                    }
                  }}
                  placeholder="Copy Variant Id here..."
                  ref={addVariantInputRef}
                />
              </div>
              <div>
                <Elliptick />
              </div>
              <div>
                <Elliptick />
              </div>
              <div>
                <Elliptick />
              </div>
              <div></div>
            </>
          )}
        </div>

        <div className={styles.addNewButtons}>
          <Button onClick={handleAddVariant}>{showAddNew ? `Save` : `Add New Variant`}</Button>
          {showAddNew && <Button onClick={() => setShowAddNew(false)}>Cancel</Button>}
        </div>
      </div>

      <div>
        <hgroup>
          <h2>What are you shipping this kit in?</h2>
        </hgroup>

        <div className={styles.shippingKitRadioButtons}>
          <div>
            <InputRadio value="corrugated_box" label="Corrugated box" {...register("shippingKit")} />
          </div>
          <div>
            <InputRadio value="padded_mailer" label="Padded mailer" {...register("shippingKit")} />
          </div>
          <div>
            <InputRadio value="no_additional_packaging" label="No additional packaging" {...register("shippingKit")} />
          </div>
          {
            // Disable field until supported in BE
            false && (
              <div>
                <InputRadio value="custom_packaging" label="Custom packaging" {...register("shippingKit")} />
                <div
                  className={twMerge([
                    styles.customPackagingWeightField,
                    shippingKit === "custom_packaging" && styles.isVisible,
                  ])}
                >
                  <Input {...register("customPackagingWeight", { valueAsNumber: true })} />
                  &nbsp;<strong>oz</strong>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div>
        <hgroup>
          <h2>Package Dimensions</h2>
          {false && (
            <>
              &nbsp;<Button onClick={() => refreshPackageDimensions(shippingKit)}>Reset to Contents</Button>
            </>
          )}
        </hgroup>

        <div className={styles.packageDimensions}>
          {packageDimensions.map((pD, index) => {
            const weight = pD?.weight ?? 0

            return (
              <div key={index}>
                <strong>Box {index + 1}</strong>
                <div>
                  <span>{Format.oz(weight)}</span>
                  &nbsp;
                  <span>({Format.lbs(weight / 16)})</span>
                </div>
              </div>
            )
          })}

          {
            // Disable fields until supported in BE
            false && (
              <>
                <div>
                  <label htmlFor="weight">Weight</label>
                  <Input id="weight" {...register("packageDimension.weight", { valueAsNumber: true })}></Input>
                </div>
                <div>
                  <label htmlFor="length">Length</label>
                  <Input id="length" {...register("packageDimension.length", { valueAsNumber: true })}></Input>
                  &nbsp;<strong>in</strong>
                </div>
                <div>
                  <label htmlFor="width">Width</label>
                  <Input id="width" {...register("packageDimension.width", { valueAsNumber: true })}></Input>
                  &nbsp;<strong>in</strong>
                </div>
                <div>
                  <label htmlFor="height">Height</label>
                  <Input id="height" {...register("packageDimension.height", { valueAsNumber: true })}></Input>
                  &nbsp;<strong>in</strong>
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}
