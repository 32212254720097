import React, { Children, cloneElement } from "react"

Tooltip.defaultProps = {
  title: "My Tooltip Text",
}

/**
 * Proxy component for tooltip plugin.
 * @example
 * ```
 * <Tooltip title="Tooltip content!"><h3>My Title</h3></Tooltip>
 *
 * <Tooltip title="Tooltip content!">
 *   <div>
 *     <p>My paragraph</p>
 *   </div>
 * </Tooltip>
 * ```
 * @param {*} props
 */
export function Tooltip(props) {
  const { title, children } = props

  return (
    <React.Fragment>
      {Children.map(children, (child) => {
        const { className: rawClassName, ...restProps } = child.props

        return cloneElement(child, {
          className: [rawClassName, "has-tip"].filter((x) => !!x).join(" "),
          "data-tooltip": true,
          "aria-haspopup": true,
          title,
          ...restProps,
        })
      })}
    </React.Fragment>
  )
}
