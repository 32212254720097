import React from "react"
import { iname } from "~/src/lib/iname"
import { QuickSendSelectionKind } from "~/src/types/preferredGift"
import type { QuickSendSelectionItem } from "~/src/types/productSelection"
import { ProductSelection } from "./ProductSelection"
import { Bee } from "./BeeKit"

const validKinds = new Set([QuickSendSelectionKind.Chosen, QuickSendSelectionKind.Gifted])
const baseName = iname().quickSend.selectionsAttributes

export function QuickSendEditSelectionInputs() {
  const userSelection = ProductSelection.useSelectedItems<QuickSendSelectionItem>()
  const selectedItems = Object.values(userSelection).filter(({ kind }) => validKinds.has(kind))

  return (
    <>
      {selectedItems.map((item) => {
        const {
          selectedVariant,
          selectable: { id, type },
        } = item

        if (id == null) throw new TypeError('Expected Selectable["id"] to be defined')

        const name = baseName[id]

        return (
          <div key={type + id}>
            <Bee.HiddenInput name={name.selectableType} value={type} />
            <Bee.HiddenInput name={name.selectableId} value={id} />
            <Bee.HiddenInput name={name.kind} value={item.kind} />

            {selectedVariant != null && <Bee.HiddenInput value={selectedVariant?.id} name={name.selectedVariantId} />}
          </div>
        )
      })}
      <input name="step" type="hidden" defaultValue="selections" />
    </>
  )
}
