import React, { DetailedHTMLProps, HTMLAttributes, MouseEvent, useEffect, useRef, useState } from "react"
import { Spinner } from "~/src/components"
import { Awaitable } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import style from "./RoundedButton.module.scss"

export interface RoundedButtonProps extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  onClick: (event: MouseEvent) => Awaitable<void>
}

export function RoundedButton(props: RoundedButtonProps) {
  const { className, children, onClick, ...restProps } = props
  const [isBusy, setBusy] = useState(false)
  const isMountedRef = useRef(true)

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  const handleClick = async (e: MouseEvent) => {
    try {
      setBusy(true)
      await onClick?.(e)
    } finally {
      if (isMountedRef.current) setBusy(false)
    }
  }

  return (
    <button
      className={twMerge([style.base, isBusy && style.isBusy, className])}
      disabled={isBusy}
      onClick={handleClick}
      {...restProps}
    >
      {isBusy ? (
        <div className={style.spinnerContainer}>
          <Spinner size="1.5em" />
        </div>
      ) : null}
      {children}
    </button>
  )
}
