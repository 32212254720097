import React from "react"
import { DexieProvider } from "~/src/hooks"
import { Source } from "./Source"
import { Driver, SidebarKind } from "./Driver"
import { DATABASE, STORES } from "./schema"
import "./DropshipEditor.scss"

// Re-export SidebarType for ease of use so consumers don't have to import from Driver
export { SidebarKind }
export interface DropshipEditorProps {
  dropshipListName: string
  dropshipListId: string
  sourceChecksum: string
  fetchUrl: string
  submitUrl: string
  isReadOnly?: boolean
  sidebarKind: SidebarKind
}

export function DropshipEditor(props: DropshipEditorProps) {
  const { dropshipListName, dropshipListId, sourceChecksum, fetchUrl, isReadOnly, sidebarKind, submitUrl } = props

  return (
    <DexieProvider
      config={{
        database: DATABASE,
        namespace: `DropshipList${dropshipListId}`,
        stores: STORES,
      }}
    >
      {({ isReady }) =>
        isReady ? (
          <Source sourceChecksum={sourceChecksum} fetchUrl={fetchUrl}>
            <Driver
              dropshipListName={dropshipListName}
              dropshipListId={dropshipListId}
              isReadOnly={isReadOnly}
              submitUrl={submitUrl}
              sidebarKind={sidebarKind}
              requireUserConfirmation
            />
          </Source>
        ) : (
          <></>
        )
      }
    </DexieProvider>
  )
}
