import React from "react"
import { ErrorListProps } from "@rjsf/utils"

import { Flash } from "~/src/components/BeeKit/Flash"

export function ErrorListTemplate(props: ErrorListProps) {
  return (
    <Flash kind="error">
      <strong className="font-bold">Errors:</strong>
      <ul>
        {props.errors.map((error, i) => (
          <li key={i}>{error.stack}</li>
        ))}
      </ul>
    </Flash>
  )
}
