import yup from "~/src/lib/yup-extended"
import { isBlank, isntNil, isPresent } from "~/src/lib/any"
import { Country } from "~/src/lib/country"
import { validate } from "~/src/lib/object"

const DEFAULT_COUNTRY_CODE = "US"

const { object, string } = yup

export const schema = ({ allowedKits }) =>
  object().shape({
    kit: string().latin().required().oneOf(allowedKits),
    name: string().latin().required().max(35),
    address_1: string()
      .latin()
      .required()
      .max(35)
      .when("country", (country, schema) => {
        return country === "US" || isBlank(country)
          ? schema
          : schema.test({
              name: "invalidName",
              test(address1: string) {
                if (/P\.?O\.?[\s]*BOX/i.test(address1)) {
                  return this.createError({ message: "address_1 can not be a P.O. Box when country isn't US" })
                }

                return true
              },
            })
      }),
    address_2: string().latin().max(35).nullable(),
    company: string().latin().max(30).nullable(),
    city: string().latin().required().max(30),
    state: string()
      .latin()
      .max(25)
      .when("country", (alpha2, schema) => {
        const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
        if (maybeCountry instanceof Error) return schema

        return maybeCountry.isStateRequired() ? schema.required() : schema
      })
      .when("country", (alpha2, schema) => {
        const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
        if (maybeCountry instanceof Error) return schema

        const stateCodes = maybeCountry.stateCodes() ?? new Set()
        if (stateCodes.size > 0) {
          return schema.test("oneOf", "${path} is not a valid 2-letter state code", (x: string) => stateCodes.has(x))
        }

        return schema
      }),
    zip: string()
      .latin()
      .when("country", (alpha2, schema) => {
        const maybeCountry = Country.fromAlpha2(isPresent(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
        if (maybeCountry instanceof Error) return schema

        if (maybeCountry.isZipRequired() && isntNil(maybeCountry.zipFormat)) {
          return schema
            .required()
            .matches(
              maybeCountry.zipPattern(),
              `zip formatting must be ${maybeCountry.zipFormat.replace(/\s*,\s*/g, " or ")} in ${maybeCountry.name}`
            )
        }

        return schema
      }),
    country: string()
      .latin()
      .test({
        name: "oneOf",
        test(alpha2) {
          const maybeCountry = Country.fromAlpha2(isntNil(alpha2) ? alpha2 : DEFAULT_COUNTRY_CODE)
          if (maybeCountry instanceof Error)
            return this.createError({ message: "${path} " + maybeCountry?.message.toLowerCase() })

          return true
        },
      }),
    phone: string()
      .latin()
      .nullable()
      .when("country", (country, schema) => {
        return (
          country === "US" || isBlank(country) ? schema : schema.required(`phone is required when country isn't US`)
        ).test({
          name: "format",
          test(phone: string) {
            if (isBlank(phone)) return true

            if (/^\d{5,}$/.test(phone.replace(/[+-\s().x]+/gi, ""))) return true

            return this.createError({
              message: "phone must have at least 5 numbers and no special characters except for: ()+-.x",
            })
          },
        })
      }),
  })

export function validateDrop(context, drop) {
  return validate(schema(context), drop)
}
