import React, { forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import { PathBuilder } from "~/src/lib/iname"
import { useFieldContext } from "./Field"

export type InputProps = { name?: PathBuilder | string } & Omit<JSX.IntrinsicElements["input"], "name">

/**
 * A Beekit skin of the native input element that forwards its ref.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { name, className, onChange, ...restProps } = props

  const fieldContext = useFieldContext()

  return (
    <input
      name={name?.toString()}
      onChange={(event) => {
        fieldContext?.descendentChange?.()
        onChange?.(event)
      }}
      className={twMerge(
        "border",
        "border-gray-300",
        "rounded",
        "data-[errored]:border-red-500",
        "data-[errored]:focus:ring-red-500",
        className
      )}
      ref={ref}
      {...(fieldContext?.hasErrors ? { "data-errored": "" } : {})}
      {...restProps}
    />
  )
})
