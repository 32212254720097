import _ from "lodash-es"
import { action, computed } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"

@inject(["ideabookStore"])
@observer
class CompanyChooser extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.ideabook = props.ideabookStore.ideabook
    this.state = { type_search: "" }
  }

  @action typeSearch(value) {
    this.setState({ type_search: _.toLower(value) })
  }

  @action typeSearchResults() {
    return _.filter(this.ideabook.enumerations.companies, (co) => {
      if (this.state.type_search === "") return false
      return _.startsWith(_.toLower(co.company.name), this.state.type_search)
    })
  }

  @action changeType(obj) {
    switch (Object.keys(obj)[0]) {
      case "company":
        this.ideabook.company = obj.company
        this.ideabook.company_id = obj.company.id
        this.ideabook.user_id = null
        this.ideabook.user = {}

        break

      case "user":
        this.ideabook.user = obj.user
        this.ideabook.user_id = obj.user.id
        this.ideabook.company_id = null
        this.ideabook.company = {}

        break

      case "public":
        this.ideabook.user_id = null
        this.ideabook.company_id = null
        this.ideabook.user = {}
        this.ideabook.company = {}

        break
    }

    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.ideabook })
  }

  @action makePublic() {
    this.changeType({ public: {} })
    this.setState({ type_search: "" })
  }

  @computed get companyName() {
    if (this.props.ideabookStore.ideabook.company) {
      return this.props.ideabookStore.ideabook.company.name
        ? this.props.ideabookStore.ideabook.company.name
        : "No Company"
    }
    return "No Company"
  }

  @computed get userName() {
    if (this.props.ideabookStore.ideabook.user) {
      return this.props.ideabookStore.ideabook.user.name ? this.props.ideabookStore.ideabook.user.name : "No User"
    }
    return "No User"
  }

  render() {
    return (
      <div className="input text u-cf">
        <div className="cols" style={{ width: "120px" }}>
          <label>Lookbook for:</label>
          <div className="smaller">
            <i>(search by company name)</i>
          </div>
          <br />

          <ul>
            <li>
              <i className="fa fa-building-o"></i> {this.companyName}
            </li>
            <li>
              <i className="fa fa-user"></i> {this.userName}
            </li>
          </ul>
          <br />
          <button
            onClick={(e) => {
              this.makePublic(e)
              e.preventDefault()
            }}
          >
            Make Public
          </button>
        </div>
        <div style={{ float: "left", marginLeft: "10px" }}>
          <div className="ui-widget-content u-cf company-chooser">
            <input name="type_search" onChange={(e) => this.typeSearch(e.target.value)} />
            <div className="company-chooser-results">
              <ul>
                {this.typeSearchResults().map((r) => {
                  return (
                    <li key={r.id} onClick={() => this.changeType(r)}>
                      <i className="fa fa-building-o"></i>
                      {r.company.name}
                      <br />
                      <ul>
                        {r.company.users.map((u) => {
                          return (
                            <li
                              key={u.id}
                              onClick={(e) => {
                                this.changeType(u)
                                e.stopPropagation()
                              }}
                            >
                              <i className="fa fa-user"></i>
                              {u.user.name}
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CompanyChooser
