import React from "react"
import { action, computed } from "mobx"
import { observer, inject } from "mobx-react"
import Statuses from "./statuses"
import Owners from "./owners"
import Groups from "./groups"
import Orders from "./orders"
import CustomRequestUsers from "./custom_request_users"
import AdditionalActions from "./additional_actions"
import ZipFiles from "./zipfiles"
import { AsideTagList } from "./aside_tag_list"
import { QuickBrandingPanel } from "~/src/components/QuickBranding"

@inject(["ideabookStore"])
@observer
class Ideabook extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.ideabook_id = this.props.ideabookStore.ideabook.id
  }

  componentDidMount() {
    $(document).tooltip()
  }

  @action updateField(field, value) {
    this.props.ideabookStore.ideabook[field] = value
  }

  @action toggleEdit(elem, save = false) {
    let button = $(elem)
    let parent = button.parent()
    let parentSibling = $(parent.siblings()[0])
    parent.hide()
    parentSibling.show()
    if (save) {
      this.props.ideabookStore.enqueueForUpdate({ ideabook: this.props.ideabookStore.ideabook })
    }
  }

  @action reactToEnter(e) {
    if (e.keyCode === 13) {
      this.toggleEdit(e.target, true)
    }
  }

  render() {
    const { ideabook = {} } = this.props.ideabookStore || {}
    const { search_tags: tags = [] } = ideabook

    return (
      <div className="row">
        <div className="ten cols">
          <div className="row">
            <div className="edit-container four cols">
              <div>
                <h3>{this.props.ideabookStore.ideabook.name}</h3>
                <i className="fa fa-pencil" onClick={(e) => this.toggleEdit(e.target)} title="Change Title"></i>
              </div>
              <div style={{ display: "none" }}>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={this.props.ideabookStore.ideabook.name}
                  onChange={(e) => this.updateField(e.target.name, e.target.value)}
                  onKeyDown={(e) => this.reactToEnter(e)}
                />
                <i className="fa fa-check" onClick={(e) => this.toggleEdit(e.target, true)} title="Save Change"></i>
              </div>
            </div>
            <div className="two cols">
              <Statuses />
            </div>
            <div className="six cols ideabook-user-company-container">
              <Owners />
              <a href={`/lookbooks/${this.ideabook_id}`} target="_blank">
                <i className="fa fa-link"></i>
              </a>
            </div>
          </div>
          <div id="groups_row" className="row">
            <div className="twelve cols">
              <Groups />
            </div>
          </div>
        </div>

        <div className="two cols">
          <QuickBrandingPanel
            productCount={this.props.ideabookStore.ideabook.quick_brandable_product_count}
            logoCount={this.props.ideabookStore.ideabook.company_logo_count}
            lookbookId={this.ideabook_id}
            companyUrl={this.props.ideabookStore.ideabook.company_url}
          />
          <AsideTagList tags={tags} />
          <Orders />
          <ZipFiles />
          <CustomRequestUsers />
          <AdditionalActions />
        </div>
      </div>
    )
  }
}

export default Ideabook
