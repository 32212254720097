import { isNil } from "./any"

/**
 * This function reads a file's contents as a data URL. It returns a promise that resolves to a string or an ArrayBuffer.
 * @param file
 * @returns
 */
export function readFileURL(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const result = event?.target?.result

      if (isNil(result)) {
        reject(new TypeError("FileReader return an undefined target"))
      } else {
        resolve(result)
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
