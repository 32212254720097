import React, { ChangeEvent, FocusEvent } from "react"
import { getInputProps, type BaseInputTemplateProps } from "@rjsf/utils"

import { Input } from "~/src/components/BeeKit/Input"

export function BaseInputTemplate({
  schema,
  id,
  options,
  label,
  value,
  type,
  placeholder,
  required,
  disabled,
  readonly: readOnly,
  autofocus,
  onChange,
  onChangeOverride,
  onBlur,
  onFocus,
  rawErrors,
  hideError,
  uiSchema,
  registry,
  formContext,
  ...rest
}: BaseInputTemplateProps) {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange(value === "" ? options.emptyValue || "" : value)
  }
  const handleBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value)
  const handleFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value)

  const inputProps = { ...rest, ...getInputProps(schema, type, options) }
  const hasError = rawErrors && rawErrors.length > 0 && !hideError

  return (
    <Input
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      autoFocus={autofocus}
      value={value}
      onChange={onChangeOverride || handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...inputProps}
    />
  )
}
