import * as React from "react"
import { Ada } from "~/src/components/AdaKit"

type QuickBrandingPanelProps = {
  productCount: number
  lookbookId: number
  logoCount: number
  companyUrl: string
}

export function QuickBrandingPanel({ productCount, lookbookId, logoCount, companyUrl }: QuickBrandingPanelProps) {
  const hasLogos = logoCount >= 1
  const hasProducts = productCount >= 1

  const disabled = !hasLogos || !hasProducts

  return (
    <aside className="tailwind-scope">
      <div className="border rounded-lg px-2 py-4 mb-3 flex flex-col gap-2">
        <h3 className="font-bold mt-2 mb-2">✨ Quick Branding</h3>
        {hasProducts ? (
          <p className="leading-normal">
            You can automatically apply your client’s logos to {productCount} item{productCount == 1 ? "" : "s"} from
            this lookbook
          </p>
        ) : (
          <p className="leading-normal">You have no products with Quick Brand templates.</p>
        )}
        {!hasLogos ? (
          <p className="leading-normal">
            You currently have no available company logos. You can upload some on the{" "}
            <a className="underline" href={companyUrl}>
              company page
            </a>
            .
          </p>
        ) : null}
        <Ada.ButtonLink href={`${lookbookId}/quick_brand`} disabled={disabled} kind="secondary">
          Launch
        </Ada.ButtonLink>
      </div>
    </aside>
  )
}
