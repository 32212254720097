import { Switch } from "@headlessui/react"
import * as React from "react"
import { useState } from "react"
import { twMerge } from "tailwind-merge"
import { PathBuilder } from "~/src/lib/iname"
import { useFieldContext } from "./Field"

export type ToggleProps = {
  id?: string
  name?: string | PathBuilder
  defaultChecked?: boolean
  className?: string
  onChange?: (value: boolean) => void
}

/**
 * A BeeKit toggle switch implemented with Headless UI's Switch.
 */
export function Toggle(props: ToggleProps) {
  const { name, id, defaultChecked, className, onChange } = props
  const [enabled, setEnabled] = useState(defaultChecked ?? false)

  const fieldContext = useFieldContext()

  const handleChange = (value: boolean) => {
    fieldContext?.descendentChange?.()
    setEnabled(value)
    onChange?.(value)
  }

  return (
    <>
      {name != null ? <input id={id} name={name.toString()} type="hidden" value={enabled ? "1" : "0"} /> : <></>}
      <Switch
        checked={enabled}
        onChange={handleChange}
        className={twMerge(
          "relative",
          "inline-flex",
          "h-6",
          "w-11",
          "items-center",
          "rounded-full",
          "transition-colors",
          "bg-gray-100",
          "border",
          "border-gray-300",
          "focus:outline-none",
          "focus:ring-2",
          "focus:ring-offset-2",
          "focus:ring-blue-600",
          "data-[checked]:bg-navy-800",
          "data-[checked]:border-navy-800",
          className
        )}
        {...(fieldContext?.hasErrors ? { "data-errored": "" } : {})}
        {...(enabled && { "data-checked": "" })}
      >
        <span
          className={twMerge(
            "inline-block",
            "h-4",
            "w-4",
            "transform",
            "rounded-full",
            "bg-white",
            "transition-transform",
            "translate-x-1",
            enabled && "translate-x-6"
          )}
        />
      </Switch>
    </>
  )
}
