import React from "react"
import { titleize } from "underscore.string"
import { iname } from "~/src/lib/iname"
import { WithErrorMessages } from "~/src/types/record"
import { Bee } from "./BeeKit"
import { Section } from "./form"

enum PreferenceResponseKind {
  Product = "product",
  Information = "information",
}

type PreferenceResponse = {
  id?: number
  response: string
  description?: string
  imageUrl?: string
  variantId?: number
  hidden: boolean
  disabled: boolean
  kind: PreferenceResponseKind
  persisted: boolean
}

export type PreferenceResponseFormFieldsProps = {
  preferenceCollector: { id: number }
  preference: { id: number }
  preferenceResponses: WithErrorMessages<PreferenceResponse>[]
  preferenceResponseVariantOptions: { label: string; value: number }[]
}

const kindOptions: Bee.ComboboxOption[] = [PreferenceResponseKind.Product, PreferenceResponseKind.Information].map(
  (x) => [x, titleize(x)]
)

export function PreferenceResponseFormFields(props: PreferenceResponseFormFieldsProps) {
  const { preferenceResponses, preferenceResponseVariantOptions } = props

  const [responses, setResponses] = React.useState<WithErrorMessages<PreferenceResponse>[]>(preferenceResponses)

  const handleAddResponseClick = () =>
    setResponses((responses) => [
      ...responses,
      {
        response: "",
        description: "",
        imageUrl: "",
        hidden: false,
        disabled: false,
        persisted: false,
        kind: PreferenceResponseKind.Product,
        errorMessages: {},
      },
    ])

  return (
    <Section>
      <Section.Title>Preference Responses</Section.Title>
      <Section.Content className="items-stretch">
        <div className="flex flex-col gap-4">
          {responses.map(
            (
              { id, response, description, imageUrl, variantId, hidden, disabled, kind, persisted, errorMessages },
              index
            ) => {
              const names = iname().preference.preferenceResponsesAttributes[index]

              return (
                <div key={index} className="flex flex-col border rounded border-gray-300 p-4">
                  {typeof id === "number" && persisted ? (
                    <Bee.Input type="hidden" name={names.id} defaultValue={id} />
                  ) : (
                    <></>
                  )}

                  <Bee.Input type="hidden" name={names.kind} defaultValue={kind} />

                  <div className="flex items-start flex-row gap-2">
                    <Bee.Field className="mr-auto" defaultErrorMessages={errorMessages?.kind}>
                      <Bee.ComboboxInput
                        name={names.kind._}
                        value={kind}
                        onChange={([value]) => {
                          /**
                           * This component is controlled because we must know when the "kind" is changed to
                           * "information". When that happens, we must clear the "variantId" field. Otherwise,
                           * this input would be uncontrolled and simple like the rest.
                           */
                          const kind = value as PreferenceResponseKind

                          setResponses((responses) => {
                            return responses.map((response, i) => {
                              if (i !== index) return response

                              if (kind === PreferenceResponseKind.Information) {
                                return { ...response, kind, variantId: undefined }
                              } else {
                                return { ...response, kind }
                              }
                            })
                          })
                        }}
                        options={kindOptions}
                      />
                    </Bee.Field>

                    <Bee.Field label="Hidden" defaultErrorMessages={errorMessages?.hidden}>
                      <Bee.Toggle name={names.hidden} defaultChecked={hidden} />
                    </Bee.Field>

                    <Bee.Field label="Disabled" defaultErrorMessages={errorMessages?.disabled}>
                      <Bee.Toggle name={names.disabled} defaultChecked={disabled} />
                    </Bee.Field>

                    <Bee.Field label="Delete">
                      <Bee.Toggle name={names._destroy} />
                    </Bee.Field>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="flex flex-col">
                      <Bee.Field label="Response" defaultErrorMessages={errorMessages?.response} className="grow">
                        <Bee.Input type="text" name={names.response} defaultValue={response} />
                      </Bee.Field>

                      <Bee.Field label="Description" defaultErrorMessages={errorMessages?.description} className="grow">
                        <Bee.TextArea name={names.description} defaultValue={description} cols={30} rows={2} />
                      </Bee.Field>
                    </div>

                    <div className="flex flex-col grow">
                      <Bee.Field label="Image URL" defaultErrorMessages={errorMessages?.imageUrl}>
                        <Bee.Input type="text" name={names.imageUrl} defaultValue={imageUrl} />
                      </Bee.Field>
                      {kind === PreferenceResponseKind.Product && (
                        <Bee.Field
                          label="Variant ID"
                          defaultErrorMessages={errorMessages?.variantId}
                          className="grow-2"
                        >
                          <Bee.ComboboxInput
                            name={names.variantId._}
                            defaultValue={variantId}
                            nullable
                            options={preferenceResponseVariantOptions.map(({ label, value }) => [value, label])}
                          />
                        </Bee.Field>
                      )}
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>

        <div className="flex gap-2">
          <Bee.Button type="button" onClick={handleAddResponseClick}>
            Add Response
          </Bee.Button>
          <Bee.Button type="submit">Save</Bee.Button>
        </div>
      </Section.Content>
    </Section>
  )
}
