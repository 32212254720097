import { unparse } from "papaparse"
import React, { useEffect, useMemo, useState } from "react"
import { Download } from "~/src/components"
import { isArray, isFn, isNil, isPresent, isntNil } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import { validateDrop } from "../../validationSchemas/drop"
import { ActionToolbar } from "./ActionToolbar"
import { BulkUploadColumns, Row, StepComponentProps } from "./DropshipWizardMain"
import style from "./ReviewStep.module.scss"
import { RoundedButton } from "./RoundedButton"
import { Spreadsheet, SpreadsheetProps, useSpreadsheetActions } from "./Spreadsheet"
import { WizardNextStep } from "./WizardNextStep"
import { WizardStep } from "./WizardStep"
import { WizardStepSidebar } from "./WizardStepSidebar"

export interface Filter {
  description: string
  fn: (row: Row) => boolean
}

/**
 * A Map of error message keys paired with a set of affected row ids as values.
 */
type ErrorMap = Map<string, Set<number>>

export function ReviewStep(props: StepComponentProps<Row>) {
  const {
    collection,
    table,
    inTransition,
    headers: columns,
    fulfillmentRequest,
    fulfillmentKits = [],
    nextStep,
    namespace,
    onNextClick,
  } = props

  const [viewedRows, setViewedRows] = useState<Row[]>([])
  const [errors, setErrors] = useState<ErrorMap>(new Map())
  const [filters, setFilters] = useState<Filter[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const { createRow, deleteRows } = useSpreadsheetActions()

  const spreadsheetColumns = useMemo(
    () =>
      Object.entries(columns).map(([name, opts]) => {
        const { options, ...restOpts } = opts

        return {
          ...restOpts,
          name,
          key: name,
          options: isFn(options) ? (row: Row) => options(props, row) : options,
        }
      }),
    [columns, props]
  )

  const areErrorsBlocking = useMemo(() => {
    return Array.from(errors).some(([errorMsg]) => isBlockingError(columns, errorMsg))
  }, [errors, columns])

  // Retrieve rows from db
  useEffect(() => {
    if (inTransition === true) return
    ;(async () => {
      try {
        setIsDataLoading(true)
        const drops: Row[] = await collection.toArray()
        setViewedRows(drops)

        const newErrors: ErrorMap = new Map()
        drops.forEach((drop) => {
          diffMergeErrors([], drop.errorMessages, drop.id, newErrors)
        })
        setErrors(newErrors)
      } finally {
        setIsDataLoading(false)
      }
    })()
  }, [inTransition, setViewedRows, collection])

  const handleDownloadSheet = async () => {
    const drops = await collection.toArray()
    return unparse(drops)
  }

  const handleRowsChange: SpreadsheetProps<Row>["onRowsChange"] = (changedRows) => {
    const changedRowsMap = new Map(changedRows.map((r) => [r.id, r]))
    const newViewedRows: Row[] = []
    const newErrors: ErrorMap = new Map(errors)

    for (const row of viewedRows) {
      // Hit changed row
      if (changedRowsMap.has(row.id)) {
        const newRow = changedRowsMap.get(row.id)
        if (isNil(newRow)) throw new Error(`Row ${row.id} doesn't exist, but it is supposed to!`)

        const rowErrors = validateDrop({ allowedKits: fulfillmentKits.map((k) => k.name) }, newRow)

        newRow.errorMessages = []
        newRow.errors = {}

        for (const { message, path } of rowErrors) {
          if (isNil(path)) continue
          if (isNil(newRow.errors)) newRow.errors = {}
          if (isNil(newRow.errorMessages)) newRow.errorMessages = []

          newRow.errors[path] = message
          newRow.errorMessages.push(message)
        }

        diffMergeErrors(row.errorMessages, newRow.errorMessages, row.id, newErrors)

        newViewedRows.push(newRow)
        changedRowsMap.set(newRow.id, newRow)
      } else {
        newViewedRows.push(row)
      }
    }

    setViewedRows(newViewedRows)
    setErrors(newErrors)

    // Update changed rows in db
    table.bulkPut(Array.from(changedRowsMap.values()))
  }

  const handleRowsDelete: SpreadsheetProps<Row>["onRowsDelete"] = (deletedRows) => {
    const deleteRowIds = new Set(deletedRows.map((r) => r.id))
    const newViewedRows: Row[] = []
    const newErrors: ErrorMap = new Map(errors)

    for (const row of viewedRows) {
      if (deleteRowIds.delete(row.id)) {
        diffMergeErrors(row.errorMessages, [], row.id, newErrors)
        continue
      }

      newViewedRows.push(row)
    }

    setViewedRows(newViewedRows)
    setErrors(newErrors)

    const deletedIds = deletedRows.map(({ namespace, id }) => [namespace, id] as [string, number])

    table.bulkDelete(deletedIds)
  }

  const handleRowsCreate: SpreadsheetProps<Row>["onRowsCreate"] = (activeRow, { direction }) => {
    const newViewedRows: Row[] = []
    for (let i = 0; i < viewedRows.length; i += 1) {
      const row = viewedRows[i]

      if (activeRow.id === row.id) {
        // Find an unused `id` and assign it the blank row
        const inc = direction === "up" ? -1 : +1
        let rowId = row.id + inc
        for (let j = i + inc; j > 0 || j < viewedRows.length; j += inc) {
          const nextRow = viewedRows[j]

          if (rowId !== nextRow?.id) break
          rowId += inc
        }

        const blankRow: Row = spreadsheetColumns.reduce((row, { name }) => ({ ...row, [name]: "" }), {
          id: rowId,
          namespace,
        })

        if (blankRow.id < row.id) {
          newViewedRows.push(blankRow)
          newViewedRows.push(row)
        } else {
          newViewedRows.push(row)
          newViewedRows.push(blankRow)
        }
      } else {
        newViewedRows.push(row)
      }
    }

    setViewedRows(newViewedRows)
  }

  const handleErrorMessageClick = (message: string, rowIds: Set<number>) => {
    setFilters(() => {
      return [
        {
          description: message,
          rowIds: new Set(rowIds),
          fn(row) {
            return this.rowIds.has(row.id)
          },
        },
      ]
    })
  }

  const filteredRows = filters.length > 0 ? viewedRows.filter((row) => filters[0]?.fn?.(row)) : viewedRows
  const listFilename = () => {
    if (isntNil(fulfillmentRequest.batch_store_order_user_id)) {
      return `batch_order_fr_${fulfillmentRequest.id}_dropship.csv`
    } else {
      return `fulfillment_request_${fulfillmentRequest.id}_dropship.csv`
    }
  }

  const actions = useMemo(
    () => [
      { icon: "Plus", tooltip: "Add row below active row (CTRL + Enter)", onUse: createRow },
      {
        icon: "Trash",
        tooltip: "Delete active row or all selected rows (CTRL + Backspace)",
        onUse: deleteRows,
      },
      {
        icon: "Download",
        tooltip: "Download working spreadsheet",
        children({ ActionIcon }) {
          return (
            <Download filename={listFilename} onDownload={handleDownloadSheet}>
              <ActionIcon />
            </Download>
          )
        },
      },
      // { icon: "Flag", tooltip: "Turn countries into codes" },
      // { icon: "Reverify", tooltip: "Rerun validations" },
    ],
    [createRow, deleteRows, handleDownloadSheet]
  )

  return (
    <WizardStep
      sidebarRender={() => (
        <WizardStepSidebar>
          {inTransition || isDataLoading ? (
            <></>
          ) : (
            <>
              <h1>
                {viewedRows.length !== filteredRows.length
                  ? `${filteredRows.length} of ${viewedRows.length}`
                  : viewedRows.length}
                &nbsp;
                {viewedRows.length === 1 ? "recipient" : "recipients"}
              </h1>

              {filters.length > 0 ? (
                <>
                  <p>Only showing recipients with:</p>

                  <ul className={style.filtersList}>
                    {filters.map((f) => (
                      <li key={f.description}>{f.description}</li>
                    ))}
                  </ul>

                  <RoundedButton onClick={() => setFilters([])}>Back to All Errors</RoundedButton>
                </>
              ) : (
                <>
                  {isPresent(errors) ? (
                    <p>Address errors below before moving on to the next step.</p>
                  ) : (
                    <p className={style.victoryMessage}>
                      We couldn&apos;t find any errors. Congratulations!
                      <br />
                      <br />
                      <span className={style.victoryEmojis}>✨&nbsp;🥳&nbsp;🎉&nbsp;✨</span>
                    </p>
                  )}
                  <ErrorsByMessageList
                    columns={columns}
                    errors={errors}
                    onErrorMessageClick={handleErrorMessageClick}
                  />
                </>
              )}

              <div className="gap" />
              <WizardNextStep stepName={nextStep} onClick={onNextClick} disabled={areErrorsBlocking} />
            </>
          )}
        </WizardStepSidebar>
      )}
    >
      <ActionToolbar actions={actions} />

      {inTransition ? (
        // TODO: Temporary loading solution. Improve this when background error processing is happening.
        <div className={style.spreadsheetLoadingMessage}>
          <h1>Making sure all ducks are in a row. This may take a while... 🦆&nbsp;&nbsp;🦆&nbsp;&nbsp;&nbsp;🦆</h1>
        </div>
      ) : (
        <Spreadsheet<Row>
          columns={spreadsheetColumns}
          rows={filteredRows}
          onRowsCreate={handleRowsCreate}
          onRowsChange={handleRowsChange}
          onRowsDelete={handleRowsDelete}
          rowKeyGetter={(r) => r.id}
        />
      )}
    </WizardStep>
  )
}

/**
 * Private Functions
 */

/**
 * Applies error diff to `newErrors` as **mutation**.
 * @param prevErrorMsgs
 * @param errorMsgs
 * @param id a row's id
 * @param newErrors mutated
 */
function diffMergeErrors(
  prevErrorMsgs: string[] = [],
  errorMsgs: string[] = [],
  id: number,
  newErrors: ErrorMap
): void {
  // Calculate error diff
  prevErrorMsgs.forEach((errorMessage) => {
    const rowIds = newErrors.get(errorMessage) ?? new Set()
    rowIds.delete(id)

    if (rowIds.size <= 0) {
      newErrors.delete(errorMessage)
    } else {
      newErrors.set(errorMessage, rowIds)
    }
  })

  errorMsgs.forEach((errorMessage) => {
    const rowIds = newErrors.get(errorMessage) ?? new Set()
    rowIds.add(id)
    newErrors.set(errorMessage, rowIds)
  })
}

/**
 * Based on `isValidationStrict` column option, returns `true` if error message is a blocker.
 * @param columns
 * @param errorMsg
 */
function isBlockingError<C extends Record<string, { isValidationStrict?: boolean }>>(
  columns: C,
  errorMsg: keyof C
): boolean {
  const headerName: keyof C | undefined = (/^\s*([^\s]+)/.exec(errorMsg.toString()) ?? [])[1]

  return columns[headerName]?.isValidationStrict ?? false
}

/**
 * Splits off the first word from the rest with regex returns first word and rest in a
 * tuple.
 * @example
 * firstWordAndRest("zip must be formatted as NNNNN (11)")
 * // ["zip", " must be formatted as NNNNN (11)"]
 *
 * firstWordAndRest("phone is a required field (2)")
 * // ["phone", " is a required field (2)"]
 * @param x
 */
function firstWordAndRest(x: string): [string, string] {
  const [, firstWord, rest] = /^([^\s]+)(.+)$/.exec(x.trim()) ?? ["", ""]

  return [firstWord, rest]
}

/**
 * Private Components
 */

interface ErrorsByMessageListProps {
  errors?: ErrorMap
  columns: BulkUploadColumns
  onErrorMessageClick?: (message: string, rowIds: Set<number>) => void
}

function ErrorsByMessageList(props: ErrorsByMessageListProps) {
  const { columns, errors = new Map(), onErrorMessageClick } = props
  const handleErrorMessageClick = (message: string, rowIds: Set<number>) => () => {
    onErrorMessageClick?.(message, rowIds)
  }

  // TODO: Here be dragons that must be slayed! The error metadata should not be
  // determined with RegExp. Save more rich error objects in state, please.
  const errorsByField = useMemo(
    () =>
      Array.from(errors ?? new Map()).reduce((acc, [message, rowIds]) => {
        const [columnName, restMsg] = firstWordAndRest(message)
        acc.set(columnName, (acc.get(columnName) ?? []).concat({ message, restMsg, rowIds }))

        return acc
      }, new Map<string, { message: string; restMsg: string; rowIds: Set<number> }[]>()),
    [errors]
  )

  if (isNil(errors)) return <></>

  return (
    <ol className={style.errorsByMessageList}>
      {Array.from(errorsByField).map(([columnName, errors]) => {
        const { isValidationStrict = false, validationHint } = columns[columnName] ?? {}

        return (
          <li key={columnName} aria-label={`Filter rowrecipients with "${columnName}"`}>
            <ol>
              <h2 className={style.columnNameTitle}>
                {columnName}&nbsp;
                <span className={twMerge([style.severityHint, isValidationStrict && style.severityHintRequired])}>
                  {isValidationStrict ? `required` : `best effort`}
                </span>
              </h2>
              {isntNil(validationHint) ? (
                <div className={style.validationHints}>
                  {isArray(validationHint) ? validationHint.map((s) => <p key={s}>{s}</p>) : validationHint}
                </div>
              ) : (
                <></>
              )}
              {errors.map(({ message, restMsg, rowIds }) => (
                <li key={restMsg} role="button">
                  <span className={style.clickableMessage} onClick={handleErrorMessageClick(message, rowIds)}>
                    {restMsg}
                  </span>
                  <br />
                  <span className={style.rowCountIndicator}>
                    {rowIds.size} {rowIds.size === 1 ? "recipient" : "recipients"}
                  </span>
                </li>
              ))}
            </ol>
          </li>
        )
      })}
    </ol>
  )
}
