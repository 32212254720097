import React from "react"

export type FormErrorProps = {
  name: string
  initialError: string | undefined
  fieldIsDirty: boolean
  error: string
}

export function FormError({ name, initialError, fieldIsDirty, error }: FormErrorProps) {
  if (!fieldIsDirty && initialError) {
    return (
      <small className="text-sm text-red-500 mr-auto">
        {name} {initialError}
      </small>
    )
  }

  if (fieldIsDirty && error) {
    return (
      <small className="text-sm text-red-500 mr-auto">
        {name} {error}
      </small>
    )
  }
}
