import { QuickSendSelectionKind } from "./preferredGift"

export type StoreProduct = {
  colorCount: number
  customCurrencyUnit?: string
  displayPrice: number
  flavorCount: number
  id: number
  imageUrl: string
  internalUrl: string
  inventoryCount: number
  inventoryRequired: boolean
  name: string
  prepaid: boolean
  sizeCount: number
  type: SelectableType.StoreProduct
  onDemand: boolean
  variantCount: number
  variants: Variant[]
  supportedRegions: string[]
}

export type Variant = {
  id: number
  colorName?: string
  colorCode?: string
  colorId?: number
  size?: string
  flavor?: string
  inStock: boolean
  inventoryCount: number
  imageUrl: string
}

export type StoreKit = {
  displayPrice: number
  id: number
  imageUrl: string
  internalUrl: string
  inventoryCount: number
  inventoryRequired: boolean
  name: string
  prepaid: boolean
  customCurrencyUnit?: string
  productContents: string[]
  type: SelectableType.StoreKit
}

export type StoreProductGroup = {
  id: number
  name: string
  selectables: Selectable[]
}

export enum SelectableType {
  StoreKit = "StoreKit",
  StoreProduct = "StoreProduct",
}

export type Selectable = StoreProduct | StoreKit

export type ProductUserSelections<T extends SelectionItemBase = ProductSelectionItem> = {
  [key: number]: T
}

export type SelectionItemBase = {
  id?: number
  selectableId: number
  selectableType: SelectableType
  selectable: Selectable
  selectedVariant?: Variant
}
export type QuickSendSelectionItem = SelectionItemBase & { kind: QuickSendSelectionKind; type: "QuickSend" }
export type BatchOrderSelectionItem = SelectionItemBase & { quantity: number; type: "BatchRequest" }
export type PreferenceCollectorSelectionItem = SelectionItemBase & { type: "PreferenceCollector"; deleted: boolean }

export type ProductSelectionItem = QuickSendSelectionItem | BatchOrderSelectionItem | PreferenceCollectorSelectionItem
