import { isntNil } from "./any"

const precision3Format = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
})

const USDformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const integerFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
})

/**
 * Collection of pure value formatting functions.
 */
export namespace Format {
  /**
   *
   * @param x a weight in pounds
   * @returns as a string formatted with pound units
   */
  export function lbs(x: number) {
    return precision3Format.format(x) + " lbs"
  }

  /**
   *
   * @param x a weight in ounces
   * @returns as a string formatted with ounce units
   */
  export function oz(x: number) {
    return precision3Format.format(x) + " oz"
  }

  /**
   * @param x
   * @returns x as string formatted as USD currency
   */
  export function usd(x: number) {
    return USDformatter.format(x)
  }

  /**
   * @param x an amount to format
   * @param units a units suffix to append
   * @returns x as string formatted as an integer with the given units suffix
   */
  export function customCurrency(x: number, units: string) {
    return `${integerFormatter.format(x)} ${units}`
  }

  /**
   * @param x an amount to format
   * @param units a units suffix to append
   * @returns x as string formatted as an integer with the given units if provided
   */
  export function currency(x: number, units?: string) {
    return isntNil(units) ? customCurrency(x, units) : usd(x)
  }
}
