import { useMedia } from "react-use"

export enum Theme {
  Light = "light",
  Dark = "dark",
}

export function useSystemTheme() {
  return useMedia("(prefers-color-scheme: dark)") ? Theme.Dark : Theme.Light
}
