import * as React from "react"
import type { ReactNode } from "react"

import type {
  ProductSelectionItem,
  ProductUserSelections,
  SelectionItemBase,
  StoreProductGroup,
} from "~/src/types/productSelection"

import { RailsForm } from "~/src/components/RailsForm"

export type ItemSelectionFn = (item: ProductSelectionItem) => void
type SearchSetterFn = (s: string) => void

type SelectionFormPageProps = {
  formUrl: string
  storeProductGroups: Array<StoreProductGroup>
  selections: Array<ProductSelectionItem>
  children?: ReactNode
  hiddenInputs: JSX.Element
}

const SelectionsContext = React.createContext<ProductUserSelections<SelectionItemBase> | null>(null)
const SelectionSetterContext = React.createContext<ItemSelectionFn | null>(null)
const SearchContext = React.createContext<string | null>(null)
const SearchSetterContext = React.createContext<SearchSetterFn | null>(null)

export const useSelectedItems = <Item extends SelectionItemBase>() => {
  const selectedItems = React.useContext(SelectionsContext) as ProductUserSelections<Item>

  if (!selectedItems) {
    throw new Error("useSelectedItems has to be used within <SelectionsContext.Provider>")
  }

  return selectedItems
}

export const useSetSelectedItem = () => {
  const setSelectedItem = React.useContext(SelectionSetterContext)

  if (!setSelectedItem) {
    throw new Error("useSetSelectedItem has to be used within <SelectionSetterContext.Provider>")
  }

  return setSelectedItem
}

export const useSearchQuery = () => {
  const search = React.useContext(SearchContext)

  if (search == null) {
    throw new Error("useSearchQuery has to be used within <SearchContext.Provider>")
  }

  return search
}

export const useSetSearchQuery = () => {
  const setSearch = React.useContext(SearchSetterContext)

  if (!setSearch) {
    throw new Error("setSearch has to be used within <SearchSetterContext.Provider>")
  }

  return setSearch
}

const existingUserSelections = (
  selections: Array<ProductSelectionItem>,
  store_product_groups: Array<StoreProductGroup>
) => {
  const selectableMap = new Map(
    store_product_groups
      .map((spg) => spg.selectables)
      .flat()
      .map((sp) => [sp.id, sp])
  )
  return selections.reduce((acc, selection) => {
    const selectable = selectableMap.get(selection.selectableId)
    acc[selection.selectableId] = { ...selection, selectable }

    return acc
  }, {})
}

export function FormPage(props: SelectionFormPageProps) {
  const { formUrl, selections, storeProductGroups, hiddenInputs, children } = props

  const initialState = existingUserSelections(selections, storeProductGroups)
  const [selectedItems, setSelectedItems] = React.useState<ProductUserSelections>(initialState)
  const [search, setSearch] = React.useState<string>("")

  const selectItem = (selectionItem: ProductSelectionItem) => {
    const { selectableId: id } = selectionItem
    const updatedValue = { ...selectedItems }
    updatedValue[id] = selectionItem
    setSelectedItems(updatedValue)
  }

  return (
    <SelectionsContext.Provider value={selectedItems}>
      <SelectionSetterContext.Provider value={selectItem}>
        <SearchContext.Provider value={search}>
          <SearchSetterContext.Provider value={setSearch}>
            <RailsForm method="patch" className="h-inherit" action={formUrl}>
              {hiddenInputs}
              {children}
            </RailsForm>
          </SearchSetterContext.Provider>
        </SearchContext.Provider>
      </SelectionSetterContext.Provider>
    </SelectionsContext.Provider>
  )
}
