import React from "react"
import { humanize } from "underscore.string"
import { twMerge } from "tailwind-merge"
import style from "./WizardNextStep.module.scss"
import { NavButton } from ".."

export function WizardNextStep(props) {
  const { inline = false, disabled = false, stepName, onClick, className, ...restProps } = props

  return (
    <div className={twMerge([style.base, inline && style.inline, className])} {...restProps}>
      {stepName && (
        <NavButton className={style.navButton} disabled={disabled}>
          <a href={disabled ? undefined : "#" + stepName} disabled={disabled} onClick={disabled ? undefined : onClick}>
            Next: {humanize(stepName)}
          </a>
        </NavButton>
      )}
    </div>
  )
}
