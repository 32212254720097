import React, { PropsWithChildren, ReactElement, ReactNode } from "react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { twMerge } from "tailwind-merge"
import { Tip, TipBubble, TipContentProps, TipTrigger } from "~/src/components/BeeKit/Tip"

export type CueProps = PropsWithChildren<{
  icon?: ReactElement
  tip?: ReactNode
  className?: string
  tipProps?: TipContentProps
}>

export function Cue({ children, className, tip, tipProps, icon }: CueProps) {
  const { className: tipClassName, ...restTipProps } = tipProps ?? {}

  return (
    <div className={twMerge("flex gap-1 items-center", className)}>
      {icon}
      <p className="text-nowrap text-sm">{children}</p>
      {tip && (
        <Tip placement="top-start">
          <TipTrigger>
            <InformationCircleIcon className="text-gray-400 h-4 w-4" />
          </TipTrigger>

          <TipBubble className={twMerge("text-sm max-w-xs", tipClassName)} {...restTipProps} showArrow={false}>
            {tip}
          </TipBubble>
        </Tip>
      )}
    </div>
  )
}
