import React from "react"
import { observable, action, computed } from "mobx"
import { observer, inject } from "mobx-react"
import ImageUpload from "./image_upload"
import Textarea from "./textarea"
import Input from "./input"
import CompanyChooser from "./company_chooser"
import { TagsInput } from "./tags_input"
import IdeabookService from "../services/ideabook_service"
import style from "./Details.module.scss"

@inject(["ideabookStore"])
@observer
class Details extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ideabook = props.ideabookStore.ideabook
    this.state = { type_search: "" }
  }

  @action updateField(field, value) {
    this.ideabook[field] = value
    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.ideabook })
  }

  componentDidMount() {
    $("input.date-field").datepicker()
  }

  render() {
    return (
      <div id="details_dialog" className={style.base}>
        <form className="formtastic">
          <div className="six cols">
            <div className="row">
              <div className="twelve cols">
                <h4>Basic Info</h4>
                <Input field="name" />
                <CompanyChooser />
              </div>
            </div>
            <div className="row">
              <div className="twelve cols">
                <h4>Request Details</h4>

                <div className="input text">
                  <label>Search tags:</label>
                  <TagsInput
                    name="search_tags"
                    onChange={(tags) =>
                      this.updateField(
                        "search_tags",
                        tags.map((n) => ({ name: n }))
                      )
                    }
                    defaultValue={this.ideabook.search_tags.map((x) => x.name)}
                    availableTags={() => IdeabookService.AvailableTags().then(({ tags }) => tags)}
                  />
                </div>

                <Textarea field="request_comments" />
                <Input field="quantity" />
                <Input field="date_needed" className="date-field" />
                <Input field="budget" />
                <Input field="categories" />
              </div>
            </div>
          </div>
          <div className="six cols">
            <h4>Lookbook Appearance</h4>
            <Textarea field="description" />
            <Input field="slug" useCaution="true" />
            <ImageUpload field="half_cover" urlField="half_cover_xlarge_url" />
            <ImageUpload field="cover" urlField="cover_xlarge_url" />
            <ImageUpload field="og_image" label="Open Graph Cover" urlField="og_image_large_url" />
            <Textarea label="Open Graph Description" field="og_description" />
          </div>
        </form>
      </div>
    )
  }
}

export default Details
