import { useEffect } from "react"
import { RowValue, HeaderValue } from "./schema"
import { useStore, FulfillmentRequest, FulfillmentKitItem } from "~/src/components/DropshipEditor/hooks/attachmentStore"
import { isNil, isntNil } from "~/src/lib/any"
import { permitKeys } from "~/src/lib/object"
import { unparse } from "papaparse"

export interface AttachmentHandlerProps {
  rows: RowValue[]
  columns: HeaderValue["columns"]
}

export function AttachmentHandler(props: AttachmentHandlerProps) {
  const { rows, columns } = props
  const { attachmentRequest, clearAttachmentRequest, kitListRequest, clearKitListRequest } = useStore((state) => state)

  useEffect(() => {
    if (isntNil(attachmentRequest)) {
      const { fulfillmentRequest, kitFieldNames, kitFieldChoices, kitComboMapper, resolve } = attachmentRequest
      const kitInfo = { kitFieldNames, kitFieldChoices, kitComboMapper }
      clearAttachmentRequest()
      resolve(generateAttachmentCsv(columns, rows, fulfillmentRequest, kitInfo))
    }

    if (isntNil(kitListRequest)) {
      const { kitFieldNames, kitFieldChoices, resolve } = kitListRequest
      const kitInfo = { kitFieldNames, kitFieldChoices }
      clearKitListRequest()
      resolve(generateKitList(rows, kitInfo))
    }
  }, [attachmentRequest, clearAttachmentRequest, kitListRequest, clearKitListRequest])

  return null
}

function generateAttachmentCsv(
  columns: HeaderValue["columns"],
  rows: RowValue[],
  fulfillmentRequest: FulfillmentRequest,
  kitInfo: {
    kitFieldNames: string[]
    kitFieldChoices: string[][]
    kitComboMapper: Map<string, number> | null
  }
) {
  // Initialize `variant_*` columns
  const variantColumns = {}
  const fulfillmentKits = fulfillmentRequest.fulfillment_kits
  for (const kit of fulfillmentKits) {
    const { fulfillment_kit_items: kitItems } = kit
    for (const kitItem of kitItems) {
      const { variant_id } = kitItem
      variantColumns[`variant_${variant_id}`] = 0
    }
  }

  // Prepare rows for CSV
  const newRows = rows.map((oldRow: RowValue) => {
    let newRow: { [key: string]: any } = {}

    // the fulfillment request will tell us if we can use this for batch store orders
    if (isntNil(fulfillmentRequest.batch_store_order_user_id)) {
      newRow.user_id = fulfillmentRequest.batch_store_order_user_id
    } else {
      newRow.order_id = fulfillmentRequest.order_id
    }

    // Set order id column
    newRow.fulfillment_request_id = fulfillmentRequest.id
    newRow.sequence_number = null

    // Populate variant_* columns
    Object.assign(newRow, variantColumns)

    let kitItems: FulfillmentKitItem[] = []
    if (isNil(kitInfo.kitComboMapper)) {
      // No combo mapping; simply use the first kit for all rows
      kitItems = fulfillmentKits[0]?.fulfillment_kit_items ?? []
    } else {
      // Find the kit this row maps to
      // NOTE: This should always fill, due to the constraints of the spreadsheet requiring all custom fields to be present and valid
      const combo = Array(kitInfo.kitFieldNames.length)
      kitInfo.kitFieldNames.forEach((kitFieldName, kitFieldIdx) => {
        const kitFieldChoice = oldRow[kitFieldName]
        const kitFieldChoiceIdx = kitInfo.kitFieldChoices[kitFieldIdx].findIndex((choice) => choice === kitFieldChoice)
        combo[kitFieldIdx] = kitFieldChoiceIdx
      })
      const kitIdx = kitInfo.kitComboMapper.get(combo.join(","))!
      kitItems = fulfillmentKits[kitIdx]?.fulfillment_kit_items ?? []
    }

    for (const kitItem of kitItems) {
      const { variant_id, quantity } = kitItem
      newRow[`variant_${variant_id}`] += quantity
    }

    // Populate remaining columns
    const colKeys = columns.map((col) => col.key)
    const oldColumns = permitKeys(oldRow, colKeys)
    newRow = { ...newRow, ...oldColumns }

    // Add columns that may be needed
    const additionalColumns = [
      "fulfillment_notes",
      "tracking_number",
      "shipping_method",
      "internal",
      "length",
      "width",
      "height",
      "weight",
      "insurance_amount",
    ]

    for (const column of additionalColumns) {
      newRow[column] = null
    }

    return newRow
  })

  return new Blob([unparse(newRows)], { type: "text/csv" })
}

function generateKitList(rows: RowValue[], kitInfo: { kitFieldNames: string[]; kitFieldChoices: string[][] }) {
  if (kitInfo.kitFieldNames.length == 0) {
    return []
  }

  const combos = rows.reduce((combos, row) => {
    const combo = Array<number>(kitInfo.kitFieldNames.length)
    kitInfo.kitFieldNames.forEach((kitFieldName, kitFieldIdx) => {
      const kitFieldChoice = row[kitFieldName]
      const kitFieldChoiceIdx = kitInfo.kitFieldChoices[kitFieldIdx].findIndex((choice) => choice === kitFieldChoice)
      combo[kitFieldIdx] = kitFieldChoiceIdx
    })

    const comboKey = combo.join(",")
    if (isNil(combos[comboKey])) {
      combos[comboKey] = combo
    }

    return combos
  }, {})

  return Object.values(combos).sort()
}
