import React from "react"
import { useFormContext } from "react-hook-form"
import { twMerge } from "tailwind-merge"
import { isNil } from "~/src/lib/any"
import { Country } from "~/src/lib/country"
import { FormError } from "../BeeKit/FormError"

import { AddressFieldsImplementationProps } from "./addressFields.types"
import { iname } from "~/src/lib/iname"

export function AddressFieldsUsingHookForm(props: AddressFieldsImplementationProps) {
  const {
    className,
    fieldClassStyling = "",
    sectionClassStyling = "",
    fieldHeaderSetClassStyling = "",
    includeNameField = true,
    includeCompanyField = true,
    internationalAllowed,
    serverErrors,
    formErrors,
    fieldNamePrefix,
    disabled = false,
    restrictedCountryCodes = [],
    includeAddressValidation = false,
  } = props

  const {
    register,
    watch,
    getValues,
    formState: { isDirty },
  } = useFormContext()

  const names = iname(fieldNamePrefix)

  const defaultValues = getValues()

  const watchCountry = watch(names.addressCountry._, defaultValues[names.addressCountry._] || "US")

  const country =
    internationalAllowed && restrictedCountryCodes.length > 0 && !restrictedCountryCodes.includes(watchCountry)
      ? Country.fromAlpha2(restrictedCountryCodes[0])
      : Country.fromAlpha2(watchCountry)

  const fieldStyle = twMerge("w-full border border-gray-300 rounded", fieldClassStyling)
  const sectionStyle = twMerge("flex flex-col w-full", sectionClassStyling)
  const fieldHeaderStyle = twMerge("w-full", fieldHeaderSetClassStyling)

  const countryOptions = Country.allPriorityFirst()
    .filter((c) => restrictedCountryCodes.length == 0 || restrictedCountryCodes.includes(c.alpha2))
    .map((c) => ({ value: c.alpha2, label: c.name, disabled: Country.disabledCountries().includes(c.alpha2) }))

  return (
    <fieldset className={twMerge("flex flex-col gap-4", className)}>
      <input type="hidden" name={names.includeAddressValidations._} defaultValue={includeAddressValidation ? 1 : 0} />
      {includeNameField ? (
        <section className={sectionStyle}>
          <div className={fieldHeaderStyle}>
            <h2 className="font-medium">Address name</h2>
            <FormError
              name="Address Name"
              initialError={serverErrors?.addressName}
              fieldIsDirty={isDirty}
              error={formErrors?.["address_name"]?.message}
            />
            <input
              className={fieldStyle}
              disabled={disabled}
              {...register(names.addressName._, {
                required: { value: !disabled, message: "required" },
              })}
            />
          </div>
        </section>
      ) : null}
      {includeCompanyField ? (
        <section className={sectionStyle}>
          <div className={fieldHeaderStyle}>
            <h2 className="font-medium">Address company</h2>
            <FormError
              name="Address Company"
              initialError={serverErrors?.addressCompany}
              fieldIsDirty={isDirty}
              error={formErrors?.["address_company"]?.message}
            />
            <input className={fieldStyle} disabled={disabled} {...register(names.addressCompany._)} />
          </div>
        </section>
      ) : null}
      {/* Country */}
      <section className={sectionStyle}>
        <div className={fieldHeaderStyle}>
          <h2 className="font-medium">Country</h2>
          <FormError
            name="Country"
            initialError={serverErrors?.addressCountry}
            fieldIsDirty={isDirty}
            error={formErrors?.["address_country"]?.message}
          />
          <select
            className={fieldStyle}
            disabled={disabled || !internationalAllowed}
            {...register(names.addressCountry._, {
              required: { value: !disabled, message: "required" },
            })}
          >
            {countryOptions.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </section>
      {/* Street Address */}
      <section className={sectionStyle}>
        <div className={fieldHeaderStyle}>
          <h2 className="font-medium">Street address</h2>
          <FormError
            name="Street address"
            initialError={serverErrors?.address1}
            fieldIsDirty={isDirty}
            error={formErrors?.["address_1"]?.message}
          />
          <input
            className={fieldStyle}
            disabled={disabled}
            {...register(names.address1._, {
              required: { value: !disabled, message: "required" },
            })}
          />
        </div>
      </section>
      {/* Address line 2 */}
      <section className={sectionStyle}>
        <div className={fieldHeaderStyle}>
          <h2 className="font-medium">
            Address line 2 <span className="text-sm text-gray-500 inline"> - Optional</span>
          </h2>
          <FormError
            name="Address line 2"
            initialError={serverErrors?.address2}
            fieldIsDirty={isDirty}
            error={formErrors?.["address_2"]?.message}
          />
          <input className={fieldStyle} disabled={disabled} {...register(names.address2._)} />
        </div>
      </section>
      {/* City */}
      <section className="md:grid md:grid-cols-[2fr_1fr_1fr] flex flex-col gap-4 w-full ">
        <div>
          <h2 className="font-medium">City</h2>
          <FormError
            name="City"
            initialError={serverErrors?.addressCity}
            fieldIsDirty={isDirty}
            error={formErrors?.["address_city"]?.message}
          />
          <input
            className={fieldStyle}
            disabled={disabled}
            {...register(names.addressCity._, {
              required: { value: !disabled, message: "required" },
            })}
          />
        </div>
        {/* State */}
        {country instanceof Error || isNil(country.states) ? (
          <></>
        ) : (
          <div>
            <h2 className="font-medium">State</h2>
            <FormError
              name="State"
              initialError={serverErrors?.addressState}
              fieldIsDirty={isDirty}
              error={formErrors?.["address_state"]?.message}
            />
            <select
              className={fieldStyle}
              disabled={disabled}
              {...register(names.addressState._, {
                required: { value: !disabled && country.isStateRequired(), message: "required" },
              })}
            >
              {country.states.map((s) => (
                <option key={s.alpha2} value={s.alpha2}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* Zip */}
        <div>
          <h2 className="font-medium">Zip Code</h2>
          <FormError
            name="Zip Code"
            initialError={serverErrors?.addressZip}
            fieldIsDirty={isDirty}
            error={formErrors?.["address_zip"]?.message}
          />
          <input
            className={fieldStyle}
            disabled={disabled}
            {...register(names.addressZip._, {
              required: { value: !disabled, message: "required" },
            })}
          />
        </div>
        {/* Phone Number */}
        {!(country instanceof Error) && country.isInternational() ? (
          <div>
            <h2 className="font-medium">Phone Number</h2>
            <FormError
              name="Phone Number"
              initialError={serverErrors?.addressPhone}
              fieldIsDirty={isDirty}
              error={formErrors?.["address_phone"]?.message}
            />
            <input
              className={fieldStyle}
              disabled={disabled}
              {...register(names.addressPhone._, {
                required: { value: !disabled, message: "required" },
              })}
            />
          </div>
        ) : (
          <></>
        )}
      </section>
    </fieldset>
  )
}
