import React from "react"
import { SelectableType, type Selectable, type StoreProduct, StoreKit } from "~/src/types/productSelection"
import { Orientation } from "~/src/types/orientation"
import { Bee } from "../BeeKit"

export type StoreItemProps = {
  selectable: Selectable
  selected: boolean
  orientation?: Orientation
  children: JSX.Element
}

const buildVariantSubtitle = (product: StoreProduct) => {
  const { variantCount, colorCount, sizeCount, flavorCount } = product
  const variantLabels: string[] = []
  if (colorCount > 1) {
    variantLabels.push(`${colorCount} Colors`)
  }
  if (sizeCount > 1) {
    variantLabels.push(`${sizeCount} Sizes`)
  }
  if (flavorCount > 1) {
    variantLabels.push(`${flavorCount} Flavors`)
  }
  if (variantLabels.length == 0 && variantCount > 1) {
    variantLabels.push(`${variantCount} Variants`)
  }
  return variantLabels.join(" / ")
}

const buildKitSubtitle = (kit: StoreKit) => {
  const productContents = kit.productContents.join(", ")
  return `Contents: ${productContents}`
}

const buildSubtitle = (selectable: Selectable) => {
  if (selectable.type == SelectableType.StoreProduct) {
    return buildVariantSubtitle(selectable as StoreProduct)
  } else if (selectable.type == SelectableType.StoreKit) {
    return buildKitSubtitle(selectable as StoreKit)
  }
}

export function CollectionItemTile(props: StoreItemProps) {
  // Will eventually be updated to handle StoreKit and StoreProduct types
  const { selectable, children, selected, orientation = Orientation.Portrait } = props
  const { id, name, displayPrice, customCurrencyUnit } = selectable

  const borderClass = selected ? "border-navy-800" : ""

  return (
    <Bee.ProductTile
      className={borderClass}
      key={id}
      onDemand={selectable.type === "StoreProduct" ? selectable.onDemand : false}
      title={name}
      subtitle={buildSubtitle(selectable)}
      price={displayPrice}
      customCurrencyUnit={customCurrencyUnit}
      orientation={orientation}
      {...selectable}
    >
      {children}
    </Bee.ProductTile>
  )
}
