import React from "react"
import { TrashIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

const buttonStyles = "px-3 py-2 border-0 shadow-none"

export const ClearButton = ({
  onClick,
  className,
  color = "text-grey-400",
  title,
}: {
  onClick: () => void
  className?: string
  color?: string
  title?: string
}) => {
  return (
    <button className={twMerge(buttonStyles, className)} onClick={onClick} type="button">
      <TrashIcon title={title} className={`h-4 w-4 ${color}`} />
    </button>
  )
}
