import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import DataGrid, { Column, DataGridProps, SelectColumn, TextEditor } from "react-data-grid"
import { EditorProps, FillEvent, Position } from "react-data-grid/lib/types"
import { create } from "zustand"
import { FallDown } from "~/src/components"
import { isArray, isFn, isNil, isString, isntNil } from "~/src/lib/any"
import { firstKey, firstValue } from "~/src/lib/object"
import { CellTip } from "../CellTip"
import { CornerTriangle } from "../CornerTriangle"
import "./Spreadsheet.scss"

export type SpreadsheetActionBus = {
  actions: string[]
  push: (action: string) => void
}

export interface SpreadsheetRow {
  errors?: Record<string, string>
}

export interface SpreadsheetColumn<R> extends Column<R> {
  options?: { label: string; value: string }[] | ((rows: R) => { label: string; value: string }[] | undefined)
}

export interface SpreadsheetProps<R extends SpreadsheetRow> extends DataGridProps<R> {
  columns: SpreadsheetColumn<R>[]
  rowKeyGetter: (row: R) => React.Key
  onRowsCreate: (row: R, opts: { direction?: "up" | "down" }) => void
  onRowsDelete: (deletedRows: R[]) => void
}

const useStore = create<SpreadsheetActionBus>((set) => ({
  actions: [],
  push(action: string) {
    return set((s) => ({ ...s, actions: s.actions.concat(action).slice(-5) }))
  },
}))

export function useSpreadsheetActions() {
  const push = useStore((s) => s.push)

  return {
    createRow() {
      push("createRow")
    },
    deleteRows() {
      push("deleteRows")
    },
  }
}

export function Spreadsheet<R extends SpreadsheetRow>(props: SpreadsheetProps<R>) {
  const {
    columns: rawColumns,
    onRowsChange,
    onRowsCreate,
    onRowsDelete,
    onSelectedRowsChange: _0,
    rows,
    selectedRows: _1,
    rowKeyGetter,
    ...restProps
  } = props

  // State Hooks
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<React.Key>>(() => new Set())
  const lastChangedRows = useRef<R[]>([])
  const selectedCell = useRef<Position | undefined>()

  // Event Handlers
  const handleRowsChange = useCallback(
    (newRows: R[]) => {
      let changedRows =
        lastChangedRows.current?.length > 0
          ? [...lastChangedRows.current]
          : isntNil(selectedCell.current)
            ? [newRows[selectedCell.current.rowIdx]]
            : []

      let rowDiff: Record<string, R> | undefined
      if (changedRows.length === 1 && isntNil(selectedCell.current)) {
        const [changedRow] = changedRows
        const column = columns[selectedCell.current.idx]

        if (isString(column.name)) {
          rowDiff = { [column.name]: changedRow[column.name] }
        } else {
          throw new TypeError(`Column name must be a string instead got ${typeof column.name}`)
        }
      }

      if (isntNil(rowDiff) && selectedRowKeys.size > 1) {
        const [changedRow] = changedRows

        if (selectedRowKeys.has(rowKeyGetter(changedRow))) {
          if (
            confirm(
              `Do you want to set "${firstKey(rowDiff)?.toString()}" to "${firstValue(rowDiff)}" for ${
                selectedRowKeys.size
              } selected rows?`
            )
          ) {
            const changedRowKeys = new Set(selectedRowKeys)
            changedRows = []

            newRows.forEach((row) => {
              if (changedRowKeys.delete(rowKeyGetter(row))) changedRows.push({ ...row, ...rowDiff })
            })

            setSelectedRowKeys(changedRowKeys)
          }
        }
      }

      onRowsChange?.(changedRows)
      lastChangedRows.current = []
    },
    [setSelectedRowKeys, selectedRowKeys, rowKeyGetter, onRowsChange]
  )

  // Adds support for drag and fill "handle"
  const handleFill = ({ columnKey, sourceRow, targetRows }: FillEvent<R>): R[] => {
    const changedRows = targetRows.map((row) => ({ ...row, [columnKey]: sourceRow[columnKey] }))
    lastChangedRows.current = changedRows

    return changedRows
  }

  const handleRowsDelete = useCallback(() => {
    const selectedRow = isNil(selectedCell.current) ? null : rows[selectedCell.current?.rowIdx]
    let deletedRows = isNil(selectedRow) ? [] : [selectedRow]

    if (selectedRowKeys.size > 1) {
      if (confirm(`Do you want to delete ${selectedRowKeys.size} selected rows?`)) {
        const deletedRowKeys = new Set(selectedRowKeys)
        deletedRows = []

        rows.forEach((row) => {
          const rowKey = rowKeyGetter(row)

          if (deletedRowKeys.delete(rowKey)) {
            deletedRows.push(row)
          }
        })

        setSelectedRowKeys(deletedRowKeys)
      }
    }

    onRowsDelete?.(deletedRows)
  }, [rows, rowKeyGetter, selectedRowKeys, setSelectedRowKeys, onRowsDelete])

  const handleRowsCreate = useCallback(() => {
    const rowIdx = selectedCell.current?.rowIdx
    onRowsCreate?.(rows[rowIdx ?? 0], { direction: isNil(rowIdx) ? "up" : "down" })
  }, [onRowsCreate, selectedCell, rows])

  useEffect(() => {
    const destroySubscription = useStore.subscribe((state: SpreadsheetActionBus) => {
      switch (state.actions.at(-1)) {
        case "createRow": {
          handleRowsCreate()
          return
        }
        case "deleteRows": {
          handleRowsDelete()
          return
        }
      }
    })

    return () => {
      destroySubscription()
    }
  }, [handleRowsCreate, handleRowsDelete])

  const handleKeyDown = (e: KeyboardEvent) => {
    const rowIdx = isNil(selectedCell.current) ? null : selectedCell.current.rowIdx
    const keyCombo = keyComboFrom(e)

    switch (keyCombo) {
      // Delete current row
      case "ctrl+backspace": {
        handleRowsDelete()

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Create new row below
      case "ctrl+enter": {
        handleRowsCreate()

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Select all
      case "ctrl+a": {
        setSelectedRowKeys(new Set(rows.map(rowKeyGetter)))

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Clear selection
      case "ctrl+d": {
        setSelectedRowKeys(new Set())

        e.stopPropagation()
        e.preventDefault()
        return
      }
      // Add current row and one above to selection
      case "shift+arrowup": {
        if (isNil(rowIdx)) return

        const newSelectedRowKeys = new Set(selectedRowKeys)
        newSelectedRowKeys.add(rowKeyGetter(rows[rowIdx]))

        const nextRow = rows[rowIdx - 1]
        if (nextRow) {
          newSelectedRowKeys.add(rowKeyGetter(nextRow))
        }

        setSelectedRowKeys(newSelectedRowKeys)
        return
      }
      // Add current row and one below to selection
      case "shift+arrowdown": {
        if (isNil(rowIdx)) return

        const newSelectedRowKeys = new Set(selectedRowKeys)
        newSelectedRowKeys.add(rowKeyGetter(rows[rowIdx]))

        const nextRow = rows[rowIdx + 1]
        if (nextRow) {
          newSelectedRowKeys.add(rowKeyGetter(nextRow))
        }

        setSelectedRowKeys(newSelectedRowKeys)
        return
      }
    }
  }

  const columns = useMemo(() => {
    const columns = rawColumns.map(({ key: rawKey, name, ...restColumn }) => {
      const key = rawKey ?? name.toString()

      const column: SpreadsheetColumn<R> = {
        name,
        key,
        editor: TextEditor,
        cellClass(row) {
          if (row?.errors?.[key]) return "rdg-cell-has-error"
        },
        formatter: ValuePresenter,
        ...restColumn,
      }

      const { options } = column

      if (isNil(options)) {
        // Nil guard
      } else if (isArray(options)) {
        column.editor = (props: EditorProps<R>) => {
          const { onRowChange, row, editorPortalTarget, rowHeight } = props

          return (
            <SelectEditor
              value={row[column.key]}
              onChange={(value) => onRowChange({ ...row, [column.key]: value }, true)}
              options={options}
              rowHeight={rowHeight}
              menuPortalTarget={editorPortalTarget}
            />
          )
        }
      } else if (isFn(options)) {
        column.editor = (props: EditorProps<R>) => {
          const { onRowChange, row, editorPortalTarget, rowHeight } = props
          const appliedOptions = options(row)

          if (isNil(appliedOptions)) return <TextEditor {...props} />

          return (
            <SelectEditor
              value={row[column.key]}
              onChange={(value) => onRowChange({ ...row, [column.key]: value }, true)}
              options={appliedOptions}
              rowHeight={rowHeight}
              menuPortalTarget={editorPortalTarget}
            />
          )
        }
      }

      return column
    })

    columns.unshift(SelectColumn)

    return columns
  }, [rawColumns])

  return (
    <div className="spreadsheet" onKeyDownCapture={handleKeyDown}>
      {rows.length > 0 ? (
        <DataGrid
          columns={columns}
          onRowsChange={handleRowsChange}
          onSelectedRowsChange={setSelectedRowKeys}
          onFill={handleFill}
          onSelectedCellChange={(pos) => (selectedCell.current = pos)}
          defaultColumnOptions={{
            resizable: true,
          }}
          rows={rows}
          selectedRows={selectedRowKeys}
          rowKeyGetter={rowKeyGetter}
          className="rdg-light"
          {...restProps}
        />
      ) : null}
    </div>
  )
}

export interface ValuePresenterProps<R extends SpreadsheetRow> {
  column: SpreadsheetColumn<R>
  row: R
  isCellSelected: boolean
}

function ValuePresenter<R extends SpreadsheetRow>(props: ValuePresenterProps<R>) {
  const { column, row, isCellSelected } = props

  //TODO: somehow calculate the size of the spreadsheet
  //then determine whether the cell is in the container or not?
  const inContainer = true
  const errorMessage = row.errors?.[column.key]

  const innerRender = (
    <>
      {errorMessage && !isCellSelected ? <CornerTriangle color="var(--bad-color)" size={10} /> : null}
      {row[column.key]}
    </>
  )

  return (
    <div className="rdg-cell-content">
      {isCellSelected && errorMessage && inContainer ? (
        <CellTip content={errorMessage}>{innerRender}</CellTip>
      ) : (
        innerRender
      )}
    </div>
  )
}

function SelectEditor(props) {
  const { value, onChange, options, rowHeight, menuPortalTarget } = props

  return (
    <FallDown
      noBorder
      autoFocus
      onKeyDown={(e: KeyboardEvent) => {
        // Don't propagate event if using arrows, because they're used by the
        // spreadsheet to navigate from cell to cell and they're needed to select
        // from an option from the dropdown
        if (/Arrow/.test(e.key)) e.stopPropagation()
      }}
      defaultMenuIsOpen
      value={options.find((o) => o.value === value)}
      onChange={(o) => onChange(o?.value)}
      options={options}
      menuPortalTarget={menuPortalTarget}
      styles={{
        control: (given) => ({
          ...given,
          height: rowHeight - 1,
          minHeight: 30,
          lineHeight: "normal",
        }),
        dropdownIndicator: (given) => ({
          ...given,
          height: rowHeight - 1,
        }),
      }}
    />
  )
}

function keyComboFrom(event: KeyboardEvent) {
  const modKeys = ["ctrl", "shift", "alt", "meta"]
  const keyCombo = new Set()

  modKeys.forEach((modKey) => {
    if (event[modKey + "Key"]) keyCombo.add(modKey)
  })

  if (event.key) {
    keyCombo.add(event.key.toLowerCase())
  }

  return Array.from(keyCombo).join("+")
}
