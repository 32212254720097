import React, { useState } from "react"
import { isFn, isNil } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import { Icon } from "~/src/components"
import style from "./ActionToolbar.module.scss"

export function ActionToolbar(props) {
  const { actions = [], actionItemProps = {}, ...restProps } = props

  return (
    <ul className={style.base} {...restProps}>
      {actions.map((action, idx) => {
        const { tooltip, icon, onUse, children } = action
        const ActionIcon = Icon[icon]

        return (
          <ActionItem key={idx} tooltip={tooltip}>
            {isFn(children) ? (
              children({ ...actionItemProps, ActionIcon, tooltip })
            ) : (
              <button onClick={onUse}>{isNil(ActionIcon) ? <Icon.QuestionMark /> : <ActionIcon />}</button>
            )}
          </ActionItem>
        )
      })}
    </ul>
  )
}

/**
 * Private Components
 */

function ActionItem(props) {
  const { className, tooltip, children, ...restProps } = props
  const [isVisible, setVisible] = useState(false)

  return (
    <li
      className={twMerge([style.actionItem, className])}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      {...restProps}
    >
      {children}
      {isVisible && <div className={style.actionItemTooltip}>{tooltip}</div>}
    </li>
  )
}
