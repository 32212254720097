import React, { ChangeEventHandler, MouseEventHandler, useRef } from "react"
import { isntNil } from "~/src/lib/any"
import { twMerge } from "tailwind-merge"
import { StorefrontDropdown, StorefrontDropdownRef } from "~/src/components"
import style from "./LegacyCatalogSearchForm.module.scss"

export interface LegacyCatalogSearchFormProps {
  categories?: Record<string, string>
  onSortChange?: (value?: string) => void
  onCategoryFilterChange?: (value?: number) => void
  onNameOrBrandFilterChange?: (value?: string) => void
  onClearFilterClick?: () => void
  sorts?: Record<string, string>
}

export function LegacyCatalogSearchForm(props: LegacyCatalogSearchFormProps) {
  const { categories, onNameOrBrandFilterChange, onCategoryFilterChange, onClearFilterClick, onSortChange, sorts } =
    props

  const categoryRef = useRef<StorefrontDropdownRef>(null)
  const sortRef = useRef<StorefrontDropdownRef>(null)

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      currentTarget: { value },
    } = event

    onNameOrBrandFilterChange?.(value)
  }

  const handleCategoryChange = (selection: { label: string; value: string }) => {
    const { value } = selection ?? {}
    onCategoryFilterChange?.(+value)
  }

  const handleSortChange = (selection: { label: string; value: string }) => {
    const { value } = selection ?? {}
    onSortChange?.(value)
  }

  const handleClearFilterClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()
    categoryRef.current?.clear()
    sortRef.current?.clear()
    onClearFilterClick?.()
  }

  return (
    <div className={twMerge([style.base])}>
      {isntNil(categories) && (
        <StorefrontDropdown
          ref={categoryRef}
          options={optionsFrom(categories)}
          placeholder="Filter by Category"
          onOptionSelect={handleCategoryChange}
        />
      )}

      <input
        className={style.textSearchInput}
        placeholder="Search by Product Name or Brand"
        type="text"
        onChange={handleSearchInputChange}
      />
      {isntNil(sorts) && (
        <StorefrontDropdown
          options={optionsFrom(sorts)}
          placeholder="Sort By"
          ref={sortRef}
          onOptionSelect={handleSortChange}
        />
      )}
      <a href="#" className={style.clearFiltersButton} onClick={handleClearFilterClick}>
        Clear Filters
      </a>
    </div>
  )
}

function optionsFrom(record: Record<string, string>) {
  return Object.entries(record).map(([id, name]) => ({ label: name, value: id }))
}
