import { ComponentClass, FunctionComponent } from "react"
import { ReactComponent } from "~/src/customElements/reactComponent"
import { isNil } from "./any"

declare global {
  interface Window {
    __reactComponents: Record<string, AnyComponent<any>>
  }
}

export type AnyComponent<P extends object> = FunctionComponent<P> | ComponentClass<P>

/**
 * Register a React component to be usable with `react_component` helper from React-on-Rails
 * @param components
 */
export function registerComponents(components: Record<string, AnyComponent<any>>) {
  window.__reactComponents ||= {}
  Object.assign(window.__reactComponents, components)

  customElements.get("react-component") || customElements.define("react-component", ReactComponent)
}

export function getComponent<P extends object>(name: string): AnyComponent<P> {
  const component = window.__reactComponents[name]

  if (isNil(component)) throw new Error(`${name} component must be registered`)

  return component
}
