import _ from "lodash-es"
import { action } from "mobx"
import { inject, observer } from "mobx-react"
import React from "react"

@inject(["ideabookStore"])
@observer
class Textarea extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.props = props
    this.ideabook = props.ideabookStore.ideabook
    this.field = props.field
    this.label = props.label || _.capitalize(_.upperCase(this.field))
  }

  @action updateField(field, value) {
    this.ideabook[field] = value
    this.props.ideabookStore.enqueueForUpdate({ ideabook: this.ideabook })
  }

  render() {
    return (
      <div>
        <div className="input text">
          <label>{this.label}:</label>
          <textarea
            name={this.field}
            id={this.field}
            defaultValue={this.ideabook[this.field] || ""}
            onChange={(e) => this.updateField(e.target.name, e.target.value)}
          ></textarea>
        </div>
      </div>
    )
  }
}

export default Textarea
