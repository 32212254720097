import { XMarkIcon } from "@heroicons/react/24/outline"
import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { Dialog, DialogPanel } from "@headlessui/react"

export type ModalProps = {
  open: boolean
  children: ReactNode
  className?: string
  backgroundClassName?: string
  usePortal?: boolean
  onClose?: () => void

  showCloseButton?: boolean
}

export function Modal(props: ModalProps) {
  const {
    open,
    children,
    className = "",
    backgroundClassName = "",
    usePortal = true,
    showCloseButton = true,
    onClose = () => {},
  } = props

  if (!usePortal) {
    return <NonPortalModal {...props} />
  }

  return (
    <Dialog
      className={twMerge(
        "z-50 fixed top-0 left-0 right-0 h-screen w-screen flex place-content-center bg-gray-600/80",
        backgroundClassName
      )}
      open={open}
      onClose={onClose}
    >
      <DialogPanel className={twMerge(className, "place-self-center mx-6 p-6 rounded-lg flex flex-col gap-1")}>
        {showCloseButton ? (
          <button type="button" className="place-self-end" onClick={() => onClose()}>
            <XMarkIcon className="w-6 h-6 text-gray-600 " />
          </button>
        ) : (
          <></>
        )}
        {children}
      </DialogPanel>
    </Dialog>
  )
}

function NonPortalModal(props: ModalProps) {
  const { open, onClose, children, className = "", backgroundClassName = "", showCloseButton = true } = props
  return (
    open && (
      <div
        className={twMerge(
          "z-50 fixed top-0 left-0 right-0 h-screen w-screen flex place-content-center bg-gray-600/80",
          backgroundClassName
        )}
        onClick={onClose}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={twMerge(
            "place-self-center mx-6 my-4 p-6 rounded-lg flex flex-col gap-1 max-h-[calc(100vh-3rem)] overflow-y-scroll",
            className
          )}
        >
          {showCloseButton ? (
            <button type="button" className="place-self-end" onClick={onClose}>
              <XMarkIcon className="w-6 h-6 text-gray-600 " />
            </button>
          ) : null}
          {children}
        </div>
      </div>
    )
  )
}
